import { Button } from "../../button";
import { Input } from "../../input";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { StyledNewsletterContainer, StyledNewsletterTextSection, StyledNewsletterDescription, StyledNewsletterSection, StyledNewsletterTitle, StyledNewsletterFormSection } from "./newsletter-section.style";
import { subscribeToNewsletter } from '../../../redux/user/user.actions';
import { FaSpinner, FaCheck } from "react-icons/fa";

const schema = yup.object().shape({
  email: yup.string().email().required("Email is a required field"),
});

const NewsletterSection = () => {
  const dispatch = useDispatch();
  
  const userSubscribe = useSelector((state) => state.userSubscribe);
  const { loading, success } = userSubscribe;

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
} = useForm({ resolver: yupResolver(schema), mode: "onChange"});

const onSubmit = values => {
  const registrationData = { ...values };
  dispatch(subscribeToNewsletter(registrationData, () => reset({})));
}

  return (
    <StyledNewsletterContainer>
      <StyledNewsletterSection>
          <StyledNewsletterTextSection>
              <StyledNewsletterTitle><h2>Subscribe to our Newsletter</h2></StyledNewsletterTitle>
              <StyledNewsletterDescription><p>Receive the emails that hundreds of students use to get the latest scholarship tips.</p></StyledNewsletterDescription>
          </StyledNewsletterTextSection>
          
          <StyledNewsletterFormSection onSubmit={handleSubmit(onSubmit)}>
            <Input variant="primary-bold"
                  id="email"
                  type="text"
                  fullWidth
                  fullHeight
                  fontFamily="Avenir"
                  style={{ borderRadius: '4px 0px 0px 4px' }}
                  {...register("email")}
                  error={errors.email?.message}
                  />
            <Button type="submit" disabled={loading} style={{ borderRadius: '0px 4px 4px 0px', width : '1rem' }}>
              {loading && <><FaSpinner className="spinner"></FaSpinner> &nbsp;</> }
              {success && !loading && <><FaCheck/> &nbsp;</> }
              {success? 'Subscribed' : 'Subscribe'}
            </Button>
          </StyledNewsletterFormSection>
      </StyledNewsletterSection>
    </StyledNewsletterContainer>
  )
}

export {NewsletterSection} 