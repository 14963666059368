import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'


import { alertReducer } from './alert/alert.reducers';
import { userLoginReducer, 
         mentorRegisterReducer, 
         studentRegisterReducer, 
         userProfileReducer, 
         userProfileUpdateReducer, 
         userForgotPasswordReducer, 
         userResetPasswordReducer,
         userChangePasswordReducer,
         userProfilePictureReducer,
         userSubscribeReducer,
         userCheckPhoneNumberReducer,
         userChangePhoneNumberReducer,
         userUpdateCertificateReducer,
    } from './user/user.reducers'
import { recommendedCourseClicksReducer, courseDetailReducer, courseFilterReducer, courseInfoReducer, courseListReducer, recommendedCoursesReducer, bookmarkedCoursesReducer } from './course/course.reducers'
import { mentorListReducer, menteeListReducer, menteeSearchReducer, verifiedMentorsReducer } from './mentor/mentor.reducers';
import { chatUsersReducer, chatMessagesReducer, sendChatMessageReducer, mentorPingReducer } from './chat/chat.reducers';
import { twoFASetupReducer, twoFAActivateReducer, twoFAConfirmationReducer, twoFAAuthProfileReducer } from './twoFA/twoFA.reducers';
import courseCategoriesReducer from './courseCategories/courseCategoties.reducers';
import { countryReducer } from './countries/countries.reducers';
import { recommendedScholarshipClicksReducer, recommendedScholarshipReducer, scholarshipInfoReducer, scholarshipFilterReducer, scholarshipListReducer, bookmarkedScholarshipReducer } from './scholarships/scholarship.reducers';
import { viewSurveyReducer, saveSurveyReducer, popUpSurveyReducer} from './survey/survey.reducers';
import { createMeetingReducer, meetingListReducer } from './meeting/meeting.reducers';
import { notificationListReducer } from './notification/notification.reducers';
import { createReminderReducer, reminderListReducer } from './reminder/reminder.reducers';
import { subscriptionProductListReducer } from './subscriptions/subscriptions.reducers';
import { applicationCreateReducer, applicationGetReducer, applicationListReducer, applicationUpdateReducer } from './application/application.reducers';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['userLogin', 'userProfile', 'popUpSurvey']
}

const rootReducer = combineReducers({
    userLogin: userLoginReducer,
    mentorRegister: mentorRegisterReducer,
    studentRegister: studentRegisterReducer,
    userProfile: userProfileReducer,
    userProfileUpdate: userProfileUpdateReducer,
    userForgotPassword: userForgotPasswordReducer,
    userResetPassword: userResetPasswordReducer,
    courseList: courseListReducer,
    courseInfo: courseInfoReducer,
    scholarshipInfo: scholarshipInfoReducer,
    courseCategories: courseCategoriesReducer,
    courseDetail: courseDetailReducer,
    recommendedCourseClicks :recommendedCourseClicksReducer,
    courseFilters: courseFilterReducer,
    mentorList: mentorListReducer,
    alert: alertReducer,
    chatUsers: chatUsersReducer,
    chatMessages: chatMessagesReducer,
    sendChatMessage: sendChatMessageReducer,
    userChangePassword: userChangePasswordReducer,
    userProfilePicture: userProfilePictureReducer,
    userSubscribe: userSubscribeReducer,
    twoFASetup: twoFASetupReducer,
    twoFAActivate : twoFAActivateReducer,
    twoFAConfirmation: twoFAConfirmationReducer,
    countries: countryReducer,
    userCheckPhoneNumber: userCheckPhoneNumberReducer,
    userChangePhoneNumber: userChangePhoneNumberReducer,
    scholarshipList: scholarshipListReducer,
    recommendedCourses: recommendedCoursesReducer,
    recommendedScholarshipClicks: recommendedScholarshipClicksReducer,
    recommendedScholarship: recommendedScholarshipReducer,
    scholarshipFilters: scholarshipFilterReducer,
    menteeList: menteeListReducer,
    menteeSearch: menteeSearchReducer,
    verifiedMentors: verifiedMentorsReducer,
    createMeeting: createMeetingReducer,
    meetingList: meetingListReducer,
    notificationList: notificationListReducer,
    createReminder: createReminderReducer,
    reminderList: reminderListReducer,
    bookmarkedCourses: bookmarkedCoursesReducer,
    bookmarkedScholarship: bookmarkedScholarshipReducer,
    userUpdateCertificate: userUpdateCertificateReducer,
    twoFAAuthProfile: twoFAAuthProfileReducer,
    mentorPing: mentorPingReducer,
    viewSurvey: viewSurveyReducer,
    saveSurvey: saveSurveyReducer,
    popUpSurvey: popUpSurveyReducer,
    subscriptionProductList: subscriptionProductListReducer,
    applicationList: applicationListReducer,
    applicationGet: applicationGetReducer,
    applicationCreate: applicationCreateReducer,
    applicationUpdate: applicationUpdateReducer
});

export default persistReducer(persistConfig, rootReducer);