import { CertificateType } from '../../enums/certificate-type'
import { USER_GET_TOKEN_SUCCESS, 
         USER_GET_TOKEN_FAIL, 
         USER_LOGIN_REQUEST, 
         USER_LOGIN_SUCCESS, 
         USER_LOGIN_FAIL, 
         USER_LOGIN_REQUIRES_CONFIRMATION,
         USER_LOGIN_FORM_RESET,
         USER_LOGIN_SOCIAL_LOGIN,
         MENTOR_REGISTER_REQUEST, 
         MENTOR_REGISTER_SUCCESS, 
         MENTOR_REGISTER_FAIL, 
         MENTOR_REGISTER_VALIDATED,
         STUDENT_REGISTER_REQUEST, 
         STUDENT_REGISTER_SUCCESS, 
         STUDENT_REGISTER_FAIL,
         USER_PROFILE_REQUEST, 
         USER_PROFILE_FAIL, 
         USER_PROFILE_SUCCESS, 
         USER_PROFILE_UPDATE_REQUEST, 
         USER_PROFILE_UPDATE_FAIL, 
         USER_PROFILE_UPDATE_SUCCESS, 
         USER_LOGOUT_REQUEST, 
         USER_LOGOUT_SUCCESS, 
         USER_LOGOUT_FAIL, 
         USER_LOGOUT_COMPLETE,
         USER_FORGOT_PASSWORD_REQUEST, 
         USER_FORGOT_PASSWORD_SUCCESS, 
         USER_FORGOT_PASSWORD_FAIL, 
         USER_RESET_PASSWORD_REQUEST, 
         USER_RESET_PASSWORD_SUCCESS, 
         USER_RESET_PASSWORD_FAIL, 
         USER_IS_UNAUTHENTICATED, 
         USER_PROFILE_EMAIL_NOT_VERIFIED, 
         USER_IS_AUTHENTICATED,
         USER_CHANGE_PASSWORD_REQUEST, 
         USER_CHANGE_PASSWORD_SUCCESS, 
         USER_CHANGE_PASSWORD_FAIL, 
         USER_UPDATE_PROFILEPIC_FAIL,
         USER_UPDATE_PROFILEPIC_SUCCESS,
         USER_UPDATE_PROFILEPIC_REQUEST,
         USER_PROFILE_PICTURE_UPDATE,
         USER_SUBSCRIBE_REQUEST,
         USER_SUBSCRIBE_SUCCESS,
         USER_SUBSCRIBE_FAIL,
         USER_CHECK_PHONENUMBER_REQUEST,
         USER_CHECK_PHONENUMBER_SUCCESS,
         USER_CHECK_PHONENUMBER_FAIL,
         USER_CHECK_PHONENUMBER_RESET,
         USER_CHANGE_PHONENUMBER_REQUEST,
         USER_CHANGE_PHONENUMBER_SUCCESS,
         USER_CHANGE_PHONENUMBER_FAIL,
         USER_CHANGE_PHONENUMBER_RESET,
         USER_UPDATE_CERTIFICATE_FAIL,
         USER_UPDATE_CERTIFICATE_SUCCESS,
         USER_UPDATE_CERTIFICATE_REQUEST,
    } from './user.constants'

export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_GET_TOKEN_SUCCESS:
            return { ...state, token: action.payload }
        case USER_GET_TOKEN_FAIL:
            return { ...state, error: action.payload }
        case USER_LOGIN_REQUEST:
            return { loading: true }
        case USER_LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                requiresCodeConfirmation: false,
                requiresEmailConfirmation: false,
                requiresExtraMentorFields: false,
                pendingMentorVerification: false,
                loading: false,
                success: true,
                socialLoginAttempted: false,
            }
        case USER_LOGIN_FAIL:
            return {
                loading: false,
                success: false,
                error: action.payload,
            }
        case USER_LOGOUT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case USER_LOGOUT_SUCCESS:
            return {
                loading: false,
                logoutSuccess: true,
                token: null,
                isAuthenticated: false,
                socialLoginAttempted: false,
            }
        case USER_LOGOUT_FAIL:
            return {
                loading: false,
                success: false,
                error: action.payload
            }
        case USER_IS_UNAUTHENTICATED: 
            return {
                ...state,
                loading: false,
                requiresCodeConfirmation: false,
                requiresEmailConfirmation: false,
                requiresExtraMentorFields: false,
                pendingMentorVerification: false,
                isAuthenticated: false,
                socialLoginAttempted: false,
            }
        case USER_IS_AUTHENTICATED: 
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                isMentor: action.isMentor,
                socialLoginAttempted: false,
                requiresCodeConfirmation: false,
                pendingMentorVerification: false,
            }
        case USER_LOGIN_REQUIRES_CONFIRMATION:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                requiresCodeConfirmation: action.payload.requiresCodeConfirmation,
                requiresEmailConfirmation: action.payload.requiresEmailConfirmation,
                requiresExtraMentorFields: action.payload.requiresExtraMentorFields,
                pendingMentorVerification: action.payload.pendingMentorVerification,
                success: true,
                socialLoginAttempted: false,
            }
        case USER_LOGIN_SOCIAL_LOGIN:
            return {
                loading: false,
                isAuthenticated: false,
                socialLoginAttempted: true,
                socialLoginReturnUrl: action.payload.returnUrl
            }
        case USER_LOGOUT_COMPLETE: 
            return { ...state, logoutSuccess: false, socialLoginReturnUrl: null}
        default:
            return state
    }
}

export const mentorRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case MENTOR_REGISTER_REQUEST:
            return { loading: true };
        case MENTOR_REGISTER_SUCCESS:
            return { loading: false, success: true };
        case MENTOR_REGISTER_VALIDATED:
            return { loading: false, success: false, isValid: true };
        case MENTOR_REGISTER_FAIL:
            return { loading: false, success: false, error: action.payload };
        default:
            return state;
    }
};

export const studentRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case STUDENT_REGISTER_REQUEST:
            return { loading: true };
        case STUDENT_REGISTER_SUCCESS:
            return { loading: false, success: true };
        case STUDENT_REGISTER_FAIL:
            return { loading: false, success: false, error: action.payload };
        default:
            return state;
    }
};

export const userProfileReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case USER_PROFILE_REQUEST:
            return { ...state, loading: true }
        case USER_PROFILE_SUCCESS:
            if (!action.payload) return;

            const user = action.payload;
            const totalFields = user.isMentor? 7 : 16;
            const filledMentorFields = (user.first_name != null) 
                                + (user.last_name != null) 
                                + (user.description != null) 
                                + (user.email_address != null) 
                                + (user.degree_title != null)
                                + (user.degree_year != null)
                                + (user.profile_picture != null);
                                
            const filledMenteeFields = (user.first_name != null) 
                                + (user.last_name != null) 
                                + (user.email_address != null) 
                                + (user.country_of_residence != null)
                                + (user.citizenship != null) 
                                + (user.course_of_study != null) 
                                + (user.university_of_study != null) 
                                + (user.degree_level_of_study != null)
                                + (user.country_of_study != null) 
                                + (user.grading_system != null) 
                                + (user.course != null) 
                                + (user.degree_level != null)
                                + (user.delivery_method != null) 
                                + (user.mode_of_study != null)
                                + (user.tuition_budget != null)
                                + (user.profile_picture != null);

            const filledFields =user.isMentor? filledMentorFields: filledMenteeFields;

            user.percentageProgress = (filledFields * 100 / totalFields).toFixed(2);
            user.certificates = [
                ...formatUserDocuments(user.curriculum_vitae, CertificateType.CurriculumVitae),
                ...formatUserDocuments(user.previous_written_academic_work, CertificateType.PreviousWrittenAcademicWork),
                ...formatUserDocuments(user.transcript, CertificateType.Transcript),
                ...formatUserDocuments(user.any_other_information, CertificateType.AnyOtherInformation)
            ];

            return {
                loading: false,
                user: user,
                emailVerified: user && user.hasVerifiedEmail,
            }
        case USER_PROFILE_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case USER_PROFILE_EMAIL_NOT_VERIFIED: 
            return {
                loading: false,
                emailVerified: false,
            }
        case USER_PROFILE_PICTURE_UPDATE:
            state.user.profile_picture = action.payload.image_url;
            return { ...state}
        default:
            return state
    }
}

export const userProfileUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_PROFILE_UPDATE_REQUEST:
            return { ...state, loading: true }
        case USER_PROFILE_UPDATE_SUCCESS:
            return {
                loading: false,
                success: true,
                userProfileInfo: action.payload
            }
        case USER_PROFILE_UPDATE_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const userForgotPasswordReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_FORGOT_PASSWORD_REQUEST:
            return { ...state, loading: true }
        case USER_FORGOT_PASSWORD_SUCCESS:
            return {
                loading: false,
                success: true,
                email: action.payload
            }
        case USER_FORGOT_PASSWORD_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const userResetPasswordReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_RESET_PASSWORD_REQUEST:
            return { ...state, loading: true }
        case USER_RESET_PASSWORD_SUCCESS:
            return {
                loading: false,
                success: true,
            }
        case USER_RESET_PASSWORD_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const userChangePasswordReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_CHANGE_PASSWORD_REQUEST:
            return { ...state, loading: true }
        case USER_CHANGE_PASSWORD_SUCCESS:
            return {
                loading: false,
                success: true,
            }
        case USER_CHANGE_PASSWORD_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const userProfilePictureReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_UPDATE_PROFILEPIC_REQUEST:
            return { ...state, loading: true }
        case USER_UPDATE_PROFILEPIC_SUCCESS:
            return {
                loading: false,
                success: true,
            }
        case USER_UPDATE_PROFILEPIC_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const userSubscribeReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_SUBSCRIBE_REQUEST:
            return { ...state, loading: true }
        case USER_SUBSCRIBE_SUCCESS:
            return {
                loading: false,
                success: true,
            }
        case USER_SUBSCRIBE_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
};

export const userCheckPhoneNumberReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_CHECK_PHONENUMBER_REQUEST:
            return { ...state, loading: true }
        case USER_CHECK_PHONENUMBER_SUCCESS:
            return {
                loading: false,
                success: true,
            }
        case USER_CHECK_PHONENUMBER_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case USER_CHECK_PHONENUMBER_RESET:
            return {};
        default:
            return state;
    }
};


export const userChangePhoneNumberReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_CHANGE_PHONENUMBER_REQUEST:
            return { ...state, loading: true }
        case USER_CHANGE_PHONENUMBER_SUCCESS:
            return {
                loading: false,
                success: true,
            }
        case USER_CHANGE_PHONENUMBER_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case USER_CHANGE_PHONENUMBER_RESET:
            return {};
        default:
            return state;
    }
};

export const userUpdateCertificateReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_UPDATE_CERTIFICATE_REQUEST:
            return { ...state, loading: true }
        case USER_UPDATE_CERTIFICATE_SUCCESS:
            return {
                loading: false,
                success: true,
            }
        case USER_UPDATE_CERTIFICATE_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
};

const formatUserDocuments = (documentObject, type) => {
    const formattedDocuments = [];

    if (!documentObject) return formattedDocuments;

    Object.keys(documentObject)?.forEach(key => {
        documentObject[key].type = type;
        documentObject[key].key = key;
        documentObject[key].id = key;
        formattedDocuments.push(documentObject[key]);
    });

    return formattedDocuments;
}