import { HiOutlineUser } from "react-icons/hi";
import { FiUsers, FiSliders } from "react-icons/fi";
import { BiCog } from 'react-icons/bi';
import { BsFillBookmarkFill } from "react-icons/bs";

export const UserProfileMenuData = [
    {
        label: "My Profile",
        icon: <HiOutlineUser />,
        to: `/profile/myprofile`,
        color: '#9B51E0'
    },
    {
        label: "My Bookmarks",
        icon: <BsFillBookmarkFill />,
        to: `/profile/bookmarks`,
        color: '#2D9CDB'
    },
    {
        label: "My Applications",
        icon: <BsFillBookmarkFill />,
        to: `/profile/applications`,
        color: '#2D9CDB'
    },
    {
        label: "Mentors",
        icon: <FiUsers />,
        to: `/profile/mentors`,
        color: '#A5A6F6'
    },
    // {
    //     label: "Application History",
    //     icon: <FiSend />,
    //     to: `/profile/application-history`,
    //     color: '#F2C94C'
    // },
    {
        label: "Settings",
        icon: <FiSliders />,
        to: `/profile/settings`,
        color: '#782970'
    },
    // {
    //     label: "Settings",
    //     icon: <RiSettings3Line />,
    //     to: `/profile/settings`,
    //     color: '#56CCF2'
    // },
    {
        label: "Sign Out",
        icon: <BiCog />,
        color: "#EB5757",
        bottomLink: true,
        isLogOut: true,
    },
];

export const MentorProfileMenuData = [
    {
        label: "My Profile",
        icon: <HiOutlineUser />,
        to: `/profile/myprofile`,
        color: '#9B51E0'
    },
    // {
    //     label: "My Bookmarks",
    //     icon: <BsFillBookmarkFill />,
    //     to: `/profile/bookmarks`,
    //     color: '#2D9CDB'
    // },
    {
        label: "Chats",
        icon: <FiUsers />,
        to: `/profile/mentors`,
        color: '#A5A6F6'
    },
    // {
    //     label: "Application History",
    //     icon: <FiSend />,
    //     to: `/profile/application-history`,
    //     color: '#F2C94C'
    // },
    {
        label: "Settings",
        icon: <FiSliders />,
        to: `/profile/settings`,
        color: '#782970'
    },
    // {
    //     label: "Settings",
    //     icon: <RiSettings3Line />,
    //     to: `/profile/settings`,
    //     color: '#56CCF2'
    // },
    {
        label: "Sign Out",
        icon: <BiCog />,
        color: "#EB5757",
        bottomLink: true,
        isLogOut: true,
    },
]

export const LandingPageNavLinksData = [
    {
        label: "Explore",
        location: "explore",
    },
    {
        label: "Pricing",
        location: "pricing",
    },
    {
        label: "About Us",
        to: `/about-us`,
    },
    {
        label: "Blog",
        to: `/blog`,
    },
];


export const DefaultNavLinksData = [
    {
        label: "Courses",
        to: `/course-categories`,
    },
    {
        label: "Scholarship",
        to: `/scholarship`
    },
    {
        label: "Mentorship",
        to: `/mentorship`,
    },
    {
        label: "Recommended",
        to: `/recommended`,
    }
];
