import React, { useEffect, useState, useRef } from "react";
import { Footer } from "../footer/footer";
import { Navbar } from "../navbar";
import { NewsletterSection } from "../landing-page/newsletter-section";
import { useParams } from "react-router-dom";
import { countriesContentData } from "../country-info/Countryinfo";
import {
  CountryCourseContainer,
  CountryCourseSection,
  CountryCourseFormSection,
  InfoHeadingForm,
  TextHeading,
} from "../country-content/content-style";

import { FormSearchFilter } from "./form-search-filter/form-search-filter";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Container } from "../country-content/content-style";
import {
  CountryClusterContainer,
  CountryClusterText,
  CountryClusterExpand,
  CountryClusterExpandDetails,
  CountryClusterTextContainer,
  HeaderText,
  DescriptionText,
  MobileCountryCourseContainer,
} from "../country-content/content-style";

import SearchByCountrySection from "../landing-page/search-by-country-section/index";

function Content() {
  const [openSections, setOpenSections] = useState(Array(8).fill(false));
  const toggleSection = (index) => {
    const updatedSections = [...openSections];
    updatedSections[index] = !updatedSections[index];
    setOpenSections(updatedSections);
  };

  const { id } = useParams();
  const [countryData, setCountryData] = useState(null);

  const WhyStudyRef = useRef(null);
  const UniversitiesRef = useRef(null);
  const ScholarshipRef = useRef(null);
  const GeneralApplicationRef = useRef(null);
  const GeneralVisaRef = useRef(null);
  const TuitionEstimateRef = useRef(null);
  const FunFactsRef = useRef(null);
  const WorkStudyRef = useRef(null);
  const MigrationPlanRef = useRef(null);

  const scrolltoSection = (courseMenu) => {
    let locationRef;
    let block = "center";

    switch (courseMenu) {
      case "Why Study":
        locationRef = WhyStudyRef;
        block = "center";
        break;

      case "Universities":
        locationRef = UniversitiesRef;
        block = "center";
        break;

      case "Scholarship":
        locationRef = ScholarshipRef;
        block = "center";
        break;

      case "General Application":
        locationRef = GeneralApplicationRef;
        block = "center";
        break;

      case "General Visa":
        locationRef = GeneralVisaRef;
        block = "center";
        break;

      case "Tuition Estimate":
        locationRef = TuitionEstimateRef;
        block = "center";
        break;

      case " Fun Facts":
        locationRef = FunFactsRef;
        block = "center";
        break;

      case "Work Study":
        locationRef = WorkStudyRef;
        block = "center";
        break;

      case "Migration Plan":
        locationRef = MigrationPlanRef;
        block = "center";
        break;

      default:
        return;
    }
    locationRef.current?.scrollIntoView({ behavior: "smooth", block });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const countryData = countriesContentData.find(
      (country) => country.id === parseInt(id)
    );
    setCountryData(countryData);
  }, [id]);

  const {
    country_name,
    location,
    study_reason,
    ease_of_application,
    cost_of_living,
    weather,
    top_university,
    unis_with_highest_acceptance_rate,
    best_study_areas,
    available_scholarships,
    gen_application_requirements,
    gen_visa_requirement,
    tuition_estimate,
    fun_facts,
    work_study_information,
    migration_plan,
    header,
  } = countryData || {};

  const [activeContainer, setActiveContainer] = useState(null);
  const handleContainerClick = (containerId) => {
    setActiveContainer(containerId === activeContainer ? null : containerId);
  };

  const myStyle = {
    margin: '0 auto',
    maxWidth: '118rem'
  };

  return (
    <div>
      <Navbar />

      <CountryCourseContainer>
        <CountryCourseSection>
          <div style={myStyle}>
            <CountryCourseFormSection>
              <InfoHeadingForm>
                <h2>Study in {header}</h2>
              </InfoHeadingForm>
              <TextHeading>
                <div>
                  We help you realise your dreams of schooling in your dream
                  country. Explore what is like to live and study in your desired
                  country.
                </div>
              </TextHeading>
              <FormSearchFilter  location={location ?? country_name} />
            </CountryCourseFormSection>
          </div>
        </CountryCourseSection>
      </CountryCourseContainer>

      <MobileCountryCourseContainer>
        <img
          src="/assets/images/uk-item.png"
          style={{ backgroundColor: "rgba(12, 14, 38, 0.7)" }}
        />

        <CountryCourseFormSection>
          <InfoHeadingForm>
            <h2>Study in {header}</h2>
          </InfoHeadingForm>
          <TextHeading>
            <div>
              We help you realise your dreams of schooling in your dream
              country. Explore what is like to live and study in your desired
              country.
            </div>
          </TextHeading>
          <FormSearchFilter />
        </CountryCourseFormSection>

        <CountryClusterExpand>
          <CountryClusterExpandDetails>
            <CountryClusterTextContainer>
              <HeaderText onClick={() => toggleSection(0)}>
                <h1>
                  Why Study
                  <FontAwesomeIcon
                    icon={openSections[0] ? faChevronUp : faChevronDown}
                  />
                </h1>
              </HeaderText>

              {openSections[0] && (
                <DescriptionText>
                  <h2>{study_reason}</h2>
                </DescriptionText>
              )}

              {openSections[0] && (
                <HeaderText>
                  <h1> Ease of application </h1>
                </HeaderText>
              )}

              {openSections[0] && (
                <DescriptionText>
                  <h2>{ease_of_application}</h2>
                </DescriptionText>
              )}

              {openSections[0] && (
                <HeaderText>
                  <h1> Weather </h1>
                </HeaderText>
              )}
              {openSections[0] && (
                <DescriptionText>
                  <h2>{weather}</h2>
                </DescriptionText>
              )}
              {openSections[0] && (
                <HeaderText>
                  <h1> Cost of living </h1>
                </HeaderText>
              )}
              {openSections[0] && (
                <DescriptionText>
                  <h2>{cost_of_living}</h2>
                </DescriptionText>
              )}

              <HeaderText onClick={() => toggleSection(1)}>
                <h1>
                  Universities in {country_name}
                  <FontAwesomeIcon
                    icon={openSections[1] ? faChevronUp : faChevronDown}
                  />
                </h1>
              </HeaderText>
              {openSections[1] && (
                <DescriptionText>
                  <h2>{top_university}</h2>
                </DescriptionText>
              )}
              {openSections[1] && (
                <HeaderText>
                  <h1>Best Universities for International Students</h1>
                </HeaderText>
              )}
              {openSections[1] && (
                <DescriptionText>
                  <h2>{ }</h2>{" "}
                </DescriptionText>
              )}
              {openSections[1] && (
                <HeaderText>
                  <h1> Universities With Highest Acceptance Rate </h1>
                </HeaderText>
              )}
              {openSections[1] && (
                <DescriptionText>
                  <h2>{unis_with_highest_acceptance_rate}</h2>
                </DescriptionText>
              )}
              {openSections[1] && (
                <HeaderText>
                  <h1> Other Universities </h1>
                </HeaderText>
              )}
              {openSections[1] && (
                <DescriptionText>
                  <h2>{ }</h2>
                </DescriptionText>
              )}
              {openSections[1] && (
                <HeaderText>
                  <h1> Best Study Areas </h1>
                </HeaderText>
              )}
              {openSections[1] && (
                <DescriptionText>
                  <h2>{best_study_areas}</h2>
                </DescriptionText>
              )}

              <HeaderText onClick={() => toggleSection(2)}>
                <h1 ref={ScholarshipRef}>
                  Scholarship
                  <FontAwesomeIcon
                    icon={openSections[2] ? faChevronUp : faChevronDown}
                  />
                </h1>
              </HeaderText>
              {openSections[2] && (
                <DescriptionText>
                  <h2>{available_scholarships}</h2>
                </DescriptionText>
              )}

              <HeaderText onClick={() => toggleSection(3)}>
                <h1>
                  General Application Requirements
                  <FontAwesomeIcon
                    icon={openSections[3] ? faChevronUp : faChevronDown}
                  />
                </h1>
              </HeaderText>
              {openSections[3] && (
                <DescriptionText>
                  <h2>{gen_application_requirements}</h2>
                </DescriptionText>
              )}

              <HeaderText onClick={() => toggleSection(4)}>
                <h1>
                  General Visa Requirements
                  <FontAwesomeIcon
                    icon={openSections[4] ? faChevronUp : faChevronDown}
                  />
                </h1>
              </HeaderText>
              {openSections[4] && (
                <DescriptionText>
                  <h2>{gen_visa_requirement}</h2>
                </DescriptionText>
              )}

              <HeaderText onClick={() => toggleSection(5)}>
                <h1>
                  Tuition Estimate
                  <FontAwesomeIcon
                    icon={openSections[5] ? faChevronUp : faChevronDown}
                  />
                </h1>
              </HeaderText>
              {openSections[5] && (
                <DescriptionText>
                  <h2>{tuition_estimate}</h2>
                </DescriptionText>
              )}
              {openSections[5] && (
                <HeaderText>
                  <h1> Cost of Living </h1>
                </HeaderText>
              )}
              {openSections[5] && (
                <DescriptionText>
                  <h2>{cost_of_living}</h2>
                </DescriptionText>
              )}

              <HeaderText onClick={() => toggleSection(6)}>
                <h1 ref={FunFactsRef}>
                  Fun Fact
                  <FontAwesomeIcon
                    icon={openSections[6] ? faChevronUp : faChevronDown}
                  />
                </h1>
              </HeaderText>
              {openSections[6] && (
                <DescriptionText>
                  <h2>{fun_facts}</h2>
                </DescriptionText>
              )}

              <HeaderText onClick={() => toggleSection(7)}>
                <h1 ref={WorkStudyRef}>
                  Work Study Information
                  <FontAwesomeIcon
                    icon={openSections[7] ? faChevronUp : faChevronDown}
                  />
                </h1>
              </HeaderText>
              {openSections[7] && (
                <DescriptionText>
                  <h2>{work_study_information} </h2>
                </DescriptionText>
              )}

              <HeaderText onClick={() => toggleSection(8)}>
                <h1 ref={MigrationPlanRef}>
                  Migration Plan
                  <FontAwesomeIcon
                    icon={openSections[8] ? faChevronUp : faChevronDown}
                  />
                </h1>
              </HeaderText>
              {openSections[8] && (
                <DescriptionText>
                  <h2>{migration_plan}</h2>
                </DescriptionText>
              )}
            </CountryClusterTextContainer>
          </CountryClusterExpandDetails>
        </CountryClusterExpand>
      </MobileCountryCourseContainer>

      <Container>
        <CountryClusterContainer>
          <CountryClusterText>
            <h1
              onClick={() => {
                scrolltoSection("Why Study");

                handleContainerClick("whyStudy");
              }}
              className={activeContainer === "whyStudy" ? "active" : ""}
            >
              Why Study in {country_name}
            </h1>
            <h1
              onClick={() => {
                scrolltoSection("Universities");

                handleContainerClick("Universities");
              }}
              className={activeContainer === "Universities" ? "active" : ""}
            >
              Universities in {country_name}
            </h1>
            <h1
              onClick={() => {
                scrolltoSection("Scholarship");

                handleContainerClick("Scholarship");
              }}
              className={activeContainer === "Scholarship" ? "active" : ""}
            >
              Scholarship
            </h1>
            <h1
              onClick={() => {
                scrolltoSection("General Application");

                handleContainerClick("General Application");
              }}
              className={
                activeContainer === "General Application" ? "active" : ""
              }
            >
              General application requirements
            </h1>
            <h1
              onClick={() => {
                scrolltoSection("General Visa");

                handleContainerClick("General Visa");
              }}
              className={activeContainer === "General Visa" ? "active" : ""}
            >
              General visa requirements
            </h1>
            <h1
              onClick={() => {
                scrolltoSection("Tuition Estimate");

                handleContainerClick("Tuition Estimate");
              }}
              className={activeContainer === "Tuition Estimate" ? "active" : ""}
            >
              Tuition estimate
            </h1>
            <h1
              onClick={() => {
                scrolltoSection("Fun Facts");

                handleContainerClick("Fun Facts");
              }}
              className={activeContainer === "Fun Facts" ? "active" : ""}
            >
              Fun facts
            </h1>
            <h1
              onClick={() => {
                scrolltoSection("Work Study");

                handleContainerClick("Work Study");
              }}
              className={activeContainer === "Work Study" ? "active" : ""}
            >
              Work study information
            </h1>
            <h1
              onClick={() => {
                scrolltoSection("Migration Plan");

                handleContainerClick("Migration Plan");
              }}
              className={activeContainer === "Migration Plan" ? "active" : ""}
            >
              Migration plan
            </h1>
          </CountryClusterText>

          <CountryClusterExpand>
            <CountryClusterExpandDetails>
              <CountryClusterTextContainer>
                <HeaderText>
                  <h1 ref={WhyStudyRef}>Why Study </h1>

                  <DescriptionText>
                    <h2>{study_reason}</h2>
                  </DescriptionText>
                </HeaderText>

                <HeaderText>
                  <h1>
                    <ul>
                      <li>Ease of application</li>
                    </ul>
                  </h1>

                  <DescriptionText>
                    <h2>{ease_of_application}</h2>
                  </DescriptionText>
                </HeaderText>

                <HeaderText>
                  <h1>
                    <ul>
                      <li>Weather</li>
                    </ul>
                  </h1>
                  <DescriptionText>
                    <h2>{weather}</h2>
                  </DescriptionText>
                </HeaderText>

                <HeaderText>
                  <h1>
                    <ul>
                      <li>Cost of living</li>
                    </ul>
                  </h1>
                  <DescriptionText>
                    <h2>{cost_of_living}</h2>
                  </DescriptionText>
                </HeaderText>

                <HeaderText>
                  <h1 ref={UniversitiesRef}>Universities in {country_name}</h1>
                  <DescriptionText>
                    <h2>{top_university}</h2>
                  </DescriptionText>
                </HeaderText>

                <HeaderText>
                  <h1>Best Universities for International Students</h1>
                  <DescriptionText>
                    <h2>{ }</h2>
                  </DescriptionText>
                </HeaderText>

                <HeaderText>
                  <h1> Universities With Highest Acceptance Rate </h1>
                  <DescriptionText>
                    <h2>{unis_with_highest_acceptance_rate}</h2>
                  </DescriptionText>
                </HeaderText>

                <HeaderText>
                  <h1> Other Universities </h1>
                  <DescriptionText>
                    <h2>{ }</h2>
                  </DescriptionText>
                </HeaderText>

                <HeaderText>
                  <h1> Best Study Areas </h1>
                  <DescriptionText>
                    <h2>{best_study_areas}</h2>
                  </DescriptionText>
                </HeaderText>

                <HeaderText>
                  <h1 ref={ScholarshipRef}> Scholarship </h1>
                  <DescriptionText>
                    <h2>{available_scholarships}</h2>
                  </DescriptionText>
                </HeaderText>

                <HeaderText>
                  <h1 ref={GeneralApplicationRef}>
                    General Application Requirements
                  </h1>
                  <DescriptionText>
                    <h2>{gen_application_requirements}</h2>
                  </DescriptionText>
                </HeaderText>

                <HeaderText>
                  <h1 ref={GeneralVisaRef}> General Visa Requirements</h1>
                  <DescriptionText>
                    <h2>{gen_visa_requirement}</h2>
                  </DescriptionText>
                </HeaderText>

                <HeaderText>
                  <h1 ref={TuitionEstimateRef}> Tuition Estimate </h1>
                  <DescriptionText>
                    <h2>{tuition_estimate}</h2>
                  </DescriptionText>
                </HeaderText>

                <HeaderText>
                  <h1> Cost of Living </h1>
                  <DescriptionText>
                    <h2>{cost_of_living}</h2>
                  </DescriptionText>
                </HeaderText>

                <HeaderText>
                  <h1 ref={FunFactsRef}> Fun Fact </h1>
                  <DescriptionText>
                    <h2>{fun_facts}</h2>
                  </DescriptionText>
                </HeaderText>

                <HeaderText>
                  <h1 ref={WorkStudyRef}> Work Study Information </h1>
                  <DescriptionText>
                    <h2>{work_study_information}</h2>
                  </DescriptionText>
                </HeaderText>

                <HeaderText>
                  <h1 ref={MigrationPlanRef}> Migration Plan </h1>
                  <DescriptionText>
                    <h2>{migration_plan}</h2>
                  </DescriptionText>
                </HeaderText>
              </CountryClusterTextContainer>
            </CountryClusterExpandDetails>
          </CountryClusterExpand>
        </CountryClusterContainer>
      </Container>

      <SearchByCountrySection countryData={countryData} />
      <NewsletterSection />
      <Footer />
    </div>
  );
}

export default Content;
