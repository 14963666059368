import { 
    NOTIFICATION_LIST_REQUEST, 
    NOTIFICATION_LIST_SUCCESS,
    NOTIFICATION_LIST_FAIL
} from "./notification.constants";

export const notificationListReducer = (state = { notifications: [] }, action) => {
    switch (action.type) {
        case NOTIFICATION_LIST_REQUEST:
            return { ...state, loading: true, success: false, allNotificationsRetrieved: false };
        case NOTIFICATION_LIST_SUCCESS:
            return { loading: false, 
                success: true, 
                notifications: action.updateList? [...state.notifications, ...action.payload] : action.payload,
                count: action.count, 
                unreadCount: action.unreadCount,
                allNotificationsRetrieved: action.payload?.length === 0, 
            };
        case NOTIFICATION_LIST_FAIL:
            return { loading: false, error: action.payload, success: false };
        default:
            return state;
    }
}