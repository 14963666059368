import { NavLink as Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const NavbarContainer = styled.nav`
   
   width: 100%;
   background-color: ${({ theme, variant }) => {
      switch (variant) {
         case "dark":
            return "#0C0E26";

         case "light":
         default:
            return theme.colors.bgGreen;
      }
   }
   };
   backdrop-filter: blur(100px);
   padding: 0 2rem;
   
   position: fixed;
   top: 0;
   z-index: 150;
   
   @media (max-width: 480px) {
      //mobile
     padding: 0 2rem;
   }


`;

export const Container = styled.div`
margin: 0 auto;
    max-width: 124rem;
    display: flex;
   align-items: center;
   justify-content: space-between;
   height: 8rem;
   @media screen and (max-width: 480px) {
      justify-content: flex-start;
   }

`

export const NavBarLinks = styled.div`
   display: flex;

   @media screen and (max-width: 820px) {
      display: none;
   }
`;

export const NavLinks = styled.div`
   font-family: 'Avenir';
   font-size: 1.6rem;
   display: flex;
   align-items: center;

   @media screen and (max-width: 1190px) {
      flex-direction: row;
      align-items: center;
      display: flex;
      gap: 3rem;
      

      a {
         display: block;
         height: 45px;
         font-size: 1.48rem;
         font-weight: 400;
         margin-top: 2rem;
      }
   }
   @media screen and (max-width: 820px) {
      flex-direction: column;
      align-items: center;
      display: flex;
      align-items: center;
      gap: 0;
      
      a {
         display: block;
         height: 45px;
         font-size: 1.48rem;
         font-weight: 400;
         margin-top: 2rem;
      }
   }
   @media screen and (max-width: 480px) {
         display: flex;
         flex-direction: column;
         align-items: center;
         gap: 0;
      a {
         font-weight: 400;
         margin-top: 2rem;
      }
   }
`;

export const NavLinkItem = styled(Link)`
    text-decoration: none;
    font-weight: 400;
    font-size: 1.4rem;
    color: ${({ theme, variant }) => {
      switch (variant) {
         case "dark":
            return theme.colors?.white70;
         case "light":
         default:
            return theme.colors?.textGrey;
      }
   }
   };

    &:not(:first-child) {
        margin-left: 4.1rem;

        @media screen and (max-width: 1190px)  {
            margin-left: 3rem;
        }

        @media screen and (max-width: 1190px)  {
            margin-left: 0;
       }
    }

    &.active:not(.no-underline) {
       ${({ theme, variant }) => {
         switch (variant) {
            case "dark":
               return `
                     color: ${theme.colors?.white};
                     border-bottom: 1px solid  ${theme.colors?.japaaGreen80};
                  `;

            case "light":
            default:
               return `
                     color: ${theme.colors?.japaaBlack};
                     font-weight: 700;
                  `
         }
      }
    
   }
`;
export const NavLinkItems = styled(Link)`
      text-decoration: none;
      font-weight: 400;
      font-size: 1.4rem;
   color: ${({ theme, variant }) => {
      switch (variant) {
         case "dark":
            return theme.colors?.white70;
         case "light":
         default:
            return theme.colors?.textGrey;
      }
   }
   };

   &:not(:first-child) {
        margin-left: 4.1rem;

        @media screen and (max-width: 1190px) {
            margin-left: 0px;
        }
   }

   &.active(.no-underline) {
       ${({ theme, variant }) => {
         switch (variant) {
            case "dark":
               return `
                     color: ${theme.colors?.white};
                     border-bottom: 1px solid  ${theme.colors?.japaaGreen80};
                  `;

            case "light":
            default:
               return `
                     color: ${theme.colors?.japaaBlack};
                     font-weight: 700;
                  `
         }
      }
    
   }
`;

export const OtherLinks = styled.div`
   font-size: 1.5rem;
   display: flex;
   align-items: center;

   @media screen and (max-width: 820px) {
    display: none;
   }
`;

export const AuthOtherLinks = styled.div`
   display: flex;
   align-items: center;
   position: relative;
   cursor: pointer;
   @media screen and (max-width: 820px) {
       margin-left: auto;
   }
  
`;

export const BackDrop = styled.div`
   visibility: hidden;

   @media screen and (max-width: 1190px) {
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      display: block;
      z-index: 129;
      cursor: pointer;
      background: none;
      transition: background 0.2s ease-in;

      ${({ menuActive }) =>
      menuActive &&
      css`
            visibility: visible;
            background: rgba(0, 0, 0, 0.8);
         `}
   }
`;

export const MobileNavContainer = styled.div`
   display: none;

   @media screen and (max-width: 820px) {
      background-color: ${({ theme }) => theme.colors?.bgGreen};
      position: fixed;
      top: 0;
      height: 100vh;
      box-shadow: rgb(0 0 0 / 20%) -4px 20px 10px;
      border: 1px solid rgba(101, 100, 100, 0.1);
      z-index: 2147483647;
      overflow: scroll;
      transition: right 0.3s ease-in-out 0s, opacity 0.4s ease-in-out 0.1s;
      opacity: 0;
      right: -310px;
      backface-visibility: hidden;
      width: 300px;
      display: flex;
      flex-direction: column;
      margin: auto;

      ${({ menuActive }) =>
      menuActive &&
      css`
            right: 0px;
            opacity: 1;
         `}
   }

   @media screen and (max-width: 400px) {
      //width: 100%;
   }
`;

export const MenuIcon = styled.div`
color: ${({ theme, variant }) => {
      switch (variant) {
         case "dark":
            return theme.colors?.white70;
         case "light":
         default:
            return theme.colors?.black;
      }
   }};

   display: none;
   cursor: pointer;
   font-size: 2rem;

   .times-icon {
      text-align: center;
      padding: 4rem;
   }

   @media screen and (max-width: 1190px) { 
      display: none;
      .times-icon {
         text-align: center ;
         padding: 4rem;
      }
   }
   @media (max-width: 820px) {
      display: block;
      font-size: 24px;
      padding-top: 12px;
      margin-right: 2rem;
  }
`;

export const StyledAvatarContainer = styled.div`
   display: flex;
   align-items: center;
   gap: 1.4rem;
`;

export const NotificationIconContainer = styled.div`
   position: relative;
   margin-right: 2.7rem;

   .notification-icon {
      font-size: 2.3rem;
   }
`;

export const NotificationCount = styled.div`
   position: absolute;
   top: -10%;
   right: -5%;
   width: 1.2rem;
   height: 1.2rem;
   border-radius: 0.6rem;
   background-color: ${({ theme }) => theme.colors?.japaaGreen};
   text-align: center;
   font-family: 'Inter';
   font-weight: 400;
   font-size: 0.6rem;
   line-height: 1.2rem;
   color: ${({ theme }) => theme.colors?.japaaBlack};
`;