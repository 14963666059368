import styled from "styled-components";

export const BrandImageStyledContainer = styled.div`
   width: 20rem;
   height: 3.7rem;
   cursor: pointer;
   
   @media only screen and (max-width: 1190px) {
      width: 7.7rem;
   }
`;

export const BrandImageStyledComponent = styled.img`
   width: 100%;
   height: 100%;
   object-fit: contain;
`;
