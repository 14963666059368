import { 
    CHAT_USERS_REQUEST, 
    CHAT_USERS_SUCCESS, 
    CHAT_USERS_FAIL, 
    CHAT_USER_UPDATE,
    CHAT_MESSAGES_REQUEST, 
    CHAT_MESSAGES_SUCCESS, 
    CHAT_MESSAGES_FAIL,
    CHAT_MESSAGES_ADD,
    SEND_CHAT_MESSAGE_REQUEST,
    SEND_CHAT_MESSAGE_SUCCESS,
    SEND_CHAT_MESSAGE_FAIL,
    MENTOR_PING_REQUEST,
    MENTOR_PING_SUCCESS,
    MENTOR_PING_FAIL
} from "./chat.constants";

export const chatUsersReducer = (state = { users: []}, action) => {
    switch (action.type) {
        case CHAT_USERS_REQUEST:
            return { ...state, loading: true, allUsersRetrieved: false };
        case CHAT_USERS_SUCCESS:
            return { 
                loading: false, 
                success: true, 
                allUsersRetrieved: action.payload.length === 0, 
                users: action.updateList? [...state.users, ...action.payload] : action.payload,
                activeUser:  action.activeUser
             };

        case CHAT_USER_UPDATE:
            let userIndex = state.users.findIndex(c => c.id == action.user.chatId); 
            state.users[userIndex] = { ...state.users[userIndex], ...action?.user?.updatedUser };
            return {  ...state, users: [...state.users]};
        case CHAT_USERS_FAIL:
            return { loading: false, success: false, error: action.payload };
        default:
            return state;
    }
};

export const chatMessagesReducer = (state = { messages: []}, action) => {
    switch (action.type) {
        case CHAT_MESSAGES_REQUEST:
            
            return { 
                ...state, 
                loading: true, 
                allMessagesRetrieved: false,
                messages: action.clearList? [] : state.messages 
            };
        case CHAT_MESSAGES_SUCCESS:
            return { 
                loading: false, 
                success: true, 
                allMessagesRetrieved: action.payload.length === 0, 
                messages: action.updateList? [...state.messages, ...action.payload] : action.payload 
            };
        case CHAT_MESSAGES_ADD:
            if (action.message) {
                state.messages.push(action.message);
            }
            return {...state};
        case CHAT_MESSAGES_FAIL:
            return { loading: false, success: false, error: action.payload };
        default:
            return state;
    }
};

export const sendChatMessageReducer = (state = {}, action) => {
    switch (action.type) {
        case SEND_CHAT_MESSAGE_REQUEST:
            return { 
                ...state, 
                loading: true, 
                failed: false, 
                success: false 
            };
        case SEND_CHAT_MESSAGE_SUCCESS:
            return {
                loading: false,
                success: true,
                failed: false,
            };
        case SEND_CHAT_MESSAGE_FAIL:
            return {
                loading: false,
                failed: true,
                error: action.payload
            };

        default:
            return state;
    }
}

export const mentorPingReducer = (state = {}, action) => {
    switch (action.type) {
        case MENTOR_PING_REQUEST:
            return { 
                ...state, 
                loading: true, 
                failed: false, 
                success: false 
            };
        case MENTOR_PING_SUCCESS:
            return {
                loading: false,
                success: true,
                failed: false,
            };
        case MENTOR_PING_FAIL:
            return {
                loading: false,
                failed: true,
                error: action.payload
            };

        default:
            return state;
    }
}