import styled from "styled-components";

export const SurveyModal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  gap: 24px;
  position: fixed; 
  left: 50%; 
  top: 50%; 
  transform: translate(-50%, -50%);
  z-index: 19999999;
  background-color: #FFFFFF;
  border-radius: 10px;
  width: ${(props) => (props.courseTab ? 'max-content' : 'min-content')};

  .review-container {
    padding: 0;
  }

  .text-section {
    display: flex;
    flex-direction: column;
  }
  .icon {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
  .main-text {
    font-style: normal;
    font-weight: 750;
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    color: #0C0E26;
    margin-top: 1rem;
  }

  .sub-container {
    padding: 20px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;

    .star-image {
      cursor: pointer;
      width: 40px;
      height: 40px;
    }
  }

  .second-sub-container {
    padding: 20px 0 ;
    display: flex;
    flex-direction: column;
    align-items: center;  
  }

  .second-star-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 2rem;
    padding: 1rem 0;
  }

  .second-star-item {
    border-radius: 60px;
    width: 40px;
    height: 40px;
    font-size: 17px;
    background-color: #E5E6ED;
    padding: 1rem;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .second-star-item.selected {
    background-color: #37FED9;
  }

  .text {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 350;
    font-size: 14px;
    line-height: 16px;
    color: #4D4E66;
  }

  .star-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
  }

  .sub-text {
    font-style: normal;
    font-weight: 350;
    font-size: 14px;
    line-height: 16px;
    padding-top: 1rem;
    color: #0C0E26;
  }

  .second-sub-text {
    font-style: normal;
    font-weight: 750;
    font-size: 20px;
    line-height: 24px;
    color: #0C0E26;
  }

  .textarea-container{
    display: flex;
    justify-content: center;
    margin: 2rem 0;

    .comment-box {
      outline: none;
      border: 1px solid #5D5E6E;
      padding: 2rem;
      border-radius: 3px;
      font-style: normal;
      font-weight: 350;
      font-size: 12px;
      line-height: 14px;
      resize: none;
      width: 90%;
    
      ::placeholder {
        color: #C4C4C4;
      }
    }
  }

  .sub-textarea-container{
    display: flex;
    margin: 2rem 0;

    .comment-box {
      outline: none;
      border: 1px solid #0C0E26;
      padding: 2rem;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      width: 100%;
      line-height: 19px;
      resize: none;
    
      ::placeholder {
        color: #C4C4C4;
      }
    }
  }


  .btns {
    padding: 9px 24px;
    background: #37FED9;
    border-radius: 4px;
    border: none;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
  }
  .btn-container {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 1024px) {
    ${(props) => (props.courseTab ? '50%' : 'max-content')};
  }

  @media (max-width: 480px) {
    padding: 10px;
    ${(props) => (props.courseTab ? '70%' : '70%')};

   .review-container {
    width: 100%;
   } 

    .main-text {
      font-weight: 750;  
      font-size: 12px;   
      margin-top: 1rem;
    }

    .second-sub-container {
      padding: 10px 0 ;
    }

    .sub-text {
      text-align: center;
      font-size: 12px;
      margin-bottom: 0;
    }
    .second-sub-text {
      text-align: center;
      font-size: 12px;
      margin-bottom: 0;
    }

    .sub-container {
      margin-top: 1rem;
      padding: 0;
      justify-content: center;

      .star-image {
        cursor: pointer;
        width: 30px;
        height: 30px;
      }
    }

    .second-star-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: center;
    } 

    .second-star-item {
      border-radius: 60px;
      width: 20px;
      height: 20px;
      font-size: 12px;
      padding: 1rem;
    }

    .text {
      display: flex;
      justify-content: space-between;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      // padding: 2rem 0;
      line-height: 24px;
      color: #4D4E66;
    }

    .textarea-container{
      display: flex;
      justify-content: center;
      margin: 1rem 0 2rem 0;

      .comment-box {
        padding: 1rem;
        font-size: 10px;
      }
    }
    .sub-textarea-container{
      display: flex;
      justify-content: center;
      margin: 1rem 0 2rem 0;

      .comment-box {
        padding: 1rem;
        font-size: 10px;
      }
    }

    .btns {
      padding: 9px 20px;
      font-weight: 700;
      font-size: 10px;
    }
  } 
`;
