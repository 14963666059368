
import { FaTimes } from "react-icons/fa";
import {
    NavLinks,
    MobileNavContainer,
    NavLinkItem,
    MenuIcon,
} from "./navbar.style";
import { Button } from "../button/button";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { LandingPageNavLinksData, DefaultNavLinksData } from '../../services/menuData';


const MobileNav = ({
    menuActive,
    setNavIconClicked,
    theme = "light",
    onNavLinkClick
}) => {

    const userLogin = useSelector((state) => state.userLogin);
    const { isAuthenticated } = userLogin;
    const userProfile = useSelector((state) => state.userProfile);
    const { user } = userProfile;
    const location = useLocation();
    const handleClick = () => {
        setNavIconClicked(!menuActive);
    };
    return (
        <>
            
                <MobileNavContainer menuActive={menuActive} className="navbaritems">
                    <>
                        <MenuIcon className="times-icon" style={{ textAlign: 'center', padding: '2rem' }} onClick={handleClick}><FaTimes /></MenuIcon>
                        <NavLinks>
                             {(isAuthenticated && user && user.is_admin) ? (
                                <>
                                    {DefaultNavLinksData.map((linkData, i) => (
                                        <NavLinkItem key={i} variant={theme} to={linkData.to}>{linkData.label}</NavLinkItem>
                                    ))}
                                    <NavLinkItem to='/mentor-verification'>Verification</NavLinkItem>
                                </>
                            ) : (
                                <>
                                    {isAuthenticated ? (
                                        DefaultNavLinksData.map((linkData, i) => (
                                            <NavLinkItem key={i} variant={theme} to={linkData.to}>{linkData.label}</NavLinkItem>
                                        ))
                                    ) : (
                                        LandingPageNavLinksData.map((linkData, i) => (
                                            linkData.to ? (
                                                <NavLinkItem key={i} variant={theme} to={linkData.to}>{linkData.label}</NavLinkItem>
                                            ) : (
                                                <NavLinkItem className="no-underline" key={i} variant={theme} to={{ pathname: '/', state: (location.pathname.length > 1) ? { section: linkData.location } : '' }} onClick={() => onNavLinkClick(linkData.location)}>
                                                    {linkData.label}
                                                </NavLinkItem>
                                            )
                                        ))
                                    )}
                                </>
                            )}
                        </NavLinks>
                        {!isAuthenticated && (
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2rem' }}>
                                <Link to="/signin">
                                    <Button onClick={handleClick} style={{ padding: '1.4rem 2.4rem', background: 'none', color: '#4D4E66' }} size="small">
                                        Sign In
                                    </Button>
                                </Link>
                                <Link to="/register/type">
                                    <Button onClick={handleClick} style={{ padding: '1.4rem 2.4rem', }} size="small">
                                        Create Account
                                    </Button>
                                </Link>
                            </div>
                        )}
                    </>
                </MobileNavContainer>
            

        </>
    );
};

export { MobileNav };
