import { DropdownLink } from './dropdown-link'
import { StyledDropDownContainer } from './nav-dropdown.style'
import { UserProfileMenuData, MentorProfileMenuData } from '../../../services/menuData'

const NavDropdown = ({setNavDropdownActive, isMentor}) => {
  return (
    <StyledDropDownContainer>
      {isMentor?   
        (MentorProfileMenuData.map((menu, i) => (
          <DropdownLink 
            key={i}
            label={menu.label}
            icon={menu.icon}
            to={menu.to}
            setNavDropdownActive={setNavDropdownActive}
            isLogOut={menu.isLogOut}
          />))) :
        (UserProfileMenuData.map((menu, i) => (
          <DropdownLink 
            key={i}
            label={menu.label}
            icon={menu.icon}
            to={menu.to}
            setNavDropdownActive={setNavDropdownActive}
            isLogOut={menu.isLogOut}
          />
        )))}
    </StyledDropDownContainer>
  )
}

export {NavDropdown}