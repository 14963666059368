const Star = ({ filled, onClick }) => {
    const starImage = filled ? <img src="/assets/images/StarFilled.png" className='star-image' alt="Gold Star" /> : <img src="/assets/images/Star.png" className='star-image' alt="Star" />;

    return (
        <span className="star" onClick={onClick} role="button">
            {starImage}
        </span>
    );
};

export { Star };