import {
    VIEW_SURVEY,
    VIEW_SURVEY_SUCCESS,
    VIEW_SURVEY_FAILED,

    SAVE_SURVEY,
    SAVE_SURVEY_SUCCESS,
    SAVE_SURVEY_FAILED,

    SET_NPS_POPUP_SEEN,
    SET_FIVESTAR_POPUP_SEEN,
    SET_SCOLARSHIP_RECOMMENDED_POPUP_SEEN,
    SET_COURSE_RECOMMENDED_POPUP_SEEN,
    REFRESH_POPUP_SEEN
  } from './survey.constants';
  
  const initialState = {
    surveys: [],
    loading: false,
    error: null,
    npsPopupSeen: false,
    fiveStarPopupSeen: false,
    popupScholarshipRecommendedSeen: false,
    popupCourseRecommendedSeen: false,
  };

  export const popUpSurveyReducer = (state = initialState, action) => { 
    switch (action.type) {
      case SET_NPS_POPUP_SEEN:
        return {
          ...state,
          npsPopupSeen: true,
        };
      case SET_FIVESTAR_POPUP_SEEN:
        return {
          ...state,
          fiveStarPopupSeen: true,
        };
      case SET_SCOLARSHIP_RECOMMENDED_POPUP_SEEN:
        return {
          ...state,
          popupScholarshipRecommendedSeen: true,
        };
      case SET_COURSE_RECOMMENDED_POPUP_SEEN:
        return {
          ...state,
          popupCourseRecommendedSeen: true,
        };
      case REFRESH_POPUP_SEEN:
        return initialState;
      default:
        return state;
    }
  }
  
  export const viewSurveyReducer = (state = initialState, action) => {
    switch (action.type) {
      case VIEW_SURVEY:
        return {
          ...state,
          loading: true,
          error: null,
        };
      case VIEW_SURVEY_SUCCESS:
        return {
          ...state,
          loading: false,
          surveys: action.payload.surveys,
        };
      case VIEW_SURVEY_FAILED:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };

  export const saveSurveyReducer = (state = initialState, action) => {
    switch (action.type) {
      case SAVE_SURVEY:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case SAVE_SURVEY_SUCCESS:
        return {
          ...state,
          loading: false,
        };
  
      case SAVE_SURVEY_FAILED:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  
  