import {
    CREATE_REMINDER_REQUEST,
    CREATE_REMINDER_SUCCESS,
    CREATE_REMINDER_FAIL,
    REMINDER_LIST_REQUEST,
    REMINDER_LIST_SUCCESS,
    REMINDER_LIST_FAIL 
} from "./reminder.constants";


export const createReminderReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_REMINDER_REQUEST:
            return { ...state, loading: true, success: false };
        case CREATE_REMINDER_SUCCESS:
            return { loading: false, success: true };
        case CREATE_REMINDER_FAIL:
            return { loading: false, error: action.payload, success: false };
        default:
            return state;
    }
}

export const reminderListReducer = (state = { reminders: [] }, action) => {
    switch (action.type) {
        case REMINDER_LIST_REQUEST:
            return { ...state, loading: true, success: false, allRemindersRetrieved: false };
        case REMINDER_LIST_SUCCESS:
            return { loading: false, 
                success: true, 
                reminders: action.updateList? [...state.reminders, ...action.payload] : action.payload,
                count: action.count, 
                allRemindersRetrieved: action.payload?.length === 0, 
            };
        case REMINDER_LIST_FAIL:
            return { loading: false, error: action.payload, success: false };
        default:
            return state;
    }
}