import React from 'react';
import { Link } from 'react-router-dom'

import { Button } from '../button'

import {
    ErrorImageOverlay,
    ErrorImageContainer,
    ErrorImageText
} from './error-boundary.style';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasErrored: false
        };
    }

    static getDerivedStateFromError(error) {
        // process the error
        return { hasErrored: true };
    }

    componentDidCatch(error, info) {
        console.log(error);
    }

    render() {
        if (this.state.hasErrored) {
            return (
                <>
                    {/* <Navbar /> */}
                    <ErrorImageOverlay>
                        <ErrorImageContainer imageUrl='https://i.imgur.com/yW2W9SC.png' />
                        <ErrorImageText>Sorry this page is broken</ErrorImageText>
                        <Link to='/'>
                            <Button>Go back home</Button>
                        </Link>
                    </ErrorImageOverlay>

                </>
            );
        }

        return this.props.children;
    }
}

export { ErrorBoundary };