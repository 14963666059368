import { useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import CustomSwitch from "./components/custom-switch";
import PrivateRoute from './components/routing/PrivateRoute'
import useNotifier from "./services/useNotifier";
import { getToken } from "./redux/user/user.actions";
import { useDispatch } from "react-redux";
import RedirectRoute from "./components/routing/RedirectRoute";
import { Spinner } from "./components/spinner";
import { ErrorBoundary } from "./components/error-boundary";
import SearchByCountrySection from './components/landing-page/search-by-country-section/index';
import Content from './components/country-content/content';

const LandingPage = lazy(() => import("./pages/landing-page"))
const SignIn = lazy(() => import("./pages/sign-in"))
const SignUp = lazy(() => import("./pages/sign-up"))
const SearchResults = lazy(() => import("./pages/search-results"))
const RegistrationType = lazy(() => import("./pages/registration-type"))
const ForgotPassword = lazy(() => import("./pages/forgot-password"))
const ForgotPasswordVerification = lazy(() => import('./pages/forgot-password/verification'))
const MentorSignUp = lazy(() => import("./pages/sign-up/mentor"))
const MentorshipPage = lazy(() => import("./pages/mentorship"))
const ScholarshipPage = lazy(() => import("./pages/scholarship"))
const ScholarshipDetailsPage = lazy(() => import("./pages/scholarship-details"))
const Profile = lazy(() => import("./pages/profile"))
const ApplicationsPage = lazy(() => import("./pages/applications"))
const ApplicationCheckListPage = lazy(() => import("./pages/application-checklist"))
const BookMarksPage = lazy(() => import("./pages/bookmarks"))
const WalletPage = lazy(() => import("./pages/wallet"))
const UserMentors = lazy(() => import("./pages/mentors"))
const MentorPricingPage = lazy(() => import("./pages/mentors/pricing"))
const AuthenticatedPage = lazy(() => import("./pages/authenticated"))
const EmailVerifiedPage = lazy(() => import("./pages/email-verified"))
const FAQPage = lazy(() => import("./pages/faq"))
const Blog = lazy(() => import("./pages/blog"))
const BlogDetails = lazy(() => import("./pages/blog/blogDetails"))
const AboutPage = lazy(() => import("./pages/about"))
const PrivacyPage = lazy(() => import("./pages/privacy"))
const SettingsPage = lazy(() => import("./pages/settings"))
const CourseCategoryPage = lazy(() => import("./pages/courses/CourseCategoryPage"))
const CoursesDisplayPage = lazy(() =>
  import("./pages/courses/CoursesDisplayPage")
);
const CourseDetailsPage = lazy(() => import("./pages/course-detail"))
const CourseCategoryDetailsPage = lazy(() => import("./pages/course-category-detail"))
const RecommendedPage = lazy(() => import('./pages/recommended'))
const NotificationsPage = lazy(() => import('./pages/notifications'))
const MentorVerificationPage = lazy(() => import('./pages/mentor-verification'))

const App = () => {

  useNotifier()
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getToken())
  }, [dispatch])

  return (
    <ThemeProvider theme={theme}>
      <Router>

        <CustomSwitch>
          <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            {/* <RedirectRoute exact path='/home' component={LandingPage} /> */}
            <Route exact path='/' component={LandingPage} />
            <RedirectRoute exact path='/signin' component={SignIn} />
            <RedirectRoute exact path='/register/student' component={SignUp} />
            <RedirectRoute exact path='/search-results' component={SearchResults} />
            <RedirectRoute exact path='/register/mentor' component={MentorSignUp} />
            <RedirectRoute exact path='/register/type' component={RegistrationType} />
            <RedirectRoute exact path='/forgot-password' component={ForgotPassword} />
            <RedirectRoute exact path='/forgot-password/verification' component={ForgotPasswordVerification} />
            <Route exact path='/authenticated' component={AuthenticatedPage} />
            <Route exact path='/email-verified' component={EmailVerifiedPage} />
            <PrivateRoute exact path='/mentorship' component={MentorshipPage} />
            <Route exact path='/faq' component={FAQPage} />
            <Route exact path='/blog' component={Blog} />
            <Route exact path='/blog/:id' component={BlogDetails} />
            <Route exact path='/privacy' component={PrivacyPage} />
            <PrivateRoute exact path='/courses' component={CoursesDisplayPage} />
            <PrivateRoute exact path='/course/:courseId' component={CourseDetailsPage} />
            <PrivateRoute exact path='/course-categories' component={CourseCategoryPage} />
            <PrivateRoute exact path='/course-categories/:categoryId' component={CourseCategoryDetailsPage} />
            <Route exact path='/about-us' component={AboutPage} />
            <PrivateRoute exact path='/scholarship' component={ScholarshipPage} />
            <PrivateRoute exact path='/scholarship/:scholarshipId' component={ScholarshipDetailsPage} />
            <PrivateRoute exact path='/recommended' component={RecommendedPage} />
            <PrivateRoute exact path='/profile/myprofile' component={Profile} />
            <PrivateRoute exact path='/profile/applications' component={ApplicationsPage} />
            <Route exact path='/profile/applications/:id' component={ApplicationCheckListPage} />
            <PrivateRoute exact path='/profile/bookmarks' component={BookMarksPage} />
            <PrivateRoute exact path='/profile/wallet' component={WalletPage} />
            <PrivateRoute exact path='/profile/settings' component={SettingsPage} />
            <PrivateRoute exact path='/profile/mentors' component={UserMentors} />
            <PrivateRoute exact path='/profile/pricing' component={MentorPricingPage} />
            <PrivateRoute exact path='/notifications' component={NotificationsPage} />
            <PrivateRoute exact path='/mentor-verification' component={MentorVerificationPage} />
            <Route path="/landing-page/search-by-country-section" exact component={SearchByCountrySection} />
            <Route path="/country-content/:id" component={Content} />
          </Suspense>
          </ErrorBoundary>
        </CustomSwitch>
        
      </Router>
    </ThemeProvider>
  )
}

export default App;