const colors = {
    white: "#FFFFFF",
    white10: "rgba(255, 255, 255, 0.1)",
    white20: "rgba(255, 255, 255, 0.2)",
    white40: "rgba(255, 255, 255, 0.4)",
    white400: "rgba(255, 255, 255, 0.04)",
    white50: "rgba(255, 255, 255, 0.5)",
    white500: "rgba(255, 255, 255, 0.05)",
    white600: "rgba(255, 255, 255, 0.06)",
    white60: "rgba(255, 255, 255, 0.6)",
    white70: "rgba(255, 255, 255, 0.7)",
    white80: "rgba(255, 255, 255, 0.8)",
    white800: "rgba(255, 255, 255, 0.08)",
    white90: "rgba(255, 255, 255, 0.9)",
    japaaBlack: "#0C0E26",
    japaaBlack50: "rgba(12, 14, 38, 0.5)",
    japaaBlack70: "rgba(12, 14, 38, 0.7)",
    japaaBlack02: "rgba(12, 14, 38, 0.02)",
    japaaBlack03: "rgba(12, 14, 38, 0.03)",
    japaaBlack20: "rgba(12, 14, 38, 0.2)",
    grey01: "#E0E0E0;",
    japaaGreen: "#37FED9",
    japaaGreen80: "rgba(55, 254, 217, 0.8)",
    japaaGreen40: "rgba(55, 254, 217, 0.4)",
    chatBox: "#E5E6ED",
    redError: "#EB5757",
    black: "#000000",
    dark40: "rgba(12, 14, 38, 0.4)",
    ash: '#42425f',
    ash02: '#24263C',
    japaaWhite: "#F0FDFA",
    ash03: '#727380',
    bgGreen: '#D3F8F1',
    bgWhite: '#F5F5F5',
    borderWhite: '#EEEEEE',
    textGrey: '#4D4E66',
    dashboardFrame: '#FAFAFB',
    profileBorder: '#D9D2D2',
};

export { colors };