import {
  GET_COUNTRIES,
  GET_COUNTRIES_FAILED,
  GET_COUNTRIES_SUCCESS,
} from "./countries.constants";

const initialState = {
  countries: [],
  loading: false,
  errors: null,
};

export const countryReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_COUNTRIES:
      return { ...state, loading: true, errors: null };
    case GET_COUNTRIES_SUCCESS:
      return { ...state, countries: payload, loading: false, success: true };
    case GET_COUNTRIES_FAILED:
      return { ...state, errors: payload, loading: false };
    default:
      return state;
  }
};
