export const APPLICATION_LIST_REQUEST = 'APPLICATION_LIST_REQUEST'
export const APPLICATION_LIST_SUCCESS = 'APPLICATION_LIST_SUCCESS'
export const APPLICATION_LIST_FAIL = 'APPLICATION_LIST_FAIL'

export const APPLICATION_GET_REQUEST = 'APPLICATION_GET_REQUEST'
export const APPLICATION_GET_SUCCESS = 'APPLICATION_GET_SUCCESS'
export const APPLICATION_GET_FAIL = 'APPLICATION_GET_FAIL'

export const APPLICATION_CREATE_REQUEST = 'APPLICATION_CREATE_REQUEST'
export const APPLICATION_CREATE_SUCCESS = 'APPLICATION_CREATE_SUCCESS'
export const APPLICATION_CREATE_FAIL = 'APPLICATION_CREATE_FAIL'

export const APPLICATION_UPDATE_REQUEST = 'APPLICATION_UPDATE_REQUEST'
export const APPLICATION_UPDATE_SUCCESS = 'APPLICATION_UPDATE_SUCCESS'
export const APPLICATION_UPDATE_FAIL = 'APPLICATION_UPDATE_FAIL'