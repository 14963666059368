import { useEffect } from 'react';

import { createSocketConnection } from '../services/socket';

const listen = (callBackFn, channel, event) => {
  window.Echo.private(channel).listen(event, (payload) => {
    callBackFn(payload);
  });

  return function cleanUp() {
    window.Echo.leaveChannel(`private-${channel}`);
  };
}
 
export const useSocket = (type, callBack, trackId) => {
  useEffect(() => {
    createSocketConnection();
    switch (type) {
      case 'CHAT_LISTEN': {
        if(!trackId) return;
        return listen(callBack, `chat-id.${trackId}`, 'ChatMessage');
      }
      case 'CHAT_USER_LISTEN': {
        if(!trackId) return;
        return listen(callBack, `chat-user.${trackId}`, 'ChatUser');
      }
    }
  }, [trackId]);
};
