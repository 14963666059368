import React from "react";
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom";
import { Input } from "../../input/input.js";
import { Button } from "../../button/button.js"
import {
  FilterContainer,
  SingleFilterSearchParams,
} from "./form-search-filter.style";
import { BiSearch } from "react-icons/bi";
const FormSearchFilter = ({ location }) => {
  const { handleSubmit, register } = useForm({ mode: "onChange" });
  const history = useHistory();
  const onSubmit = (values) => {
    const { courseName } = values;
    history.push(
      `/courses?courseName=${courseName ?? ""}&location=${location ?? ""}`
    );
  };
  return (
    <>
      <FilterContainer>
        <form className="search-form" onSubmit={handleSubmit(onSubmit)}>
          <Input
            variant="primary-bold"
            id="courseName"
            type="text"
            placeholder="Search Course"
            fullWidth
            fullHeight
            fontFamily="Avenir"
            {...register("courseName")}
          />
          <Button
            type="submit"
            variant="dark"
            style={{
              padding: "0rem",
              borderRadius: "0 0.8rem 0.8rem 0",
              border: "none",
            }}
          >
            <button className="button-icon-search">  <BiSearch /></button>
          </Button>
        </form>

      </FilterContainer>
    </>
  );
};

export { FormSearchFilter };
