export const TWO_FA_SETUP_REQUEST = 'TWO_FA_SETUP_REQUEST'
export const TWO_FA_SETUP_SUCCESS = 'TWO_FA_SETUP_SUCCESS'
export const TWO_FA_SETUP_FAIL = 'TWO_FA_SETUP_FAIL'
export const TWO_FA_SETUP_DONE = 'TWO_FA_SETUP_DONE'

export const TWO_FA_ACTIVATE_REQUEST = 'TWO_FA_ACTIVATE_REQUEST'
export const TWO_FA_ACTIVATE_SUCCESS = 'TWO_FA_ACTIVATE_SUCCESS'
export const TWO_FA_ACTIVATE_FAIL = 'TWO_FA_ACTIVATE_FAIL'

export const TWO_FA_CONFIRM_REQUEST = 'TWO_FA_CONFIRM_REQUEST'
export const TWO_FA_CONFIRM_SUCCESS = 'TWO_FA_CONFIRM_SUCCESS'
export const TWO_FA_CONFIRM_FAIL = 'TWO_FA_CONFIRM_FAIL'


export const TWO_FA_AUTH_PROFILE_REQUEST = 'TWO_FA_AUTH_PROFILE_REQUEST'
export const TWO_FA_AUTH_PROFILE_SUCCESS = 'TWO_FA_AUTH_PROFILE_SUCCESS'
export const TWO_FA_AUTH_PROFILE_FAIL = 'TWO_FA_AUTH_PROFILE_FAIL'