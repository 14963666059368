import { APPLICATION_LIST_REQUEST,
    APPLICATION_LIST_SUCCESS,
    APPLICATION_LIST_FAIL,
    APPLICATION_GET_REQUEST,
    APPLICATION_GET_SUCCESS,
    APPLICATION_GET_FAIL,
    APPLICATION_CREATE_REQUEST,
    APPLICATION_CREATE_SUCCESS,
    APPLICATION_CREATE_FAIL,
    APPLICATION_UPDATE_REQUEST,
    APPLICATION_UPDATE_SUCCESS,
    APPLICATION_UPDATE_FAIL,
    
  } from "./application.constants";

export const applicationListReducer = (state = { applications: [] }, action) => {
    switch (action.type) {
        case APPLICATION_LIST_REQUEST:
            return { ...state, loading: true };
        case APPLICATION_LIST_SUCCESS:
            return { loading: false, success: true, applications: action.payload, count: action.count };
        case APPLICATION_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const applicationGetReducer = (state = {}, action) => {
    switch (action.type) {
        case APPLICATION_GET_REQUEST:
            return { ...state, loading: true };
        case APPLICATION_GET_SUCCESS:
            return { loading: false, success: true, application: action.payload };
        case APPLICATION_GET_FAIL:
            return { loading: false, error: action.payload }; 
        case APPLICATION_UPDATE_SUCCESS:
            return { ...state, application: action.payload };
        default:
            return state;
    }
};

export const applicationCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case APPLICATION_CREATE_REQUEST:
            return { ...state, loading: true };
        case APPLICATION_CREATE_SUCCESS:
            return { loading: false, success: true};
        case APPLICATION_CREATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const applicationUpdateReducer = (state = { mentors: [] }, action) => {
    switch (action.type) {
        case APPLICATION_UPDATE_REQUEST:
            return { ...state, loading: true, order: action.payload };
        case APPLICATION_UPDATE_SUCCESS:
            return { loading: false, success: true};
        case APPLICATION_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};