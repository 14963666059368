import { SurveyModal } from '../../../src/styles/survey-popup/index.js';
import React, { useState, useEffect } from 'react';
import { Star } from "./star.js";
import { saveSurvey } from '../../redux/survey/survey.actions.js';
import { useDispatch } from "react-redux";

const SurveyPopup = ({ closeModal, question, text, comment, numbers, surveyId }) => {
    const dispatch = useDispatch();
    const [rating, setRating] = useState(4);
    const [selectedNumber, setSelectedNumber] = useState(10);
    const [feedback, setFeedback] = useState('');
    const [courseTab, setCourseTab] = useState(false);

    const handleRatingChange = (value) => {
        if (value === rating) {
            setRating(rating);
        } else {
            setRating(value);
        }
    };

    const handleSelectedNumber = (value) => {
        setSelectedNumber(value);
    };

    const handleFeedbackChange = (e) => {
        setFeedback(e.target.value);
    };

    const handleSurveySubmit = (e) => {
        e.preventDefault();
        const surveyRating = courseTab ? selectedNumber : rating;
        dispatch(saveSurvey({ surveyId, rating: surveyRating, feedback }));
        closeModal();
    };

    useEffect(() => {
        if (numbers) {
            setCourseTab(true);
        }
    }, []);

    return (
        <SurveyModal courseTab={courseTab}>
            <div className='review-container'>
                <div className='icon'>
                    <img onClick={() => closeModal()} src="/assets/images/Vector.png" alt="close-icon" />
                </div>
                <div className='main-text'>{question}</div>
                {courseTab && (
                    <>
                        <div className='second-sub-container'>
                            <div className='second-star-container'>
                                {numbers.map((value) => (
                                    <div
                                        className={`second-star-item ${selectedNumber === value ? 'selected' : ''}`}
                                        key={value}
                                        onClick={() => handleSelectedNumber(value)}
                                    >
                                        {value}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='text'>
                            <p>Extremely Unlikely</p>
                            <p>Extremely Likely</p>
                        </div>
                    </>
                )}
                <div className={numbers ? "second-sub-container" : "sub-container"}>
                    <div className={numbers ? 'second-sub-text' : 'sub-text'}>{text}</div>
                    {!courseTab && (
                        <div className='star-container'>
                            {[1, 2, 3, 4, 5].map((value) => (
                                <Star
                                    key={value}
                                    filled={value <= rating}
                                    onClick={() => handleRatingChange(value)}
                                />
                            ))}
                        </div>
                    )}
                </div>
                {comment && (
                    <div className={numbers ? 'sub-textarea-container' : 'textarea-container'}>
                        <textarea
                            className='comment-box'
                            rows={3}
                            cols={40}
                            placeholder={comment}
                            value={feedback}
                            onChange={handleFeedbackChange}
                        />
                    </div>
                )}
                <div className='btn-container'>
                    <button className='btns' onClick={handleSurveySubmit}>Submit</button>
                </div>
            </div>
        </SurveyModal>
    );
};

export { SurveyPopup };
