import {LinkContainer, StyledLink, IconContainer, StyledUtilLink} from './dropdown-link.style'
import { logout } from "../../../../redux/user/user.actions";

import { useDispatch } from "react-redux";

const DropdownLink = ({ children, icon, label, link, color, setNavDropdownActive, isLogOut, ...rest }) => {
    const dispatch = useDispatch();


    const logoutHandler = () => {
        dispatch(logout())
    }
  return (
    <LinkContainer>
        {isLogOut ? (
            <StyledUtilLink onClick={logoutHandler}>
                {children || (
                    <>
                        <IconContainer color={color} className="icon-container">
                            {icon}
                        </IconContainer>
                        <div className="label"><span>{label}</span></div>
                    </>
                )}
            </StyledUtilLink>
        ) : (
            <StyledLink to={link} onClick={setNavDropdownActive} {...rest}>
                {children || (
                    <>
                        <IconContainer color={color} className="icon-container">
                            {icon}
                        </IconContainer>
                        <div className="label"><span>{label}</span></div>
                    </>
                )}
            </StyledLink>
        )}
        
    </LinkContainer>
  )
}

export { DropdownLink }