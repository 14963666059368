import { stripFilters } from "../../utils";
import {
  ADD_RECOMMENDED_SCHOLARSHIP_BOOKMARK_SUCCESS,
  ADD_SCHOLARSHIP_BOOKMARK_SUCCESS,
  CLEAR_SCHOLARSHIP_FILTERS,
  GET_RECOMMENDED_SCHOLARSHIPS,
  GET_RECOMMENDED_SCHOLARSHIPS_FAILED,
  GET_RECOMMENDED_SCHOLARSHIPS_SUCCESS,
  GET_SCHOLARSHIPS,
  GET_SCHOLARSHIPS_FAILED,
  GET_SCHOLARSHIPS_SUCCESS,
  REMOVE_RECOMMENDED_SCHOLARSHIP_BOOKMARK_SUCCESS,
  REMOVE_SCHOLARSHIP_BOOKMARK_SUCCESS,
  SET_SCHOLARSHIP_FILTERS,
  UPDATE_SCHOLARSHIP_FILTERS,
  GET_BOOKMARKED_SCHOLARSHIPS,
  GET_BOOKMARKED_SCHOLARSHIPS_SUCCESS,
  GET_BOOKMARKED_SCHOLARSHIPS_FAILED,

  FETCH_SCHOLARSHIP_REQUEST,
  FETCH_SCHOLARSHIP_SUCCESS,
  FETCH_SCHOLARSHIP_FAILED,
  
  UPDATE_RECOMMENDED_SCHOLARSHIP_CLICKS,
  UPDATE_RECOMMENDED_SCHOLARSHIP_CLICKS_SUCCESS,
  UPDATE_RECOMMENDED_SCHOLARSHIP_CLICKS_FAILED
} from "./scholarship.constants";

const initialState = {
  scholarships: [],
  recommended: [],
  totalCount: 0,
  filter_counts: {
    "degree_level.bachelors": 0,
    "degree_level.masters": 0,
    "degree_level.phd": 0,
    "funding.fully funded": 0,
    "funding.partially funded": 0,
  },
  errors: [],
  loading: false,
  success: null,
};

export const scholarshipListReducer = (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SCHOLARSHIPS:
      return { ...state, loading: true };
    case GET_SCHOLARSHIPS_SUCCESS:
      return {
        ...state,
        loading: false,
        scholarships: payload.scholarships,
        recommended: payload.recommended,
        totalCount: payload.totalCount,
        filter_counts: payload.filter_counts ?? {},
      };
    case GET_SCHOLARSHIPS_FAILED:
      return { ...state, loading: false, errors: action.payload };

    case ADD_SCHOLARSHIP_BOOKMARK_SUCCESS: {
      const schIndex = state.scholarships.findIndex(
        (sch) => sch.id === payload
      );
      const newScholarship = state.scholarships[schIndex];
      newScholarship.is_bookmarked = true;
      const updatedSch = [...state.scholarships];
      updatedSch.splice(schIndex, 1, newScholarship);

      return {
        ...state,
        scholarships: updatedSch,
      };
    }
    case REMOVE_SCHOLARSHIP_BOOKMARK_SUCCESS: {
      const schIndex = state.scholarships.findIndex(
        (sch) => sch.id === payload
      );
      const newScholarship = state.scholarships[schIndex];
      newScholarship.is_bookmarked = false;
      const updatedSch = [...state.scholarships];
      updatedSch.splice(schIndex, 1, newScholarship);

      return {
        ...state,
        scholarships: updatedSch,
      };
    }

    default:
      return state;
  }
};
const initialdScholarshipState = {
  loading: false,
  error: null,
};

export const recommendedScholarshipClicksReducer = (state = initialdScholarshipState, action) => {
  switch (action.type) {
    case UPDATE_RECOMMENDED_SCHOLARSHIP_CLICKS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case UPDATE_RECOMMENDED_SCHOLARSHIP_CLICKS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_RECOMMENDED_SCHOLARSHIP_CLICKS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const scholarshipInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SCHOLARSHIP_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case FETCH_SCHOLARSHIP_SUCCESS:
      return {
        ...state,
        scholarship: action.payload.scholarship,
        isLoading: false,
        error: null
      };
    case FETCH_SCHOLARSHIP_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const initialRecommendedScholarshipState = {
  scholarships: [],
  totalCount: 0,
  loading: false,
  error: null,
};

export const recommendedScholarshipReducer = (
  state = initialRecommendedScholarshipState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_RECOMMENDED_SCHOLARSHIPS:
      return {
        ...state,
        loading: true,
      };
    case GET_RECOMMENDED_SCHOLARSHIPS_SUCCESS:
      return {
        ...state,
        scholarships: payload.recommended,
        totalCount: payload.totalCount,
        loading: false,
      };
    case GET_RECOMMENDED_SCHOLARSHIPS_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ADD_RECOMMENDED_SCHOLARSHIP_BOOKMARK_SUCCESS: {
      const schIndex = state.scholarships.findIndex(
        (sch) => sch.id === payload
      );
      const newScholarship = state.scholarships[schIndex];
      newScholarship.is_bookmarked = true;
      const updatedSch = [...state.scholarships];
      updatedSch.splice(schIndex, 1, newScholarship);

      return {
        ...state,
        scholarships: updatedSch,
      };
    }
    case REMOVE_RECOMMENDED_SCHOLARSHIP_BOOKMARK_SUCCESS: {
      const schIndex = state.scholarships.findIndex(
        (sch) => sch.id === payload
      );
      const newScholarship = state.scholarships[schIndex];
      newScholarship.is_bookmarked = false;
      const updatedSch = [...state.scholarships];
      updatedSch.splice(schIndex, 1, newScholarship);

      return {
        ...state,
        scholarships: updatedSch,
      };
    }

    default:
      return state;
  }
};

const initialFilterState = {
  filters: {
    scholarship_name: "",
    destination: "",
    discipline: "",
    degree_level: [],
  },
  shouldFilter: false,
};

export const scholarshipFilterReducer = (
  state = initialFilterState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SCHOLARSHIP_FILTERS:
      return {
        ...state,
        filters: { ...stripFilters(state.filters), ...payload },
        shouldFilter: true,
      };

    case UPDATE_SCHOLARSHIP_FILTERS:
      return {
        ...state,
        filters: { ...stripFilters(state.filters), ...payload },
        shouldFilter: true,
      };

    case CLEAR_SCHOLARSHIP_FILTERS:
      return initialFilterState;

    default:
      return state;
  }
};


const initialBookmarkedScholarshipState = {
  scholarships: [],
  totalCount: 0,
  loading: false,
  error: null,
};

export const bookmarkedScholarshipReducer = (
  state = initialBookmarkedScholarshipState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_BOOKMARKED_SCHOLARSHIPS:
      return {
        ...state,
        loading: true,
      };
    case GET_BOOKMARKED_SCHOLARSHIPS_SUCCESS:
      return {
        ...state,
        scholarships: payload.scholarships,
        totalCount: payload.totalCount,
        loading: false,
      };
    case GET_BOOKMARKED_SCHOLARSHIPS_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
