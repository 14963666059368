export const CertificateType = {
    Transcript: {
        value: 1,
        label: 'Transcript'
    },
    PreviousWrittenAcademicWork: {
        value: 2,
        label: 'Previous Written Academic Work'
    },
    CurriculumVitae: {
        value: 3,
        label: 'Curriculum Vitae'
    },
    AnyOtherInformation: {
        value: 4,
        label: 'Any Other Information'
    }
};