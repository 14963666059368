import { Route, Redirect } from 'react-router-dom'

import { useSelector } from "react-redux";

const RedirectRoute = ({ component: Component, ...rest }) => {

    const userLogin = useSelector((state) => state.userLogin);
    const { isAuthenticated } = userLogin;
    const params = new URLSearchParams(window.location.search);
    const extraInfo = params.has("return_url")? '?return_url=' + params.get("return_url") : '';

    return (
        <Route
            {...rest}
            render={props => isAuthenticated ? (
                <Redirect to={'/Authenticated' + extraInfo} />
            ) : (
                <Component {...props} />
            )}
        />
    )
}

export default RedirectRoute