import Echo from "laravel-echo";
import {apiBaseURL, wsHost} from './config';
import apiClient from "./axios";

window.Pusher = require('pusher-js');

const getAuthString = async({socketId, channelName}) => {
    const config = {
        headers: {
            "content-Type": "application/json",
        },
    };

    const { data: { auth } } = await apiClient.post(apiBaseURL + `/api/broadcasting/auth`,
                            { 
                                socket_id: socketId,
                                channel_name: channelName
                            }, 
                            config);
    return auth;
}

export const createSocketConnection = (token) => {
  if (!window.Echo) {
    window.Echo = new Echo({
      channelAuthorization: {
            customHandler: async(request, callBackFn) =>
            {
                try {
                    let authString = await getAuthString(request);
                    console.log({auth: authString});
                    callBackFn(null, {auth: authString});
                } catch (error) {
                    callBackFn(error, {});
                }
            }
        },
      broadcaster: "pusher",
      key: '',
      wsHost: wsHost,
      wssPort: 80,
      disableStats: true,
      forceTLS: true,
      enabledTransports: ['ws', 'wss']
    });
  }
};
