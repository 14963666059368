import styled from "styled-components";
import { theme } from '../../theme';

export const StyledChatItem = styled.div`
    background-color: #F8F8F8;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
    border-radius: 0.8rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    margin-top: 2rem;
    cursor: pointer;

    .image-container {
        max-width: 6rem;
        max-height: 6rem;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }
    .chat-info {
        font-family: 'Avenir';
        margin-left: 2rem;
        .chat-name {            
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 2.6rem;
            text-transform: capitalize;
            color: #333333;
        }
        .recent-chat {
            font-weight: 400;
            line-height: 2rem;
            font-size: 1.4rem;
            color: #333333;
            width: 30rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .date-and-chat-count {
        font-family: 'Avenir';
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: auto;

        .chat-date {
            font-size: 1.2rem;
            color: #828282;
        }

        .chat-count-bubble {
            width: 20px;
            height: 20px;
            background-color: ${theme.colors?.japaaGreen};
            color: ${theme.colors?.japaaBlack};
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-top: 8px;
        }
    }
`;