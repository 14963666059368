import { motion } from "framer-motion";
import styled from "styled-components";

export const FilterWrapper = styled.div`
  background: #FFFFFF
  padding: 6rem;
  @media (max-width: 480px) {
    padding: 6rem 20px 6rem 20px;
  }
`;

export const FilterContainer = styled.div`
  .search-form {
        display: flex;
        justify-content: center;
        background: #FFFFFF
        @media (max-width: 480px) {
            align-items: center;
            display: flex;
            flex-direction: inherit;   
        }
    }
    .button-icon-search {
        cursor: pointer;
        background: #0C0E26;
        color: #F5FAF9;
        border: none;
        border-radius: 0px 12px 12px 0px;
        padding: 0px 16px;
        width: 72px;
        height: 56px;
        outline: none;
        @media (max-width: 480px) {
            display: block;
        }
    }
    .button-text-search {
        display: none;

        @media (max-width: 480px) {
            display: none;
            background: #37FED9;
            border-radius: 4px;
            width: 336px;
            border: none;
            height: 56px;
            margin-top: 2rem;
            font-weight: 500;
            font-size: 16px;          
            color: #0C0E26;
        }
    }
   
    input:focus {
        outline: none;
    }
    input {
    background: #FFFFFF;
    border-radius: 3px;
    overflow: hidden;
    max-width: 124rem;
    padding: 0px 16px;
    width: 880px;
    height: 56px;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: #CDD8D6;

    @media (max-width: 480px) {
        border-radius: 4px;
        width: 336px;
        height: 56px;
    }

    ::placeholder {
        color:  #727380;
    }
  }
`;
