import axios from 'axios'
import { USER_GET_TOKEN_SUCCESS, 
         USER_GET_TOKEN_FAIL, 
         USER_LOGIN_REQUEST, 
         USER_LOGIN_SUCCESS, 
         USER_LOGIN_FAIL, 
         USER_LOGIN_REQUIRES_CONFIRMATION,
         USER_LOGIN_SOCIAL_LOGIN,
         MENTOR_REGISTER_REQUEST, 
         MENTOR_REGISTER_SUCCESS, 
         MENTOR_REGISTER_FAIL, 
         MENTOR_REGISTER_VALIDATED,
         STUDENT_REGISTER_REQUEST, 
         STUDENT_REGISTER_SUCCESS, 
         STUDENT_REGISTER_FAIL, 
         USER_PROFILE_REQUEST, 
         USER_PROFILE_FAIL, 
         USER_PROFILE_SUCCESS, 
         USER_PROFILE_UPDATE_FAIL, 
         USER_PROFILE_UPDATE_SUCCESS, 
         USER_PROFILE_UPDATE_REQUEST, 
         USER_LOGOUT_REQUEST, 
         USER_LOGOUT_SUCCESS, 
         USER_LOGOUT_FAIL, 
         USER_LOGOUT_COMPLETE,
         USER_FORGOT_PASSWORD_REQUEST, 
         USER_FORGOT_PASSWORD_FAIL, 
         USER_FORGOT_PASSWORD_SUCCESS, 
         USER_RESET_PASSWORD_FAIL, 
         USER_RESET_PASSWORD_SUCCESS, 
         USER_RESET_PASSWORD_REQUEST, 
         USER_IS_UNAUTHENTICATED, 
         USER_PROFILE_EMAIL_NOT_VERIFIED, 
         USER_IS_AUTHENTICATED,
         USER_CHANGE_PASSWORD_FAIL, 
         USER_CHANGE_PASSWORD_SUCCESS, 
         USER_CHANGE_PASSWORD_REQUEST, 
         USER_UPDATE_PROFILEPIC_FAIL,
         USER_UPDATE_PROFILEPIC_SUCCESS,
         USER_UPDATE_PROFILEPIC_REQUEST,
         USER_PROFILE_PICTURE_UPDATE,
         USER_SUBSCRIBE_REQUEST,
         USER_SUBSCRIBE_SUCCESS,
         USER_SUBSCRIBE_FAIL,
         USER_CHECK_PHONENUMBER_REQUEST,
         USER_CHECK_PHONENUMBER_SUCCESS,
         USER_CHECK_PHONENUMBER_FAIL,
         USER_CHECK_PHONENUMBER_RESET,
         USER_CHANGE_PHONENUMBER_REQUEST,
         USER_CHANGE_PHONENUMBER_SUCCESS,
         USER_CHANGE_PHONENUMBER_FAIL,
         USER_CHANGE_PHONENUMBER_RESET,
         USER_UPDATE_CERTIFICATE_FAIL,
         USER_UPDATE_CERTIFICATE_SUCCESS,
         USER_UPDATE_CERTIFICATE_REQUEST,
        } from './user.constants'
import { enqueueSnackbar } from '../alert/alert.actions'
import { apiBaseURL } from '../../services/config'
import apiClient from '../../services/axios';
import setAuthToken from './user.utils';
import { REFRESH_POPUP_SEEN } from '../survey/survey.constants';


export const getToken = () => async (dispatch) => {
    let res
    try {
        res = await apiClient.get(apiBaseURL + '/sanctum/csrf-cookie');
        dispatch({
            type: USER_GET_TOKEN_SUCCESS,
            payload: res.config.headers['X-XSRF-TOKEN']
        });

        const token = res.config.headers['X-XSRF-TOKEN']

        setAuthToken(token)

        console.log(res)
        console.log(token)

    } catch (error) {
        dispatch({
            type: USER_GET_TOKEN_FAIL,
            payload: error?.response && error?.response.data.message ? error?.response.data.message : error?.message
        })
    }
}

export const login = (email, password) => async (dispatch) => {
    try {
        dispatch({
            type: USER_LOGIN_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await apiClient.post(apiBaseURL + '/login', { email, password }, config);

        if (data.status == 'Forbidden') {
            dispatch({
                type: USER_LOGIN_REQUIRES_CONFIRMATION,
                payload: data
            });
        }
        else{
            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: data
            });
        }

    } catch (error) {
        console.log('login', error?.response?.data?.errors);
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: error?.response?.data?.errors ?? error?.message
        });
    }
}

export const mentorRegister = (updateData, validate_only = false) => async (dispatch) => {
    try {
        dispatch({
            type: MENTOR_REGISTER_REQUEST,
        });
        const config = {
            headers: {
                "content-Type": "application/json",
            },
        };

        const { data } = await apiClient.post(
            apiBaseURL + "/api/register-mentor",
            { ...updateData, validate_only },
            config
        );
        let resData = data;

        console.log('mentor registration', resData)

        dispatch({
            type: validate_only? MENTOR_REGISTER_VALIDATED : MENTOR_REGISTER_SUCCESS,
        });

        if (!validate_only) {
            dispatch(enqueueSnackbar({
                message: 'Registration Successful',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                },
            }));
        }

    } catch (error) {
        dispatch({
            type: MENTOR_REGISTER_FAIL,
            payload: error?.response?.data?.errors ?? error?.message,
        });
    }
};


export const studentRegister = (email, first_name, last_name, password, password_confirmation) => async (dispatch) => {
    try {
        dispatch({
            type: STUDENT_REGISTER_REQUEST,
        });

        const config = {
            headers: {
                "content-Type": "application/json",
            },
        };

        const { data } = await apiClient.post(
            apiBaseURL + "/api/register-student",
            { email, first_name, last_name, password, password_confirmation },
            config
        );
        let resData = data;

        console.log('student registration', resData)

        dispatch({
            type: STUDENT_REGISTER_SUCCESS,
        });

        dispatch(enqueueSnackbar({
            message: 'Registration Successful',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
            },
        }));

    } catch (error) {
        dispatch({
            type: STUDENT_REGISTER_FAIL,
            payload: error?.response?.data?.errors ?? error?.message
        });
    }
};

export const getProfile = () => async (dispatch) => {
    try {
        dispatch({
            type: USER_PROFILE_REQUEST,
        });

        const { data: { data } } = await apiClient.get(apiBaseURL + "/api/profile");
        const { isMentor } = data;

        dispatch({
            type: USER_PROFILE_SUCCESS,
            payload: data
        });

        dispatch({
            type: USER_IS_AUTHENTICATED,
            isMentor: isMentor
        });

    } catch (error) {
        dispatch({
            type: USER_PROFILE_FAIL,
            payload:
                error?.response && error?.response.data.message
                    ? error?.response.data.message
                    : error?.message,
        });
        
        if(error?.response?.status === 401){
            dispatch({
                type: USER_IS_UNAUTHENTICATED
            });
            return;
        }

        if(error?.response?.status === 403){
            dispatch({
                type: USER_LOGIN_REQUIRES_CONFIRMATION,
                payload: error?.response.data
            });
            return;
        }
    }
};

export const updateUserProfile = (user, onUpdate = null) => async (dispatch) => {
    try {
        dispatch({
            type: USER_PROFILE_UPDATE_REQUEST,
        });

        const config = {
            headers: {
                "content-Type": "application/json",
            },
        };

        const { data } = await apiClient.post(apiBaseURL + "/api/profile", user, config);
        let resData = data;
       
        dispatch({
            type: USER_PROFILE_UPDATE_SUCCESS,
            payload: resData
        });

        dispatch({
            type: USER_PROFILE_SUCCESS,
            payload: resData.data
        });

        dispatch(enqueueSnackbar({
            message: resData.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
            },
        }));

        if (onUpdate) 
        {
            onUpdate();
        }

    } catch (error) {
        console.log(error);
        dispatch({
            type: USER_PROFILE_UPDATE_FAIL,
            payload:
                error?.response && error?.response.data
                    ? error?.response.data
                    : error?.message,
        });
    }
};

export const updateMissingMentorProfile = (user) => async (dispatch) => {
    try {
        dispatch({
            type: USER_PROFILE_UPDATE_REQUEST,
        });

        const config = {
            headers: {
                "content-Type": "application/json",
            },
        };

        const { data } = await apiClient.post(apiBaseURL + "/api/profile/update-mentor-fields", user, config);
        let resData = data;

        //update profile status
        dispatch(getProfile());
       
        dispatch({
            type: USER_PROFILE_UPDATE_SUCCESS,
            payload: resData
        });
        
        dispatch(enqueueSnackbar({
            message: resData.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
            },
        }));

    } catch (error) {
        console.log(error);
        dispatch({
            type: USER_PROFILE_UPDATE_FAIL,
            payload:
                error?.response && error?.response.data
                    ? error?.response.data
                    : error?.message,
        });
    }
};

export const forgotPassword = (email) => async (dispatch) => {
    try {
        dispatch({
            type: USER_FORGOT_PASSWORD_REQUEST,
        });

        const config = {
            headers: {
                "content-Type": "application/json",
            },
        };

        const { data } = await apiClient.post(apiBaseURL + "/api/forgot-password", { email }, config);
        let resData = data;

        console.log(resData)

        dispatch({
            type: USER_FORGOT_PASSWORD_SUCCESS,
            payload: email
        });

        dispatch(enqueueSnackbar({
            message: resData.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
            },
        }));

    } catch (error) {
        console.log(error);
        dispatch({
            type: USER_FORGOT_PASSWORD_FAIL,
            payload:
                error?.response && error?.response.data.message
                    ? error?.response.data.message
                    : error?.message,
        });

        dispatch(enqueueSnackbar({
            message: error?.response && error?.response.data.message
                ? error?.response.data.message
                : error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }));
    }
};

export const resetPassword = (email, token, password, password_confirmation) => async (dispatch) => {
    try {
        dispatch({
            type: USER_RESET_PASSWORD_REQUEST,
        });

        const config = {
            headers: {
                "content-Type": "application/json",
            },
        };

        const { data } = await apiClient.post(apiBaseURL + "/api/reset-password", { email, token, password, password_confirmation }, config);
        let resData = data;

        console.log(resData);

        dispatch({
            type: USER_RESET_PASSWORD_SUCCESS,
            payload: resData
        });

        dispatch(enqueueSnackbar({
            message: resData.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
            },
        }));

    } catch (error) {
        console.log(error);
        dispatch({
            type: USER_RESET_PASSWORD_FAIL,
            payload:
                error?.response && error?.response.data
                    ? error?.response.data
                    : error?.message,
        });

        dispatch(enqueueSnackbar({
            message: error?.response && error?.response.data.message
                ? error?.response.data.message
                : error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }));
    }
};


export const logout = () => async (dispatch) => {
    localStorage.removeItem('japaToken')
    try {
        dispatch({
            type: USER_LOGOUT_REQUEST,
        });

        const config = {
            headers: {
                "content-Type": "application/json",
            },
        };

        const { data: { message } } = await apiClient.post(apiBaseURL + "/logout", {}, config);
        let resData = message;

        dispatch({
            type: USER_LOGOUT_SUCCESS,
        });

    } catch (error) {
        console.log(error);
        dispatch({
            type: USER_LOGOUT_FAIL,
            payload:
                error?.response && error?.response.data.message
                    ? error?.response.data.message
                    : error?.message,
        });
    }
};

export const completeLogout = () => async (dispatch) => {
    dispatch({
        type: USER_LOGOUT_COMPLETE
    });
    dispatch({
        type: REFRESH_POPUP_SEEN
    });
};

export const resendEmailVerification = () => async (dispatch) => {
    try {
        dispatch(enqueueSnackbar({
            message: 'Processing Email Verification Resend',
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
            },
        }));

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await apiClient.post(apiBaseURL + '/api/email/verification-notification', {}, config);

        if (data.errors) {
            dispatch(enqueueSnackbar({
                message: 'Email Verification Notification Resend Failed',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error',
                },
            }));
        } else {
            dispatch(enqueueSnackbar({
                message: 'Email Verification successfully Resent',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                },
            }));
        }

    } catch (error) {
        dispatch(enqueueSnackbar({
            message: error?.response && error?.response.data.message ? error?.response.data.message : error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }));
    }
}

export const changePassword = (passwordChangeRequest) => async (dispatch) => {
    try {
        dispatch({
            type: USER_CHANGE_PASSWORD_REQUEST,
        });

        const config = {
            headers: {
                "content-Type": "application/json",
            },
        };

        const { data } = await apiClient.post(apiBaseURL + "/api/profile/change-password", passwordChangeRequest, config);

        dispatch({
            type: USER_CHANGE_PASSWORD_SUCCESS
        });

        //update has password field for social media accounts
        dispatch(getProfile());

        dispatch(enqueueSnackbar({
            message: "Password changed successfully",
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
            },
        }));

    } catch (error) {
        dispatch({
            type: USER_CHANGE_PASSWORD_FAIL,
            payload:
            error?.response?.data?.errors
                ? error?.response?.data?.errors
                : error?.response?.data?.message ?? error?.message,
        });
    }
};

export const updateProfilePicture = (image) => async (dispatch) => {
    try {
        dispatch({
            type: USER_UPDATE_PROFILEPIC_REQUEST,
        });

        let formData = new FormData();
        formData.append('profile_picture', image, image.name);

        const config = {
            headers: {
                'accept': 'application/json',
                'Accept-Language': 'en-US,en;q=0.8',
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
            },
        };
        
        const { data } = await apiClient.post(apiBaseURL + "/api/upload-profile-picture", formData, config);

        dispatch({
            type: USER_UPDATE_PROFILEPIC_SUCCESS
        });
        
        dispatch({
            type: USER_PROFILE_PICTURE_UPDATE,
            payload: data
        });

        dispatch(enqueueSnackbar({
            message: "Profile picture updated successfully",
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
            },
        }));
    } catch (error) {
        dispatch({
            type: USER_UPDATE_PROFILEPIC_FAIL,
            payload:
                error?.response && error?.response.data?.errors?.profile_picture?.length > 0
                    ? error.response.data.errors.profile_picture[0]
                    : error?.message,
        });

        dispatch(enqueueSnackbar({
            message:  error?.response && error?.response.data?.errors?.profile_picture?.length > 0
                            ? error.response.data.errors.profile_picture[0]
                            : error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }));
    }
};

export const subscribeToNewsletter = ({email}, callbackFn) => async (dispatch) => {
    try {
        dispatch({
            type: USER_SUBSCRIBE_REQUEST
        });

        await apiClient.post(`https://ceuzcxxwplufam56o75qrwiyru0tomyh.lambda-url.eu-west-2.on.aws`,
                            { email },
                            {"content-Type": "application/json"});

        callbackFn();
        
        dispatch({
            type: USER_SUBSCRIBE_SUCCESS
        });
        
        dispatch(enqueueSnackbar({
                message: "Subscription successful",
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                },
            }));
    } catch (error) {
        console.log(error);    
        dispatch({
            type: USER_SUBSCRIBE_FAIL
        });
    }
};

export const checkPhoneNumber = (prefix, number) => async (dispatch) => {
    try {
        dispatch({
            type: USER_CHECK_PHONENUMBER_REQUEST,
        });

        const config = {
            headers: {
                "content-Type": "application/json",
            },
        };

        const { data } = await apiClient.post(apiBaseURL + "/api/profile/check-phone-number", {prefix, number}, config);

        dispatch({
            type: USER_CHECK_PHONENUMBER_SUCCESS
        });

        dispatch(enqueueSnackbar({
            message: "Authentication code sent successfully",
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
            },
        }));

    } catch (error) {
        dispatch({
            type: USER_CHECK_PHONENUMBER_FAIL,
            payload: error?.response?.data ?? error?.message,
        });
    }
};

export const resetCheckPhoneNumberStatus = () => (dispatch) => {
    dispatch({
        type: USER_CHECK_PHONENUMBER_RESET,
    });
    dispatch({
        type: USER_CHANGE_PHONENUMBER_RESET,
    });
};

export const changePhoneNumber = (prefix, number, code) => async (dispatch) => {
    try {
        dispatch({
            type: USER_CHANGE_PHONENUMBER_REQUEST,
        });

        const config = {
            headers: {
                "content-Type": "application/json",
            },
        };

        const { data } = await apiClient.post(apiBaseURL + "/api/profile/change-phone-number", {prefix, number, code}, config);
        let resData = data;
       
        dispatch({
            type: USER_CHANGE_PHONENUMBER_SUCCESS
        });

        dispatch({
            type: USER_PROFILE_SUCCESS,
            payload: resData.data
        });

        dispatch(enqueueSnackbar({
            message: resData.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
            },
        }));

    } catch (error) {
        dispatch({
            type: USER_CHANGE_PHONENUMBER_FAIL,
            payload: error?.response?.data ?? error?.message,
        });
    }
};

export const updateProfileCertificate = ({upload_file, description, certificate_type}) => async (dispatch) => {
    try {
        // image file should not exceed 10Mb
        if (upload_file?.size > 10485760) {
            dispatch(enqueueSnackbar({
            message:  "Uploaded file should not exceed 10Mb in size",
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
            }));
            return;
        }

        dispatch({
            type: USER_UPDATE_CERTIFICATE_REQUEST,
        });

        let formData = new FormData();
        formData.append('upload_file', upload_file, upload_file.name)
        formData.append('certificate_type', certificate_type)
        formData.append('description', description);

        const config = {
            headers: {
                'accept': 'application/json',
                'Accept-Language': 'en-US,en;q=0.8',
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
            },
        };
        
        const { data : { data } } = await apiClient.post(apiBaseURL + "/api/upload-certificate", formData, config);

        dispatch({
            type: USER_UPDATE_CERTIFICATE_SUCCESS
        });
        
        dispatch({
            type: USER_PROFILE_SUCCESS,
            payload: data
        });
    } catch (error) {
        console.log(error);
        dispatch({
            type: USER_UPDATE_CERTIFICATE_FAIL,
            payload:
                error?.response && error?.response.data
                    ? error?.response.data
                    : error?.message,
        });
    }
};

export const deleteProfileCertificate = (certificateDetails) => async (dispatch) => {
    try {
        const config = {
            headers: {
                'accept': 'application/json',
            },
        };
        
        const { data : { data } } = await apiClient.post(apiBaseURL + "/api/delete-certificate", certificateDetails, config);

        dispatch({
            type: USER_PROFILE_SUCCESS,
            payload: data
        });

        dispatch(enqueueSnackbar({
            message: "Certificate removed successfully",
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
            },
        }));
    } catch (error) {
        console.log(error);

        dispatch(enqueueSnackbar({
            message:  error?.response.data?.message ?? error?.message,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'error',
            },
        }));
    }
};

export const recordSocialLogin = (returnUrl) => async (dispatch) => {
    console.log(returnUrl);
    dispatch({
        type: USER_LOGIN_SOCIAL_LOGIN,
        payload: { returnUrl }
    })
};