import axios from 'axios'

const apiBaseURL = process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_BACKEND_URL || 'http://127.0.0.1:8000'
    : process.env.REACT_APP_BACKEND_URL

const apiClient = axios.create({
    // baseURL: apiBaseURL
    withCredentials: true,
})

export default apiClient