export const COURSE_LIST_REQUEST = "COURSE_LIST_REQUEST";
export const COURSE_LIST_SUCCESS = "COURSE_LIST_SUCCESS";
export const COURSE_LIST_FAIL = "COURSE_LIST_FAIL";

export const FETCH_COURSE_REQUEST = 'FETCH_COURSE_REQUEST';
export const FETCH_COURSE_SUCCESS = 'FETCH_COURSE_SUCCESS';
export const FETCH_COURSE_FAILED = 'FETCH_COURSE_FAILED';

export const GET_COURSE_CATEGORY = "GET_COURSE_CATEGORY";
export const GET_COURSE_CATEGORY_SUCCESS = "GET_COURSE_CATEGORY_SUCCESS";
export const GET_COURSE_CATEGORY_FAILED = "GET_COURSE_CATEGORY_FAILED";

export const SET_COURSE_FILTERS = "SET_COURSE_FILTERS";
export const UPDATE_COURSE_FILTERS = "UPDATE_COURSE_FILTERS";
export const FILTER_COURSES = "FILTER_COURSES";
export const FILTER_COURSES_SUCCESS = "FILTER_COURSES_SUCCESS";
export const FILTER_COURSES_FAILED = "FILTER_COURSES_FAILED";
export const CLEAR_COURSE_FILTERS = "CLEAR_COURSE_FILTERS";

export const ADD_BOOKMARK = "ADD_BOOKMARK";
export const REMOVE_BOOKMARK = "REMOVE_BOOKMARK";
export const ADD_BOOKMARK_SUCCESS = "ADD_BOOKMARK_SUCCESS";
export const ADD_BOOKMARK_FAILED = "ADD_BOOKMARK_FAILED";
export const REMOVE_BOOKMARK_SUCCESS = "REMOVE_BOOKMARK_SUCCESS";
export const REMOVE_BOOKMARK_FAILED = "REMOVE_BOOKMARK_FAILED";

export const GET_RECOMMENDED_COURSES = "GET_RECOMMENDED_COURSES";
export const GET_RECOMMENDED_COURSES_SUCCESS =
  "GET_RECOMMENDED_COURSES_SUCCESS";
export const GET_RECOMMENDED_COURSES_FAILED = "GET_RECOMMENDED_COURSES_FAILED";

export const ADD_RECOMMENDED_BOOKMARK = "ADD_RECOMMENDED_BOOKMARK";
export const ADD_RECOMMENDED_BOOKMARK_SUCCESS =
  "ADD_RECOMMENDED_BOOKMARK_SUCCESS";
export const ADD_RECOMMENDED_BOOKMARK_FAILED =
  "ADD_RECOMMENDED_BOOKMARK_FAILED";
export const REMOVE_RECOMMENDED_BOOKMARK = "REMOVE_RECOMMENDED_BOOKMARK";
export const REMOVE_RECOMMENDED_BOOKMARK_SUCCESS =
  "REMOVE_RECOMMENDED_BOOKMARK_SUCCESS";
export const REMOVE_RECOMMENDED_BOOKMARK_FAILED =
  "REMOVE_RECOMMENDED_BOOKMARK_FAILED";

export const ADD_COURSE_DETAIL_BOOKMARK = "ADD_COURSE_DETAIL_BOOKMARK";
export const ADD_COURSE_DETAIL_BOOKMARK_SUCCESS =
  "ADD_COURSE_DETAIL_BOOKMARK_SUCCESS";
export const ADD_COURSE_DETAIL_BOOKMARK_FAILED =
  "ADD_COURSE_DETAIL_BOOKMARK_FAILED";
export const REMOVE_COURSE_DETAIL_BOOKMARK = "REMOVE_COURSE_DETAIL_BOOKMARK";
export const REMOVE_COURSE_DETAIL_BOOKMARK_SUCCESS =
  "REMOVE_COURSE_DETAIL_BOOKMARK_SUCCESS";
export const REMOVE_COURSE_DETAIL_BOOKMARK_FAILED =
  "REMOVE_COURSE_DETAIL_BOOKMARK_FAILED";

export const GET_BOOKMARKED_COURSES = "GET_BOOKMARKED_COURSES";
export const GET_BOOKMARKED_COURSES_SUCCESS =
    "GET_BOOKMARKED_COURSES_SUCCESS";
export const GET_BOOKMARKED_COURSES_FAILED = "GET_BOOKMARKED_COURSES_FAILED";

export const UPDATE_RECOMMENDED_COURSE_CLICKS = "UPDATE_RECOMMENDED_COURSE_CLICKS";
export const UPDATE_RECOMMENDED_COURSE_CLICKS_SUCCESS = "UPDATE_RECOMMENDED_COURSE_CLICKS_SUCCESS";
export const UPDATE_RECOMMENDED_COURSE_CLICKS_FAILED = "UPDATE_RECOMMENDED_COURSE_CLICKS_FAILED";