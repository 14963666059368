export const GET_SCHOLARSHIPS = "GET_SCHOLARSHIPS";
export const GET_SCHOLARSHIPS_SUCCESS = "GET_SCHOLARSHIPS_SUCCESS";
export const GET_SCHOLARSHIPS_FAILED = "GET_SCHOLARSHIPS_FAILED";

export const FETCH_SCHOLARSHIP_REQUEST = 'FETCH_SCHOLARSHIP_REQUEST';
export const FETCH_SCHOLARSHIP_SUCCESS = 'FETCH_SCHOLARSHIP_SUCCESS';
export const FETCH_SCHOLARSHIP_FAILED = 'FETCH_SCHOLARSHIP_FAILED';

export const GET_RECOMMENDED_SCHOLARSHIPS = "GET_RECOMMENDED_SCHOLARSHIPS";
export const GET_RECOMMENDED_SCHOLARSHIPS_SUCCESS =
  "GET_RECOMMENDED_SCHOLARSHIPS_SUCCESS";
export const GET_RECOMMENDED_SCHOLARSHIPS_FAILED =
  "GET_RECOMMENDED_SCHOLARSHIPS_FAILED";

export const ADD_SCHOLARSHIP_BOOKMARK = "ADD_SCHOLARSHIP_BOOKMARK";
export const ADD_SCHOLARSHIP_BOOKMARK_SUCCESS =
  "ADD_SCHOLARSHIP_BOOKMARK_SUCCESS";
export const ADD_SCHOLARSHIP_BOOKMARK_FAILED =
  "ADD_SCHOLARSHIP_BOOKMARK_FAILED";
export const REMOVE_SCHOLARSHIP_BOOKMARK = "REMOVE_SCHOLARSHIP_BOOKMARK";
export const REMOVE_SCHOLARSHIP_BOOKMARK_SUCCESS =
  "REMOVE_SCHOLARSHIP_BOOKMARK_SUCCESS";
export const REMOVE_SCHOLARSHIP_BOOKMARK_FAILED =
  "REMOVE_SCHOLARSHIP_BOOKMARK_FAILED";

export const ADD_RECOMMENDED_SCHOLARSHIP_BOOKMARK =
  "ADD_RECOMMENDED_SCHOLARSHIP_BOOKMARK";
export const ADD_RECOMMENDED_SCHOLARSHIP_BOOKMARK_SUCCESS =
  "ADD_RECOMMENDED_SCHOLARSHIP_BOOKMARK_SUCCESS";
export const ADD_RECOMMENDED_SCHOLARSHIP_BOOKMARK_FAILED =
  "ADD_RECOMMENDED_SCHOLARSHIP_BOOKMARK_FAILED";
export const REMOVE_RECOMMENDED_SCHOLARSHIP_BOOKMARK =
  "REMOVE_RECOMMENDED_SCHOLARSHIP_BOOKMARK";
export const REMOVE_RECOMMENDED_SCHOLARSHIP_BOOKMARK_SUCCESS =
  "REMOVE_RECOMMENDED_SCHOLARSHIP_BOOKMARK_SUCCESS";
export const REMOVE_RECOMMENDED_SCHOLARSHIP_BOOKMARK_FAILED =
  "REMOVE_RECOMMENDED_SCHOLARSHIP_BOOKMARK_FAILED";

export const SET_SCHOLARSHIP_FILTERS = "SET_SCHOLARSHIP_FILTERS";
export const UPDATE_SCHOLARSHIP_FILTERS = "UPDATE_SCHOLARSHIP_FILTERS";
export const CLEAR_SCHOLARSHIP_FILTERS = "CLEAR_SCHOLARSHIP_FILTERS";

export const GET_BOOKMARKED_SCHOLARSHIPS = "GET_BOOKMARKED_SCHOLARSHIPS";
export const GET_BOOKMARKED_SCHOLARSHIPS_SUCCESS =
  "GET_BOOKMARKED_SCHOLARSHIPS_SUCCESS";
export const GET_BOOKMARKED_SCHOLARSHIPS_FAILED =
  "GET_BOOKMARKED_SCHOLARSHIPS_FAILED";

export const UPDATE_RECOMMENDED_SCHOLARSHIP_CLICKS = "UPDATE_RECOMMENDED_SCHOLARSHIP_CLICKS";
export const UPDATE_RECOMMENDED_SCHOLARSHIP_CLICKS_SUCCESS = "UPDATE_RECOMMENDED_SCHOLARSHIP_CLICKS_SUCCESS";
export const UPDATE_RECOMMENDED_SCHOLARSHIP_CLICKS_FAILED = "UPDATE_RECOMMENDED_SCHOLARSHIP_CLICKS_FAILED";
