import { Link } from "react-router-dom";
import { BrandImageStyledContainer, BrandImageStyledComponent } from "./brand-image.style";

export const BrandImage = ({theme}) => {
   let imageSrc = '';
   switch(theme) {
      case "dark":
         imageSrc = "/assets/images/japa-brand-logo.png";
         break;

      case "light":
      default:
         imageSrc = "/assets/images/japa-brand-logo-light.svg";
         break;
   }
   return (
      <BrandImageStyledContainer>
         <Link to="/">
            <BrandImageStyledComponent src={imageSrc} alt="brand image" />
         </Link>
      </BrandImageStyledContainer>
   );
};
