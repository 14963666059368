import { CREATE_MEETING_REQUEST,
    CREATE_MEETING_SUCCESS,
    CREATE_MEETING_FAIL,
    MEETING_LIST_REQUEST,
    MEETING_LIST_SUCCESS,
    MEETING_LIST_FAIL
} from "./meeting.constants";

export const createMeetingReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_MEETING_REQUEST:
            return { ...state, loading: true, success:false };
        case CREATE_MEETING_SUCCESS:
            return { loading: false, success: true };
        case CREATE_MEETING_FAIL:
            return { loading: false, error: action.payload, success:false };
        default:
            return state;
    }
}

export const meetingListReducer = (state = { meetings: [] }, action) => {
    switch (action.type) {
        case MEETING_LIST_REQUEST:
            return { ...state, loading: true, success:false };
        case MEETING_LIST_SUCCESS:
            return { loading: false, success: true, meetings: action.payload, count: action.count };
        case MEETING_LIST_FAIL:
            return { loading: false, error: action.payload, success:false };
        default:
            return state;
    }
}