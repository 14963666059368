export const CHAT_USERS_REQUEST = 'CHAT_USERS_REQUEST'
export const CHAT_USERS_SUCCESS = 'CHAT_USERS_SUCCESS'
export const CHAT_USERS_FAIL = 'CHAT_USERS_FAIL'
export const CHAT_USER_UPDATE = 'CHAT_USER_UPDATE'

export const CHAT_MESSAGES_REQUEST = 'CHAT_MESSAGES_REQUEST'
export const CHAT_MESSAGES_SUCCESS = 'CHAT_MESSAGES_SUCCESS'
export const CHAT_MESSAGES_FAIL = 'CHAT_MESSAGES_FAIL'
export const CHAT_MESSAGES_ADD = 'CHAT_MESSAGES_ADD'

export const SEND_CHAT_MESSAGE_REQUEST = 'SEND_CHAT_MESSAGE_REQUEST'
export const SEND_CHAT_MESSAGE_SUCCESS = 'SEND_CHAT_MESSAGE_SUCCESS'
export const SEND_CHAT_MESSAGE_FAIL = 'SEND_CHAT_MESSAGE_FAIL'

export const MENTOR_PING_REQUEST = 'MENTOR_PING_REQUEST'
export const MENTOR_PING_SUCCESS = 'MENTOR_PING_SUCCESS'
export const MENTOR_PING_FAIL = 'MENTOR_PING_FAIL'