export const VIEW_SURVEY = "VIEW_SURVEY";
export const VIEW_SURVEY_SUCCESS = "VIEW_SURVEY_SUCCESS";
export const VIEW_SURVEY_FAILED = "VIEW_SURVEY_FAILED"; 

export const SAVE_SURVEY = "SAVE_SURVEY";
export const SAVE_SURVEY_SUCCESS = "SAVE_SURVEY_SUCCESS";
export const SAVE_SURVEY_FAILED = "SAVE_SURVEY_FAILED"; 

export const SET_NPS_POPUP_SEEN = "SET_NPS_POPUP_SEEN";
export const SET_FIVESTAR_POPUP_SEEN = "SET_FIVESTAR_POPUP_SEEN";
export const SET_COURSE_RECOMMENDED_POPUP_SEEN = "SET_COURSE_RECOMMENDED_POPUP_SEEN";
export const SET_SCOLARSHIP_RECOMMENDED_POPUP_SEEN = "SET_SCOLARSHIP_RECOMMENDED_POPUP_SEEN";
export const REFRESH_POPUP_SEEN = 'REFRESH_POPUP_SEEN';