import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { removeSnackbar } from '../redux/alert/alert.actions';
import { ChatNotification } from '../components/chat-notification';

let displayed = [];

const useNotifier = () => {

    const dispatch = useDispatch();

    const alert = useSelector(state => state.alert);
    const { notifications } = alert;

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = (id) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id) => {
        displayed = [...displayed.filter(key => id !== key)];
    };

    React.useEffect(() => {
        notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }

            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) return;

            // display snackbar using notistack
            enqueueSnackbar(message, {
                key,
                ...options,
                autoHideDuration: options.type == 'chat'? 5000 : 3000,
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose(event, reason, myKey);
                    }
                },
                onExited: (event, myKey) => {
                    // remove this snackbar from redux store
                    dispatch(removeSnackbar(myKey));
                    removeDisplayed(myKey);
                },
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                },
                content: (key) => {

                    if (options.type != 'chat') return;             

                    const chatUser = options.data.mentee ?? options.data.mentor;
                    if (!chatUser) return; 
                    chatUser.unreadMessageCount = options.data.unread_message_count;
                    chatUser.lastUpdate = options.data.chat_updated_at;
                    chatUser.lastSentMessage = options.data.last_sent_message;
                    
                    //stopgarp: don't display if notification does not arrive in 10 seconds
                    if (new Date() - new Date(chatUser.lastUpdate) > 10000) return;
                    
                    return (
                        <ChatNotification key={key} chatUser={chatUser} onClick={options.onClick}></ChatNotification>
                    );
                }
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
};

export default useNotifier;
