import {
  COURSE_CATEGORY_FAILED,
  COURSE_CATEGORY_REQUEST,
  COURSE_CATEGORY_SUCCESS,
} from "./courseCategories.constants";

const initialState = {
  categories: [],
  loading: false,
  error: null,
};

const courseCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case COURSE_CATEGORY_REQUEST:
      return { ...state, loading: true };
    case COURSE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
        error: null,
      };
    case COURSE_CATEGORY_FAILED:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default courseCategoriesReducer;
