import styled from "styled-components";

export const CountryCourseContainer = styled.div`
  background-image: url(../../assets/images/uk-item.png);
  background-size: cover;
  margin: 0;
  padding: 0;
  max-width: none;
  margin-top: 8rem;
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

export const MobileCountryCourseContainer = styled.div`
  display: none;
  @media screen and (max-width: 480px) {
    display: block;
    img {
      width: 100%;
      margin-top: 8rem;
    }
  }
`;

export const CountryCourseSection = styled.div`
  background: rgba(12, 14, 38, 0.7);
  padding: 8rem;

  @media screen and (max-width: 480px) {
    display: block;
  }
`;

export const CountryCourseFormSection = styled.div`
  width: 608px;
  text-align: center;
  background: #FFFFFFB2;
  padding: 3rem;
  margin-top: 35px;
  margin-left:7%;

  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 0;
  }
`;

export const TextHeading = styled.div`
  text-align: justify;
  font-weight: 500;
  font-size: 17px;
  padding-bottom: 32px;
  .extra-text {
    font-family: "Avenir";
    font-weight: 500;
    font-size: 16px;
    margin: 2rem auto 0 auto;
    color: ${({ theme }) => theme.colors.textGrey};
    max-width: 92.8rem;
    .bold-text {
      color: ${({ theme }) => theme.colors.japaaBlack};
      font-weight: bold;
    }
  }
  @media screen and (max-width: 480px) {
    border-radius: 1.2rem;
    text-align: left;
  }
`;

export const InfoHeadingForm = styled.div`
  color: ${({ theme }) => theme.colors.japaaBlack};
  text-align: justify;
  padding-bottom: 10px;
  h2 {
    font-weight: 750;
    font-size: 32px;
  }
  @media screen and (max-width: 480px) {
    h2 {
      font-weight: 700;
      font-size: 3.2rem;
    }
    text-align: left;
  }
`;

export const FormSearchFilter = styled.div``;
export const CountryClusterContainer = styled.div`
  padding: 20px;
  display: flex;
  margin-top: 50px;
  position: relative;
  margin-left: 1rem;

  @media screen and (max-width: 480px) {
    display: none;
  }
`;

export const CountryClusterDetails = styled.div`
  width: 268px;
  height: auto;
`;

export const CountryClusterText = styled.div`
  width: 268px;
  height: max-content;
  padding: 2rem;
  border-radius: 4px;
  border-radius: 8px;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #eeeeee;
  margin-left: 25px;
  h1 {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #4d4e66;
    margin: 0px 0 2rem 0;
    padding: 1rem;
  }
  h1:hover,
  h1:active,
  h1.active {
    background-color: #37fed9;
    cursor: pointer;
  }
`;

export const CountryClusterExpand = styled.div`
  width: 780px;
  padding: 24px;
  border-radius: 8px;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #eeeeee;
  margin-left: 50px;
  @media screen and (max-width: 480px) {
    margin: 0;
    margin-top: 63px;
    width: auto;
    background: none;
  }
`;

export const CountryClusterExpandDetails = styled.div`
  width: 732px;
  @media screen and (max-width: 480px) {
    width: auto;
  }
`;

export const CountryClusterTextContainer = styled.div`
  width: 732px;
  margin: 0px 0 3rem 0;
  @media screen and (max-width: 480px) {
    width: auto;
  }
`;

export const HeaderText = styled.div`
  margin-bottom: 50px;
  h1 {
    color: #0c0e26;
    font-family: "Avenir";
    font-size: 20px;
    font-weight: 750;
    line-height: 24px;
    letter-spacing: 0rem;
    text-align: left;
    margin-bottom: 16px;
  }

  ul {
    margin-left: 3rem;
  }
  @media screen and (max-width: 480px) {
    h1 {
      justify-content: space-between;
      display: flex;
    }
    background: linear-gradient(0deg, #ffffff, #ffffff);
    padding: 1rem;
  }
`;

export const DescriptionText = styled.div`
  margin-bottom: 16px;
  h2 {
    color: #4d4e66;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0rem;
    text-align: left;
  }
`;

export const Explore = styled.div`
  h1 {
    color: #0c0e26;
    font-family: Avenir LT Std;
    font-size: 24px;
    font-weight: 750;
    line-height: 28.8px;
    letter-spacing: 0rem;
    text-align: left;
  }

  .view {
    border-radius: 5px;
  }
  @media screen and (max-width: 480px) {
    display: flex;
    justify-content: center;
  }
`;

export const Container = styled.div`
  margin: 0 auto;
  padding: 0 6rem;
  max-width: 124rem;

  @media screen and (max-width: 820px) {
    padding: 0 2rem;
  }
`;
