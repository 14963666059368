import { motion } from "framer-motion";
import styled, { css } from "styled-components";

export const Div = styled.div`
   ${({ fullWidth }) => fullWidth && "width: 100%;"}
   position: relative;
   display: inline-block;
   width: 100%;
`;

export const Label = styled.label`
font-family: 'Avenir';
    pointer-events: none;
    color: ${({ theme }) => theme.colors?.japaaBlack};
    white-space: nowrap;
    font-weight: 700;
    font-size: 16px;
    text-transform: capitalize;

   ${Div}:focus-within & {
      color: ${({ theme }) => theme.colors?.japaaGreen};
   }
`;

export const AdornmentWrapperDiv = styled.div`
   display: flex;
   align-items: center;
   white-space: nowrap;
   position: absolute;
   // padding-left: 10px;
   // margin-right: 10px;

   &.microphone-icon {
      left: 1rem;
      top: 1.2rem;
      bottom: 1.2rem;
   }

   &.start {
      left:1.4rem;
      svg {
         width: 15px;
         height: 15px;
      }
   }
   &.message-icon {
      right: 10px;
      top: 12px;
      bottom: 12px;
   }

   &.end {
      right: 0px;
    top: 0.9rem;
    bottom: 0.9rem;
   }
`;

export const InputWrapperDiv = styled.div`
   position: relative;
   display: flex;
   align-items: center;
   width: 100%;
   ${({ fullHeight }) => fullHeight && "height: 100%;"}
`;

function variantInputStyles({ variant }) {
   switch (variant) {
      case "secondary":
         return css`
            background-color: ${({ theme }) => theme && theme.colors?.white600};
            color: ${({ theme }) => theme && theme.colors?.white};
            border:  ${({ theme, error }) => error ? `1px solid ${theme.colors?.redError}` : 'none'};
         
            &::placeholder {
                 font-family: 'Avenir';
                 font-size: 1.4rem;
                 color: ${({ theme }) => theme && theme.colors?.white50};
            }
            
            &:-internal-autofill-selected {
               color: ${({ theme }) => theme && theme.colors?.white50};
               caret-color: ${({ theme }) => theme && theme.colors?.white50};
            }
             
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
               -webkit-text-fill-color: ${({ theme }) => theme && theme.colors?.white} !important;
            }
          `;
      case "dark":
         return css`
            background-color: rgba(12, 14, 38, 0.4);
            color: ${({ theme }) => theme.colors.japaaBlack};
            border:  ${({ theme, error }) => error ? `1px solid ${theme.colors?.redError}` : 'none'};
         
            &::placeholder {
                 font-family: 'Avenir';
                 font-size: 1.4rem;
                 color: ${({ theme }) => theme && theme.colors?.japaaBlack};
            }

            &:-internal-autofill-selected {
               color: ${({ theme }) => theme && theme.colors?.japaaBlack};
               caret-color: ${({ theme }) => theme && theme.colors?.japaaBlack};
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
               -webkit-text-fill-color: ${({ theme }) => theme && theme.colors?.japaaBlack} !important;
            }
          `;
      case "primary-bold":
         return css`
            border: 1px solid #BDBDBD;
            background-color: #fbfbfb;
            
            &::placeholder {
                 font-family: 'Avenir';
                 font-size: 1.6rem;
                 color: ${({ theme }) => theme && theme.colors?.ash03};
            }
   
            &:-internal-autofill-selected, &:-webkit-autofill:focus {
               color: ${({ theme }) => theme && theme.colors?.japaaBlack} !important;
               caret-color: ${({ theme }) => theme && theme.colors?.japaaBlack} !important;
            }
   
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
               -webkit-text-fill-color: ${({ theme }) => theme && theme.colors?.ash02} !important;
            }
          `;
         case "dark-frame":
            return css`
               background-color: ${({ theme }) => theme && theme.colors?.japaaBlack03};
               border: 1px solid ${({ theme }) => theme && theme.colors?.dashboardFrame};
               color: ${({ theme }) => theme && theme.colors?.white} !important;
               background-color: none;
               
               &::placeholder {
                    font-family: 'Avenir';
                    font-size: 1.6rem;
                    color: ${({ theme }) => theme && theme.colors?.white};
               }
      
               &:-internal-autofill-selected, &:-webkit-autofill:focus {
                  color: ${({ theme }) => theme && theme.colors?.white} !important;
                  caret-color: ${({ theme }) => theme && theme.colors?.white} !important;
               }
      
               &:-webkit-autofill,
               &:-webkit-autofill:hover,
               &:-webkit-autofill:focus,
               &:-webkit-autofill:active {
                  -webkit-text-fill-color: ${({ theme }) => theme && theme.colors?.white} !important;
               }
             `;
      default:
      case "primary":
         return css`
            border:  ${({ theme, value }) => value ? `1px solid #5D5E6E` : `1px solid #EEEEEE`};
            background-color:  ${({ theme, value }) => value ? `${theme.colors?.white}` : `#F9F9F9`};
         
            &::placeholder {
                 font-family: 'Avenir';
                 font-size: 1.6rem;
                 line-height: 2.2rem;
                 color: #BDBDBD;
            }

            &:-internal-autofill-selected, &:-webkit-autofill:focus {
               color: ${({ theme }) => theme && theme.colors?.japaaBlack} !important;
               caret-color: ${({ theme }) => theme && theme.colors?.japaaBlack} !important;
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
               -webkit-text-fill-color: ${({ theme }) => theme && theme.colors?.ash02} !important;
            }
          `;
   }
}

export const InputField = styled.input`
   width: 100%;
   border: none;
   outline: none;
   font-size: 1.5rem;
   appearance: none;
   padding: 0 ${({ startAdornment, endAdornment }) => (endAdornment ? "4.2rem 0 2.4rem" : startAdornment ? "2.4rem 0 4.2rem" : "2.4rem")};
   box-sizing: border-box;
   height: 5.5rem;
   border-radius: 4px;
   border: 1px solid #BDBDBD;
   background-color: #fbfbfb;
   ${({ fullHeight }) =>
      fullHeight &&
      css`
         height: 100%;
      `}
   

   &::-ms-clear {
      display: none;
   }

   &::-ms-expand {
      display: none;
   }

   &:-moz-ui-invalid {
      box-shadow: none;
   }

   &[type=time]::-webkit-calendar-picker-indicator {
      background-image: url(assets/images/webkit-clock-icon.svg);
   }
   
   &::placeholder {
        font-family: 'Avenir';
        font-size: 1.6rem;
        color: ${({ theme }) => theme && theme.colors?.japaaBlack};
   }

   &:-webkit-autofill,
   &:-webkit-autofill:hover,
   &:-webkit-autofill:focus,
   &:-webkit-autofill:active {
      transition: background-color 5000s;
   }

   &:disabled {
      /* background-color: hsl(0, 0%, 95%); */
      font-family: 'Avenir';
        font-size: 1.4rem;
        color: ${({ theme }) => theme && theme.colors?.white};
   }

   ${variantInputStyles}
`;

export const ErrorText = styled(motion.p)`
   font-family: 'Avenir';
   margin: 8px 0 0 0;
   color: ${({ theme }) => theme.colors?.redError};
   font-size: 1.4rem;
   font-weight: 400;
   text-transform: capitalize;

   ${({ easyFlow }) =>
      easyFlow &&
      css`
         margin: 0;
         position: absolute;
         left: 65px;
         bottom: -21px;
         font-size: 11.5px;
      `}
`;

export const PasswordControlButton = styled.button`
   background: none;
   border: none;
   width: 3rem;
   overflow: hidden;
   cursor:pointer;
`;

export const InputWrapper = styled.div`
margin-top: 8px;
   width: 100%;
   margin-bottom: 24px;

   &.grid-2-2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 20px;

      @media only screen and (max-width: 468px) {
         display: grid;
         grid-template-columns: repeat(1, 1fr);
         row-gap: 20px;
         width: 100%;
      }
   }

   &.wide-margin {
      margin-top: 2.4rem
   }

   .MuiIconButton-root {
      color: #BDBDBD !important;
    }
`;