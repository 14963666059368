import { createStore, applyMiddleware } from 'redux'
import { persistStore } from 'redux-persist'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './root-reducer'

const middleware = [thunk]
const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25});
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)))
const persistor = persistStore(store)
export { store, persistor }