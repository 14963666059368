import { Route, Redirect } from 'react-router-dom'

import { useSelector } from "react-redux";

const PrivateRoute = ({ component: Component, ...rest }) => {

    const userLogin = useSelector((state) => state.userLogin);
    const { isAuthenticated, logoutSuccess } = userLogin;
    return (
        <Route
            {...rest}
            render={props => !isAuthenticated ? (
                <Redirect to={'/signin' + (!logoutSuccess? '?return_url=' + window.location.href : '')} />
            ) : (
                <Component {...props} />
            )}
        />
    )
}

export default PrivateRoute