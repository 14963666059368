import styled from "styled-components";

export const StyledNewsletterContainer = styled.div`
    background-image: url(/assets/images/landing-page-background.png);
    background-size: cover;
`;

export const StyledNewsletterSection = styled.div`
    background-color: ${({ theme }) => theme.colors.japaaBlack50};
    color: ${({ theme }) => theme.colors.black};
    padding: 4.4rem 4rem 12rem 4rem;
    text-align: center;

    @media screen and (max-width: 920px) {
        flex-direction: column;
        text-align: center;
        padding: 2rem 0 8rem 0;
    }
`

export const StyledNewsletterTextSection = styled.div`
    text-align: center;

    @media screen and (max-width: 920px) {
        width: 100%;
    }
`

export const StyledNewsletterTitle = styled.div`
    margin-bottom: 1.6rem;

    h2 {
        font-family: 'Avenir';
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        line-height: 46px;
        color: ${({ theme }) => theme.colors.white};
    }
    
    @media screen and (max-width: 480px) {
        padding: 2rem;
        h2 {
            font-size: 24px;
            text-align: center;
        }
    }
`

export const StyledNewsletterDescription = styled.div`
    max-width: 50rem;
    margin: 0 auto 2.5rem;
    p {
        font-family: 'Avenir';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        color: ${({ theme }) => theme.colors.white90};
    }
    @media screen and (max-width: 480px) {
       
        p {
            margin-bottom: 5px;
            padding: 0 20px 0 20px;
            font-size: 16px;
            text-align: center;
        }
    }
`
export const StyledNewsletterFormSection = styled.form`
    display: flex;
    justify-content: center;
    min-height: 4.8rem;
    width: 54.3rem;
    max-width: 90vw;
    margin: 0 auto;
    padding-top: 1rem;

    button , input {
        height: 4.8rem;
    }

    .spinner {
        animation: spin infinite 3s linear;
      }
    
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
    @media screen and (max-width: 480px) {
            padding-top: 4rem;            
    }
`