import { MENTOR_LIST_FAIL, 
    MENTOR_LIST_REQUEST, 
    MENTOR_LIST_SUCCESS,
    MENTEE_LIST_FAIL, 
    MENTEE_LIST_REQUEST, 
    MENTEE_LIST_SUCCESS,
    MENTEE_SEARCH_FAIL, 
    MENTEE_SEARCH_REQUEST, 
    MENTEE_SEARCH_SUCCESS,
    GET_VERIFIED_MENTORS_REQUEST,
    GET_VERIFIED_MENTORS_SUCCESS,
    GET_VERIFIED_MENTORS_FAIL,
} from "./mentor.constants";

export const mentorListReducer = (state = { mentors: [] }, action) => {
    switch (action.type) {
        case MENTOR_LIST_REQUEST:
            return { ...state, loading: true };
        case MENTOR_LIST_SUCCESS:
            return { loading: false, success: true, mentors: action.payload, count: action.count };
        case MENTOR_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const menteeListReducer = (state = { mentees: [] }, action) => {
    switch (action.type) {
        case MENTEE_LIST_REQUEST:
            return { ...state, loading: true };
        case MENTEE_LIST_SUCCESS:
            return { loading: false, success: true, mentees: action.payload, count: action.count };
        case MENTEE_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};


export const menteeSearchReducer = (state = { mentees: [] }, action) => {
    switch (action.type) {
        case MENTEE_SEARCH_REQUEST:
            return { ...state, loading: true };
        case MENTEE_SEARCH_SUCCESS:
            return { loading: false, success: true, mentees: action.payload, count: action.count };
        case MENTEE_SEARCH_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const verifiedMentorsReducer = (state = { mentors: [] }, action) => {
    switch (action.type) {
        case GET_VERIFIED_MENTORS_REQUEST:
            return { ...state, loading: true };
        case GET_VERIFIED_MENTORS_SUCCESS:
            return { loading: false, success: true, mentors: action.payload, count: action.count };
        case GET_VERIFIED_MENTORS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};