import { TWO_FA_SETUP_FAIL, 
    TWO_FA_SETUP_REQUEST, 
    TWO_FA_SETUP_SUCCESS,
    TWO_FA_SETUP_DONE,
    TWO_FA_CONFIRM_REQUEST, 
    TWO_FA_CONFIRM_SUCCESS,
    TWO_FA_CONFIRM_FAIL,
    TWO_FA_ACTIVATE_REQUEST,
    TWO_FA_ACTIVATE_SUCCESS,
    TWO_FA_ACTIVATE_FAIL,
    TWO_FA_AUTH_PROFILE_REQUEST,
    TWO_FA_AUTH_PROFILE_SUCCESS,
    TWO_FA_AUTH_PROFILE_FAIL } from "./twoFA.constants";

export const twoFASetupReducer = (state = { courses: []}, action) => {
    switch (action.type) {
        case TWO_FA_SETUP_REQUEST:
            return { ...state, loading: true, type: action.payload, error: null };
        case TWO_FA_SETUP_SUCCESS:
            return { ...state, loading: false, success: true, status: action.payload };
        case TWO_FA_SETUP_FAIL:
            return { ...state, loading: false, success: false, error: action.payload };
        case TWO_FA_SETUP_DONE:
            return { };
        default:
            return state;
    }
};

export const twoFAActivateReducer = (state = { courses: []}, action) => {
    switch (action.type) {
        case TWO_FA_ACTIVATE_REQUEST:
            return { ...state, loading: true, type: action.payload, error: null };
        case TWO_FA_ACTIVATE_SUCCESS:
            return { loading: false, success: true, status: action.payload };
        case TWO_FA_ACTIVATE_FAIL:
            return { ...state, loading: false, success: false, error: action.payload };
        default:
            return state;
    }
};

export const twoFAConfirmationReducer = (state = { courses: []}, action) => {
    switch (action.type) {
        case TWO_FA_CONFIRM_REQUEST:
            return { ...state, loading: true, type: action.payload, error: null };
        case TWO_FA_CONFIRM_SUCCESS:
            return { loading: false, success: true, status: action.payload };
        case TWO_FA_CONFIRM_FAIL:
            return { ...state, loading: false, success: false, error: action.payload };
        default:
            return state;
    }
};


export const twoFAAuthProfileReducer = (state = { profile: {} }, action) => {
    switch (action.type) {
        case TWO_FA_AUTH_PROFILE_REQUEST:
            return { loading: true }
        case TWO_FA_AUTH_PROFILE_SUCCESS:
            return { loading: false, profile: action.payload }
        case TWO_FA_AUTH_PROFILE_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
