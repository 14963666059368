import { 
    SUBSCRIPTION_PRODUCT_LIST_REQUEST,
    SUBSCRIPTION_PRODUCT_LIST_SUCCESS,
    SUBSCRIPTION_PRODUCT_LIST_FAIL
} from "./subscriptions.constants";

export const subscriptionProductListReducer = (state = { subscriptionProducts: [] }, action) => {
    switch (action.type) {
        case SUBSCRIPTION_PRODUCT_LIST_REQUEST:
            return { ...state, loading: true, success: false };
        case SUBSCRIPTION_PRODUCT_LIST_SUCCESS:
            return { 
                loading: false, 
                success: true, 
                subscriptionProducts: action.payload
            };
        case SUBSCRIPTION_PRODUCT_LIST_FAIL:
            return { loading: false, error: action.payload, success: false };
        default:
            return state;
    }
}