export const USER_GET_TOKEN_SUCCESS = 'USER_GET_TOKEN_SUCCESS'
export const USER_GET_TOKEN_FAIL = 'USER_GET_TOKEN_FAIL'


export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGIN_FORM_RESET = 'USER_LOGIN_FORM_RESET'
export const USER_LOGIN_REQUIRES_CONFIRMATION = 'USER_LOGIN_REQUIRES_CONFIRMATION'
export const USER_LOGIN_SOCIAL_LOGIN = 'USER_LOGIN_SOCIAL_LOGIN'

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL'
export const USER_LOGOUT_COMPLETE = 'USER_LOGOUT_COMPLETE'

export const MENTOR_REGISTER_REQUEST = 'MENTOR_REGISTER_REQUEST'
export const MENTOR_REGISTER_SUCCESS = 'MENTOR_REGISTER_SUCCESS'
export const MENTOR_REGISTER_FAIL = 'MENTOR_REGISTER_FAIL'
export const MENTOR_REGISTER_VALIDATED = 'MENTOR_REGISTER_VALIDATED'

export const STUDENT_REGISTER_REQUEST = 'STUDENT_REGISTER_REQUEST'
export const STUDENT_REGISTER_SUCCESS = 'STUDENT_REGISTER_SUCCESS'
export const STUDENT_REGISTER_FAIL = 'STUDENT_REGISTER_FAIL'
export const STUDENT_REGISTER_RESET = 'STUDENT_REGISTER_RESET'

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST'
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS'
export const USER_PROFILE_FAIL = 'USER_PROFILE_FAIL'
export const USER_PROFILE_RESET = 'USER_PROFILE_RESET'
export const USER_PROFILE_PICTURE_UPDATE = 'USER_PROFILE_PICTURE_UPDATE'

export const USER_PROFILE_UPDATE_REQUEST = 'USER_PROFILE_UPDATE_REQUEST'
export const USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS'
export const USER_PROFILE_UPDATE_FAIL = 'USER_PROFILE_UPDATE_FAIL'
export const USER_PROFILE_UPDATE_RESET = 'USER_PROFILE_UPDATE_RESET'

export const USER_FORGOT_PASSWORD_REQUEST = 'USER_FORGOT_PASSWORD_REQUEST'
export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS'
export const USER_FORGOT_PASSWORD_FAIL = 'USER_FORGOT_PASSWORD_FAIL'
export const USER_FORGOT_PASSWORD_RESET = 'USER_FORGOT_PASSWORD_RESET'

export const USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST'
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS'
export const USER_RESET_PASSWORD_FAIL = 'USER_RESET_PASSWORD_FAIL'
export const USER_RESET_PASSWORD_RESET = 'USER_RESET_PASSWORD_RESET'

export const USER_IS_UNAUTHENTICATED = 'USER_IS_UNAUTHENTICATED'
export const USER_IS_AUTHENTICATED = 'USER_IS_AUTHENTICATED'
export const USER_PROFILE_EMAIL_NOT_VERIFIED = 'USER_PROFILE_EMAIL_NOT_VERIFIED'

export const USER_CHANGE_PASSWORD_FAIL = 'USER_CHANGE_PASSWORD_FAIL'
export const USER_CHANGE_PASSWORD_SUCCESS = 'USER_CHANGE_PASSWORD_SUCCESS'
export const USER_CHANGE_PASSWORD_REQUEST = 'USER_CHANGE_PASSWORD_REQUEST'

export const USER_UPDATE_PROFILEPIC_FAIL = 'USER_UPDATE_PROFILEPIC_FAIL'
export const USER_UPDATE_PROFILEPIC_SUCCESS = 'USER_UPDATE_PROFILEPIC_SUCCESS'
export const USER_UPDATE_PROFILEPIC_REQUEST = 'USER_UPDATE_PROFILEPIC_REQUEST'

export const USER_SUBSCRIBE_REQUEST = "USER_SUBSCRIBE_REQUEST"
export const USER_SUBSCRIBE_SUCCESS = "USER_SUBSCRIBE_SUCCESS"
export const USER_SUBSCRIBE_FAIL = "USER_SUBSCRIBE_FAIL"

export const USER_CHECK_PHONENUMBER_REQUEST = 'USER_CHECK_PHONENUMBER_REQUEST'
export const USER_CHECK_PHONENUMBER_SUCCESS = 'USER_CHECK_PHONENUMBER_SUCCESS'
export const USER_CHECK_PHONENUMBER_FAIL = 'USER_CHECK_PHONENUMBER_FAIL'
export const USER_CHECK_PHONENUMBER_RESET = 'USER_CHECK_PHONENUMBER_RESET'

export const USER_CHANGE_PHONENUMBER_REQUEST = 'USER_CHANGE_PHONENUMBER_REQUEST'
export const USER_CHANGE_PHONENUMBER_SUCCESS = 'USER_CHANGE_PHONENUMBER_SUCCESS'
export const USER_CHANGE_PHONENUMBER_FAIL = 'USER_CHANGE_PHONENUMBER_FAIL'
export const USER_CHANGE_PHONENUMBER_RESET = 'USER_CHANGE_PHONENUMBER_RESET'

export const USER_UPDATE_CERTIFICATE_FAIL = 'USER_UPDATE_CERTIFICATE_FAIL'
export const USER_UPDATE_CERTIFICATE_SUCCESS = 'USER_UPDATE_CERTIFICATE_SUCCESS'
export const USER_UPDATE_CERTIFICATE_REQUEST = 'USER_UPDATE_CERTIFICATE_REQUEST'