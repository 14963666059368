import { forwardRef, useCallback, useState, useEffect, useMemo } from "react";
import { FormControl, IconButton } from "@material-ui/core"
import { Visibility, VisibilityOff } from "@material-ui/icons"
// import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
// import { BsEyeSlash, BsEye } from "react-icons/bs";

import { Label, InputWrapperDiv, InputField, AdornmentWrapperDiv, ErrorText } from "./input.style";

const Input = forwardRef(
    (
        {
            id,
            name,
            type = "text",
            variant,
            label,
            required,
            value,
            error,
            fullWidth,
            fullHeight,
            className,
            onChange,
            startAdornment,
            endAdornment,
            endAdornmentClassname,
            startAdornmentClassname,
            isMobile,
            ...remainingProps
        }, ref
    ) => {
        const labelId = label ? `${id}-label` : undefined;
        const errorId = error ? `${id}-error-text` : undefined;

        const [showPassword, setShowPassword] = useState(false);

        const handleChange = useCallback(
            (event) => {
                onChange(event);
            },
            [onChange]
        );

        const PasswordControl = useMemo(
            () => (
                <IconButton style={{ color: 'rgba(255, 255, 255, 0.5)' }} onClick={() => {
                    console.log('clicked')
                    setShowPassword(!showPassword)
                }}>
                    {showPassword ? (
                        <Visibility fontSize="large" />
                    ) : (
                        <VisibilityOff fontSize="large" />
                    )}
                </IconButton>
            ),
            [setShowPassword, showPassword]
        );
        
        return (
            <FormControl fullWidth={fullWidth}>
                {label && (
                    <Label id={labelId} htmlFor={id}>
                        {`${label}${required ? "*" : ""}`}
                    </Label>
                )}
                <InputWrapperDiv fullHeight={fullHeight}>
                    {startAdornment && <AdornmentWrapperDiv className={startAdornmentClassname ? startAdornmentClassname : "start"}>{startAdornment}</AdornmentWrapperDiv>}
                    <InputField
                        id={id}
                        ref={ref}
                        name={name}
                        variant={variant}
                        startAdornment={startAdornment}
                        endAdornment={endAdornment}
                        type={showPassword ? "text" : type}
                        value={value}
                        required={required}
                        aria-invalid={!!error}
                        aria-describedby={errorId}
                        onChange={handleChange}
                        className={className}
                        error={error}
                        isMobile={isMobile}
                        fullHeight={fullHeight}
                        {...remainingProps}
                    />
                    {endAdornment && (
                        <AdornmentWrapperDiv className={endAdornmentClassname ? endAdornmentClassname : "end"}>
                            {type === "password" ? PasswordControl : endAdornment}
                        </AdornmentWrapperDiv>
                    )}
                </InputWrapperDiv>
                {error && (
                    <ErrorText
                        initial="pageInitial"
                        animate="pageAnimate"
                        exit="pageExit"
                        variants={{
                            pageInitial: {
                                opacity: 1,
                                x: 0,
                                y: -5,
                            },
                            pageAnimate: {
                                opacity: 1,
                                x: 0,
                                y: 0,
                            },
                            pageExit: {
                                opacity: 0,
                            },
                        }}
                        id={errorId}
                    >
                        {error}
                    </ErrorText>
                )}
            </FormControl>
        )
    })

export { Input }
