import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FooterStyles, FooterContainer, FooterLogoContainer, FooterUl } from './footer.style'

const Footer = ({onNavLinkClick}) => {
    const location = useLocation();
    const navLinkClick = (link) => {
        if (onNavLinkClick) onNavLinkClick(link);
    };

    return (
        <FooterStyles>
            <FooterContainer>
                <div>
                    <FooterUl>
                        <h3 className="footer-link-header">Company</h3>
                        <li><Link to="/about-us">About Us</Link></li>
                        <li><a href="https://cyberfleetng.zohorecruit.com/jobs/Careers" target="_blank">Careers</a></li>
                        <li><Link to="/privacy">Privacy</Link></li>
                        <li><Link to="/blog">Blog</Link></li>
                        {/* <li>Terms</li> */}
                    </FooterUl>
                </div>
                <div>
                    <FooterUl>
                        <h3 className="footer-link-header">Product</h3>
                        <li><Link to="/scholarship">Scholarship</Link></li>
                        <li><Link to="/mentorship">Mentorship</Link></li>
                        <li><Link to="/courses">Courses</Link></li>
                        <li className='hide'>Wallet</li>
                    </FooterUl>
                </div>
                <div>
                    <FooterUl>
                        <h3 className="footer-link-header">Resources</h3>
                        <li><Link to="/faq">FAQ</Link></li>
                        <li><Link to={{ pathname: '/', state: (location.pathname.length > 1) ? { section: "pricing" } : '' }} onClick={() => navLinkClick("pricing")}>Pricing</Link></li>
                        <li><a href="https://japaanow.substack.com/p/coming-soon?r=1itixq&s=w&utm_campaign=post&utm_medium=web" target="_blank">Newsletter</a></li>
                        <li className='hide'>none</li>
                    </FooterUl>
                </div>
                <div>
                    <FooterUl>
                        <h3 className="footer-link-header">Contact</h3>
                        <li>japaanow@cyberfleetng.com</li>
                       
                        <ul className="social-links">
                            <li><a target="_blank" href="https://twitter.com/japaanow?s=20&t=IdA2blhw1jORZ5sGyyp9bQ"><img className="social-link-image" src="/assets/images/twitter-icon.png" alt="twitter icon" /></a></li>
                            <li><a target="_blank" href="https://web.facebook.com/Japaanow"><img className="social-link-image" src="/assets/images/facebook-icon.png" alt="facebook icon" /></a></li>
                            <li><a target="_blank" href="https://www.instagram.com/japaanow/?igshid=YmMyMTA2M2Y%3Dhttps%3A%2F%2Finstagram.com%2Fjapaanow%3Figshid%3DYmMyMTA2M2Y%3D"><img className="social-link-image" src="/assets/images/instagram-icon.png" alt="instagram icon" /></a></li>
                            <li><a target="_blank" href="https://www.linkedin.com/showcase/japaanow/about/"><img className="social-link-image" src="/assets/images/linkedin-icon.png" alt="linkedin icon" /></a></li>
                        </ul>
                         <li className='hide'>+23455638221</li>
                        <li className='hide'>none</li>
                    </FooterUl>
                </div>
                {/* </div> */}

            </FooterContainer>
        </FooterStyles>
    )
}

export { Footer }
