import apiClient from "../../services/axios";
import { apiBaseURL } from "../../services/config";
import { generateUrlFilters } from "../../utils";
import {
    SAVE_SURVEY,
    SAVE_SURVEY_SUCCESS,
    SAVE_SURVEY_FAILED,

    VIEW_SURVEY,
    VIEW_SURVEY_SUCCESS,
    VIEW_SURVEY_FAILED,

    SET_NPS_POPUP_SEEN,
    SET_FIVESTAR_POPUP_SEEN,
    SET_SCOLARSHIP_RECOMMENDED_POPUP_SEEN,
    SET_COURSE_RECOMMENDED_POPUP_SEEN
} from "./survey.constants";

export const setPopUp = ({ surveyType }) => async (dispatch) => {
    if(surveyType && surveyType === "nps"){
        dispatch({ type: SET_NPS_POPUP_SEEN });
    }
    if(surveyType && surveyType === "fiveStar"){
        dispatch({ type: SET_FIVESTAR_POPUP_SEEN });
    }
    if(surveyType && surveyType === "scholarships"){
        dispatch({ type: SET_SCOLARSHIP_RECOMMENDED_POPUP_SEEN });
    }
    if(surveyType && surveyType === "courses"){
        dispatch({ type: SET_COURSE_RECOMMENDED_POPUP_SEEN });
    }
};

export const viewSurvey = () => async (dispatch) => {
    try {
        dispatch({ type: VIEW_SURVEY });
        const { data: surveys } = await apiClient.get(apiBaseURL + ("/api/survey"));
        dispatch({
            type: VIEW_SURVEY_SUCCESS,
            payload: { surveys: surveys },
        });

    } catch (error) {
        dispatch({
            type: VIEW_SURVEY_FAILED,
            payload: error?.response?.data?.message || error.message,
        });
    }
};

export const saveSurvey = ({ surveyId, rating, feedback }) => async (dispatch) => {
    try {
        dispatch({ type: SAVE_SURVEY });
        const response = await apiClient.post(apiBaseURL + `/api/survey/${surveyId}`, {
            rating,
            response: feedback,
        });


        dispatch({
            type: SAVE_SURVEY_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: SAVE_SURVEY_FAILED,
            payload: error?.response?.data?.message || error.message,
        });
    }
};
