import { stripFilters } from "../../utils";
import {
  ADD_BOOKMARK_SUCCESS,
  ADD_COURSE_DETAIL_BOOKMARK_SUCCESS,
  ADD_RECOMMENDED_BOOKMARK_SUCCESS,
  CLEAR_COURSE_FILTERS,
  COURSE_LIST_FAIL,
  COURSE_LIST_REQUEST,
  COURSE_LIST_SUCCESS,
  FILTER_COURSES_SUCCESS,
  GET_COURSE_CATEGORY,
  GET_COURSE_CATEGORY_FAILED,
  GET_COURSE_CATEGORY_SUCCESS,
  GET_RECOMMENDED_COURSES,
  GET_RECOMMENDED_COURSES_FAILED,
  GET_RECOMMENDED_COURSES_SUCCESS,
  REMOVE_BOOKMARK_SUCCESS,
  REMOVE_COURSE_DETAIL_BOOKMARK_SUCCESS,
  REMOVE_RECOMMENDED_BOOKMARK_SUCCESS,
  SET_COURSE_FILTERS,
  UPDATE_COURSE_FILTERS,
  GET_BOOKMARKED_COURSES,
  GET_BOOKMARKED_COURSES_SUCCESS,
  GET_BOOKMARKED_COURSES_FAILED,

  FETCH_COURSE_REQUEST,
  FETCH_COURSE_SUCCESS,
  FETCH_COURSE_FAILED,
  
  UPDATE_RECOMMENDED_COURSE_CLICKS,
  UPDATE_RECOMMENDED_COURSE_CLICKS_SUCCESS,
  UPDATE_RECOMMENDED_COURSE_CLICKS_FAILED
} from "./course.constants";

const initialState = {
  courses: [],
  recommended: [],
  totalCourseCount: 0,
  filterCount: {
    "mode_of_delivery.on-campus": 0,
    "mode_of_delivery.online": 0,
    "program_level.graduate": 0,
    "program_level.bachelors": 0,
  },
  loading: false,
  success: null,
  error: null,
};

export const courseInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_COURSE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case FETCH_COURSE_SUCCESS:
      return {
        ...state,
        course: action.payload.course,
        isLoading: false,
        error: null
      };
    case FETCH_COURSE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export const courseListReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case COURSE_LIST_REQUEST:
      return { ...state, loading: true };
    case COURSE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        courses: payload.result,
        recommended: payload.recommended,
        totalCourseCount: payload.totalCount,
        filterCount: payload.filterCount ?? {},
      };

    case ADD_BOOKMARK_SUCCESS: {
      const courseIndex = state.courses.findIndex(
        (course) => course.id === payload
      );
      const recommendIndex =
        courseIndex < 0
          ? state.recommended.findIndex((course) => course.id === payload)
          : -1;

      if (courseIndex >= 0) {
        const newCourse = state.courses[courseIndex];
        newCourse.is_bookmarked = true;
        const updatedCourses = [...state.courses];
        updatedCourses.splice(courseIndex, 1, newCourse);

        return {
          ...state,
          courses: updatedCourses,
        };
      } else {
        const newCourse = state.recommended[recommendIndex];
        newCourse.is_bookmarked = true;
        const updatedRecommendations = [...state.recommended];
        updatedRecommendations.splice(recommendIndex, 1, newCourse);

        return {
          ...state,
          recommended: updatedRecommendations,
        };
      }
    }
    case REMOVE_BOOKMARK_SUCCESS: {
      const courseIndex = state.courses.findIndex(
        (course) => course.id === payload
      );
      const recommendIndex =
        courseIndex < 0
          ? state.recommended.findIndex((course) => course.id === payload)
          : -1;

      if (courseIndex >= 0) {
        const newCourse = state.courses[courseIndex];
        newCourse.is_bookmarked = false;
        const updatedCourses = [...state.courses];
        updatedCourses.splice(courseIndex, 1, newCourse);

        return {
          ...state,
          courses: updatedCourses,
        };
      } else {
        const newCourse = state.recommended[recommendIndex];
        newCourse.is_bookmarked = false;
        const updatedRecommendations = [...state.recommended];
        updatedRecommendations.splice(recommendIndex, 1, newCourse);

        return {
          ...state,
          recommended: updatedRecommendations,
        };
      }
    }
    case COURSE_LIST_FAIL:
      return { ...state, loading: false, success: false, error: payload };
    default:
      return state;
  }
};

const courseInitialState = {
  courses: [],
  totalCount: 0,
  loading: false,
  success: null,
  error: null,
};

export const courseDetailReducer = (state = courseInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_COURSE_CATEGORY:
      return { ...state, loading: true };
    case GET_COURSE_CATEGORY_SUCCESS:
      return { ...state, ...payload, success: true, loading: false };
    case GET_COURSE_CATEGORY_FAILED:
      return { ...state, success: false, errors: payload, loading: false };

    case ADD_COURSE_DETAIL_BOOKMARK_SUCCESS: {
      const courseIndex = state.courses.findIndex(
        (course) => course.id === payload
      );
      const newCourse = state.courses[courseIndex];
      newCourse.is_bookmarked = true;
      const updatedCourses = [...state.courses];
      updatedCourses.splice(courseIndex, 1, newCourse);
      return {
        ...state,
        courses: updatedCourses,
      };
    }

    case REMOVE_COURSE_DETAIL_BOOKMARK_SUCCESS: {
      const courseIndex = state.courses.findIndex(
        (course) => course.id === payload
      );
      const newCourse = state.courses[courseIndex];
      newCourse.is_bookmarked = false;
      const updatedCourses = [...state.courses];
      updatedCourses.splice(courseIndex, 1, newCourse);
      return {
        ...state,
        courses: updatedCourses,
      };
    }

    default:
      return state;
  }
};

const initialFilterState = {
  filters: {
    course_name: "",
    location: "",
    program_level: [],
    mode_of_delivery: [],
  },
  shouldFilter: false,
};
export const courseFilterReducer = (state = initialFilterState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_COURSE_FILTERS:
      return {
        ...state,
        filters: { ...stripFilters(state.filters), ...payload },
        shouldFilter: true,
      };
    case UPDATE_COURSE_FILTERS:
      return {
        ...state,
        filters: { ...stripFilters(state.filters), ...payload },
        shouldFilter: true,
      };
    case CLEAR_COURSE_FILTERS:
      return initialFilterState;
    case FILTER_COURSES_SUCCESS:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export const recommendedCourseClicksReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_RECOMMENDED_COURSE_CLICKS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case UPDATE_RECOMMENDED_COURSE_CLICKS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case UPDATE_RECOMMENDED_COURSE_CLICKS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

const initialRecommendedCoursesState = {
  courses: [],
  totalCount: 0,
  loading: false,
  error: null,
};

export const recommendedCoursesReducer = (
  state = initialRecommendedCoursesState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_RECOMMENDED_COURSES:
      return {
        ...state,
        loading: true,
      };
    case GET_RECOMMENDED_COURSES_SUCCESS:
      return {
        ...state,
        courses: payload.recommended,
        totalCount: payload.totalCount,
        loading: false,
      };
    case GET_RECOMMENDED_COURSES_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ADD_RECOMMENDED_BOOKMARK_SUCCESS: {
      const courseIndex = state.courses.findIndex(
        (course) => course.id === payload
      );
      const newCourse = state.courses[courseIndex];
      newCourse.is_bookmarked = true;
      const updatedCourses = [...state.courses];
      updatedCourses.splice(courseIndex, 1, newCourse);
      return {
        ...state,
        courses: updatedCourses,
      };
    }
    case REMOVE_RECOMMENDED_BOOKMARK_SUCCESS: {
      const courseIndex = state.courses.findIndex(
        (course) => course.id === payload
      );
      const newCourse = state.courses[courseIndex];
      newCourse.is_bookmarked = false;
      const updatedCourses = [...state.courses];
      updatedCourses.splice(courseIndex, 1, newCourse);
      return {
        ...state,
        courses: updatedCourses,
      };
    }
    default:
      return state;
  }
};

const initialBookmarkedCoursesState = {
  courses: [],
  totalCount: 0,
  loading: false,
  error: null,
};

export const bookmarkedCoursesReducer = (
  state = initialBookmarkedCoursesState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_BOOKMARKED_COURSES:
      return {
        ...state,
        loading: true,
      };
    case GET_BOOKMARKED_COURSES_SUCCESS:
      return {
        ...state,
        courses: payload.courses,
        totalCount: payload.totalCount,
        loading: false,
      };
    case GET_BOOKMARKED_COURSES_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};