import styled from "styled-components";

export const StyledDropDownContainer = styled.div`
   position: absolute;
   top: 5rem;
   right: 0;
   background-color: #121428;
   border-radius: 6px;
   padding: 0;
   // border: 1px solid ${({ theme }) => theme.colors?.japaaGreen80};
   width: 15rem;
`;