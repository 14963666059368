import styled, { css } from "styled-components";
import { theme } from '../../theme'

function variantButtonStyles({ variant }) {
   switch (variant) {
      case "secondary":
         return css`
            color: ${({ theme }) => theme.colors?.white};
            background-color: ${({ theme }) => theme.colors?.japaaBlack};
            // border: 1px solid ${({ theme }) => theme.colors?.borderColor};

            &:hover,
            &:active {
               opacity: 0.7;
            }

            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               opacity: 40%;
               cursor: not-allowed;
            }
         `;
      case "invert":
         return css`
            color: #444444;
            background-color: #fff;
            border: 1px solid ${theme.colors.black};

            &:hover,
            &:active {
               opacity: 0.7;
            }

            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               opacity: 40%;
               cursor: not-allowed;
            }
         `;
      case "grey":
         return css`
            color: ${theme.colors.white};
            background-color: #42425f;
            border: 1px solid ${theme.colors.grey};

            &:hover,
            &:active {
               opacity: 0.8;
            }

            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               opacity: 40%;
               cursor: not-allowed;
            }
         `;
      case "link-black":
         return css`
            color: ${theme.colors.japaaBlack};
            background: none;
            font-weight: 500;
            padding-left: 0;

            &:hover,
            &:active {
               opacity: 0.8;
            }

            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               color: ${theme.colors.dark40};
               cursor: not-allowed;
            }
         `;
         case "frame":
            return css`
               color: ${theme.colors.japaaBlack};
               background: none;
               font-weight: 500;
               border: 1px solid ${theme.colors.japaaBlack};
               border-radius: 0.2rem;
   
               &:hover,
               &:active {
                  opacity: 0.8;
                  background-color: ${theme.colors.japaaBlack20};
               }
   
               &:disabled,
               &:disabled:active,
               &:disabled:hover {
                  color: ${theme.colors.japaaBlack};
                  cursor: not-allowed;
               }
            `;
         case "dark":
            return css`
               color: ${theme.colors.white};
               background-color: ${theme.colors.japaaBlack};
               border: 1px solid ${theme.colors.grey};
   
               &:hover,
               &:active {
                  opacity: 0.8;
               }
   
               &:disabled,
               &:disabled:active,
               &:disabled:hover {
                  opacity: 100%;
                  color: #D4CCCC;
                  background-color: ${theme.colors.white};
                  border: none;
                  cursor: not-allowed;
               }
            `;
      default:
      case "primary":
         return css`
            color: ${theme.colors.japaaBlack};
            background-color: ${theme.colors.japaaGreen};

            &:hover,
            &:active {
               color: ${theme.colors.japaaBlack};
               background-color: ${theme.colors.japaaGreen};
               opacity: 0.8;
            }

            &:disabled,
            &:disabled:active,
            &:disabled,
            &:disabled:active,
            &:disabled:hover {
               opacity: 40%;
               cursor: not-allowed;
            }
         `;
   }
}

export const ButtonStyledComponent = styled.button`
   border: 0;
   padding: 0;
   cursor: pointer;
   box-sizing: border-box;
   display: inline-block;
   max-width: 100%;
   text-align: center;
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
   font-family: 'Avenir';
   font-style: normal;
   font-size: ${({ size }) => {
      switch (size) {
         case "small":
            return "1.4rem;";
         case "medium":
            return "1.8rem;";
         case "large":
            return "2rem;";
         default:
            return "1.6rem;";
      }
   }};
   letter-spacing: 0;
   line-height: 1.6rem;
   font-weight: bold;
   border-radius: .8rem;
   text-transform: capitalize;
   transition: all 300ms ease-in-out;
   background-color: ${theme.colors.japaaGreen};
   color: ${theme.colors.white};
   display: flex;
   justify-content: center;
   align-items: center;
   padding: ${({ size }) => {
      switch (size) {
         case "v-small":
            return "1.4rem 3rem;";
         case "small":
            return "1.4rem 7rem;";
         case "medium":
            return "1.8rem 9rem;";
         case "large":
            return "2rem 10rem;";
         default:
            return "1.6rem 8rem;";
      }
   }};

   ${({ fullWidth }) =>
      fullWidth &&
      css`
         width: 100%;
      `};
      ${({ responsive }) =>
      responsive &&
      css`
        @media only screen and (max-width: 468px) {
            width: 100%;
            margin-bottom: 20px;
            margin-left: 0px !important;
        }
      `};
    

   ${variantButtonStyles}
`;
