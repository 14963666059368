import styled from "styled-components";
import { NavLink as Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
 display: flex;
 align-items:center;
 padding: 1rem 1.56rem;
 z-index: 10;
 position: relative;

 .label {
    margin-left: 1.1rem;
    font-weight: 400;
    font-size: 1.4rem;
    color: ${({ theme,color }) => color ? theme.colors?.redError : theme.colors?.white};
    white-space: nowrap;
 }

 &:hover {
    background-color: ${({ theme }) => theme.colors?.japaaGreen40};
    
    .label {
        color: ${({ theme }) => theme.colors?.white};
    }

    .icon-container {
        color: ${({ theme }) => theme.colors?.white};
    }
 }

 &.active{
    background-color: ${({ theme }) => theme.colors?.japaaGreen40};
    
    .label, .icon-container {
        color: ${({ theme }) => theme.colors?.white};
    }
  }
`;
export const StyledUtilLink = styled.div`
 display: flex;
 align-items:center;
 padding: 1rem 1.56rem;
 z-index: 10;
 position: relative;

 .label {
    margin-left: 1.1rem;
    font-weight: 400;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors?.redError};
    white-space: nowrap;
 }

 &:hover {
    background-color: ${({ theme }) => theme.colors?.redError};

    .label {
        color: ${({ theme }) => theme.colors?.white};
    }

    .icon-container {
        color: ${({ theme }) => theme.colors?.white};
    }
 }
`;

export const LinkContainer = styled.div`
    position: relative;
    cursor: pointer;
    font-family: 'Avenir';
`;

export const IconContainer = styled.div`
    color: ${({ theme,color }) => color && theme.colors?.redError};
    display: flex;
    // margin-bottom: 2px;
`;