import React from "react";
import { useHistory } from "react-router-dom";
import {
  SearchByCountrySectionWrapper,
  CountryListSection,
  CountryItem,
  HeaderSection,
  Overlay,
  CountryText,
} from "./search-by-country-style";

const SearchByCountrySection = ({ countryData }) => {
  const history = useHistory();
  const linkToNewPage = (countryId) => {
    history.push(`/country-content/${countryId}`);
  };

  return (
    <SearchByCountrySectionWrapper>
      <HeaderSection>
        {countryData ? (
          <h3 className="header-text1">Explore other study destinations</h3>
        ) : (
          <h3 className="header-text2">Search by Country</h3>
        )}
      </HeaderSection>
      <CountryListSection>
        <CountryItem onClick={() => linkToNewPage("1")} className="wide-item">
          <Overlay />
          <CountryText>
            <p className="country-text">United Kingdom</p>
          </CountryText>

          <img src="/assets/images/united-kingdom.png" alt="" />
        </CountryItem>

        <CountryItem onClick={() => linkToNewPage("2")} className="wide-item">
          <Overlay />
          <CountryText>
            <p className="country-text">USA</p>
          </CountryText>
          <img src="/assets/images/usa.png" alt="" />
        </CountryItem>
        <CountryItem onClick={() => linkToNewPage("3")}>
          <Overlay />
          <CountryText>
            <p className="country-text">Canada</p>
          </CountryText>
          <img src="/assets/images/canada.png" alt="" />
        </CountryItem>
        <CountryItem onClick={() => linkToNewPage("4")}>
          <Overlay />
          <CountryText>
            <p className="country-text">Australia</p>
          </CountryText>
          <img src="/assets/images/australia.png" alt="" />
        </CountryItem>
        <CountryItem onClick={() => linkToNewPage("5")}>
          <Overlay />
          <CountryText>
            <p className="country-text">Sweden</p>
          </CountryText>
          <img src="/assets/images/sweden.png" alt="" />
        </CountryItem>
        <CountryItem onClick={() => linkToNewPage("6")}>
          <Overlay />
          <CountryText>
            <p className="country-text">Germany</p>
          </CountryText>
          <img src="/assets/images/germany.png" alt="" />
        </CountryItem>
      </CountryListSection>
      {countryData ? (
        <button className="view-all-btn">
          <h2> View all countries</h2>
        </button>
      ) : (
        " "
      )}
    </SearchByCountrySectionWrapper>
  );
};

export default SearchByCountrySection;
