import React from 'react';
import { StyledChatItem } from './chat-notification.style';
import { useSnackbar } from 'notistack';
import moment from 'moment';

const ChatNotification = React.forwardRef(({ key, chatUser, onClick },ref) => {
    const { first_name, last_name, id, profile_picture, unreadMessageCount, lastUpdate, lastSentMessage } = chatUser;
    const dateStr = moment(lastUpdate).fromNow();
    const { closeSnackbar } = useSnackbar();

    const onNotificationClick = () => {
        if (onClick) {
            onClick({id});
        }
        closeSnackbar(key);
    };

    return (
        <StyledChatItem ref={ref} onClick={() => onNotificationClick()}>
            <div className="image-container">
                <img src={profile_picture ?? "/assets/images/no-profile-picture.jpg"} alt="" />
            </div>
            <div className="chat-info">
                <h2 className="chat-name">{first_name} {last_name}</h2>
                <p className="recent-chat">{lastSentMessage ?? "New Chat!"}</p>
            </div>
            <div className="date-and-chat-count">
                <span className="chat-date">{dateStr}</span>
                {unreadMessageCount?
                    <span className="chat-count-bubble">{unreadMessageCount}</span> : ''
                }
            </div>
        </StyledChatItem>
    )
});

export { ChatNotification }
