import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { BsFillCaretDownFill } from "react-icons/bs";
import { RiMenu3Fill } from "react-icons/ri";
import { Avatar } from "@material-ui/core"
import {
    NavbarContainer,
    Container,
    NavBarLinks,
    NavLinks,
    NavLinkItem,
    NavLinkItems,
    OtherLinks,
    MenuIcon,
    BackDrop,
    AuthOtherLinks,
    StyledAvatarContainer,
    NotificationIconContainer,
    NotificationCount
} from "./navbar.style";
import { BrandImage } from "../brand-image/brand-image";
import { Button } from "../button/button";
import { MobileNav } from "./mobile-nav";
import { getProfile } from '../../redux/user/user.actions';
import { LandingPageNavLinksData, DefaultNavLinksData } from '../../services/menuData';
import { NavDropdown } from "../user-profile/nav-dropdown";
import { useSocket } from '../../hooks/socket-hook';
import { enqueueSnackbar } from '../../redux/alert/alert.actions';
import { HiBell } from "react-icons/hi";
import { viewSurvey, setPopUp } from "../../redux/survey/survey.actions";
import { SurveyPopup } from "../survey-popup/survey-popup"

const Navbar = ({ includeNavlinks = true, theme = "light", onNavLinkClick }) => {
    const npsQuestion = "How likely are you to recommend Japaanow to a friend/family?";
    const npsText = "What was the reason for your response?";
    const npsComment = "Leave a comment";
    const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    const fiveStarQuestion = "How are you enjoying JapaaNow?";
    const fiveStarText = "Rate your overall experience";
    const fiveStarComment = "Leave a comment";

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const userLogin = useSelector((state) => state.userLogin);
    const userProfile = useSelector((state) => state.userProfile);
    const { surveys } = useSelector((state) => state.viewSurvey);
    const { fiveStarPopupSeen, npsPopupSeen } = useSelector((state) => state.popUpSurvey);

    const { isAuthenticated } = userLogin;
    const { user } = userProfile;
    const profilePicture = user && user.profile_picture;
    const unread_notification_count = user && user.unread_notifiable_count;

    const [navIconClicked, setNavIconClicked] = useState(false);
    const [navDropdownActive, setNavDropdownActive] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [surveyId, setSurveyId] = useState('');
    const [showOverlay, setShowOverlay] = useState(false);
    const [isNpsClosed, setIsNpsClosed] = useState(false);
    const [showFiveStar, setShowFiveStar] = useState(false);
    const [isFiveStar, setIsFiveStar] = useState(false);

    const navbarRef = useRef(null); // Ref for the navbar DOM element
    includeNavlinks = includeNavlinks && !(user && user.isMentor);

    const handleClick = () => {
        setNavIconClicked(!navIconClicked);
    };

    const toggleNavDropdown = () => {
        setNavDropdownActive(!navDropdownActive);
    };

    const navLinkClick = (link) => {
        if (onNavLinkClick) onNavLinkClick(link);
        setNavIconClicked(!navIconClicked);
    };

    useEffect(() => {
        dispatch(getProfile());
    }, [dispatch]);

    useEffect(() => {
        // Add event listener for click event on document body
        document.addEventListener("click", handleBodyClick, true);
        return () => {
            // Clean up event listener when component unmounts
            document.removeEventListener("click", handleBodyClick, true);
        };
    }, []);

    const handleBodyClick = (event) => {
        if (
            navbarRef.current &&
            !navbarRef.current.contains(event.target)
        ) {
            return setNavDropdownActive(false);
        }
    };

    const closeModal = (surveyModalType) => {
        setShowOverlay(false);
        setIsOpen(false);
        dispatch(setPopUp({ surveyType: surveyModalType }))

        if (surveyModalType === "nps") {
            setIsNpsClosed(true);
        } else if (surveyModalType === "fiveStar") {
            setIsFiveStar(true);
        }
    };

    useEffect(() => {
        if (user) {
            const timeDifference = new Date().getTime() - new Date(user.last_login).getTime();
            const timeThreshold = 5*60*1000; // 5 minutes in milliseconds 
            setShowFiveStar(timeDifference > timeThreshold);
            if (!showFiveStar) {
                const setShowFiveStarFunction = () => setShowFiveStar(true);
                clearTimeout(setShowFiveStarFunction);
                setTimeout(setShowFiveStarFunction, timeThreshold - timeDifference);
            }
        }
    }, [user])

    useEffect(() => {
        if (user?.visit_count > 1 && !location.pathname.includes("recommended")) {
            dispatch(viewSurvey());
        }
    }, []);

    useEffect(() => {
        if (isAuthenticated && surveys?.length > 0) {
            const fiveStarFilteredSurvey = surveys.find(survey => survey.survey_type == 1);
            const npsFilteredSurvey = surveys.find(survey => survey.survey_type == 0);
            if (location.pathname.includes('recommended')) {
                setShowOverlay(false);
                setIsOpen(false);
            }
            else if (npsFilteredSurvey && !npsPopupSeen) {
                setSurveyId(npsFilteredSurvey.id)
                setShowOverlay(true);
                setIsOpen(true);
            }
            else if (fiveStarFilteredSurvey && !fiveStarPopupSeen && showFiveStar) {
                setSurveyId(fiveStarFilteredSurvey.id)
                setIsFiveStar(true);
                setShowOverlay(true);
                setIsOpen(true);
            }
        }
    }, [surveys, showFiveStar, isNpsClosed])

    //set up socket connection to get chat
    useSocket("CHAT_USER_LISTEN", (payload) => {
        dispatch(enqueueSnackbar({
            options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
                type: 'chat',
                data: payload.chat_user,
                onClick: ({ id }) => {
                    if (id) history.push("/profile/mentors?chatUserId=" + id);
                }
            },
        }));
        dispatch(getProfile());
    }, userProfile?.user?.id);

    return (
        <>
            <NavbarContainer variant={theme} ref={navbarRef}>
                <Container>
                    {!isAuthenticated ?
                        <>
                            <MenuIcon style={!isAuthenticated ? { position: 'absolute', right: '0' } : {}} variant={theme} onClick={handleClick}><RiMenu3Fill /></MenuIcon>
                        </>
                        :
                        (user && !user.isMentor) &&
                        <>
                            <MenuIcon style={!isAuthenticated ? { position: 'absolute', right: '0' } : {}} variant={theme} onClick={handleClick}><RiMenu3Fill /></MenuIcon>
                        </>
                    }
                    <BrandImage theme={theme} />
                    {includeNavlinks ? (<NavBarLinks menuActive={navIconClicked}>
                        <NavLinks menuActive={navIconClicked}>
                            {(isAuthenticated && user && user.is_admin) ? (
                                <>
                                    {DefaultNavLinksData.map((linkData, i) => (
                                        <NavLinkItem key={i} variant={theme} to={linkData.to}>{linkData.label}</NavLinkItem>
                                    ))}
                                    <NavLinkItem to='/mentor-verification'>Verification</NavLinkItem>
                                </>
                            ) : (
                                <>
                                    {isAuthenticated ? (
                                        DefaultNavLinksData.map((linkData, i) => (
                                            <NavLinkItem key={i} variant={theme} to={linkData.to}>{linkData.label}</NavLinkItem>
                                        ))
                                    ) : (
                                        LandingPageNavLinksData.map((linkData, i) => (
                                            linkData.to ? (
                                                <NavLinkItem key={i} variant={theme} to={linkData.to}>{linkData.label}</NavLinkItem>
                                            ) : (
                                                <NavLinkItems className="no-underline" key={i} variant={theme} to={{ pathname: '/', state: (location.pathname.length > 1) ? { section: linkData.location } : '' }} onClick={() => navLinkClick(linkData.location)}>
                                                    {linkData.label}
                                                </NavLinkItems>
                                            )
                                        ))
                                    )}
                                </>
                            )}
                        </NavLinks>
                    </NavBarLinks>) : ''}
                    {!isAuthenticated ? (
                        <OtherLinks menuActive={navIconClicked}>
                            <Button style={{ padding: 0 }} variant={theme == "light" ? "link-black" : "link"} size="small">
                                <Link to="/signin">Sign In</Link></Button>
                            <Link to="/register/type">
                                <Button style={{ padding: '1.4rem 2.4rem', marginLeft: '4rem' }} size="small">
                                    Create Account
                                </Button>
                            </Link>
                        </OtherLinks>
                    ) : (
                        <AuthOtherLinks>
                            {(user && user.isMentor) &&
                                <>
                                    <NotificationIconContainer onClick={() => history.push("/notifications")}>
                                        <HiBell className="notification-icon" />
                                        {unread_notification_count ?
                                            <NotificationCount>
                                                {unread_notification_count}
                                            </NotificationCount> : ''}
                                    </NotificationIconContainer>
                                </>
                            }
                            <StyledAvatarContainer onClick={toggleNavDropdown}>
                                <Avatar alt="Name" src={profilePicture ?? "/assets/images/no-profile-picture.jpg"} sx={{ width: 40, height: 40 }} />
                                <BsFillCaretDownFill />
                            </StyledAvatarContainer>

                            {navDropdownActive && <NavDropdown isMentor={user && user.isMentor} setNavDropdownActive={setNavDropdownActive} />}

                        </AuthOtherLinks>
                    )}

                </Container>
            </NavbarContainer >
            <MobileNav
                menuActive={navIconClicked}
                setNavIconClicked={setNavIconClicked}
                onNavLinkClick={navLinkClick}
            />
            <BackDrop menuActive={navIconClicked} onClick={handleClick} />
            {showOverlay && (
                <div className="overlay-container"></div>
            )}
            {isOpen && (
                <SurveyPopup
                    closeModal={() => closeModal(isFiveStar ? "fiveStar" : "nps")}
                    question={isFiveStar ? fiveStarQuestion : npsQuestion}
                    text={isFiveStar ? fiveStarText : npsText}
                    comment={isFiveStar ? fiveStarComment : npsComment}
                    numbers={isFiveStar ? "" : numbers}
                    setShowFiveStar={setShowFiveStar}
                    isFiveStar={isFiveStar}
                    surveyId={surveyId}
                />
            )}
        </>
    )
}

export { Navbar }