 export const countriesContentData= [
     {
        id: 2,
        country_name: "USA",
        location: "united states of america",
        header:"the United State Of America",
        study_reason: "The majority of the world's international students are studied abroad in the United States of America (USA). Many students choose to study in the United States for a variety of reasons, including the high quality of instruction, the distinctive curriculum, the multicultural atmosphere, the lively campus life, and the abundance of chances.",
        ease_of_application: "It's not any simpler for foreign students to enroll in US universities. The majority of colleges admit overseas students at rates that are comparable to those of their other candidates. Accordingly, the procedure might be just as difficult for non-US citizens as it is for US people.",
        cost_of_living: "Based on the city you live in, you may roughly estimate the cost of living in the United States for international students. The typical cost of living in the US is projected to be between $10,000 and $18,000 per year, or between $1000 and $1500 per month.",
        weather: "The contiguous United States, a sizable nation by population, is home to a wide range of climates. • While the southwest is extremely hot and dry throughout the summer, the plains and southern states are hot and muggy. • Florida and southern California enjoy never-ending summers. • In the north, northeast, and plains states such as Illinois, Indiana, Michigan, Kansas, Minnesota, Missouri, New Jersey, Nebraska, New York, Ohio, and Wisconsin, conditions are frequently quite cold with heavy snowfall and freezing temperatures. • Winters in the southern states are mild, e.g., Florida, Georgia, North Carolina, South Carolina, Louisiana, Oklahoma, and Texas.",
        top_university: "Cornell University Boston University University of California, Berkeley Wake Forest University Georgia Institute of Technology West Virginia University Syracuse University Johns Hopkins University Colgate University",
        unis_with_highest_acceptance_rate: "University of California, Berkeley University of Toledo Kent State University University of Utah Colorado State University University of Kansas Arizona State University University of Kentucky East Carolina University",
        best_study_areas: "Engineering.Business & Management Studies.Computer Science.Medicine.",
        available_scholarships: "American University Scholarship.Clark University Scholarship.Aga Khan Foundation International Scholarships",
        gen_application_requirements: "Contact a College Advisor Choose a University Program Official transcripts from all previous schools attended Scores from standardized tests, such as the SAT or ACT English proficiency test scores, such as the TOEFL, IELTS, iTEP, or PTE Academic A personal essay or statement of purpose Letters of recommendation from teachers or other professionals A copy of your passport to verify your identity and citizenship.Apply for a Student Visa",
        gen_visa_requirements: "Student visa (F-1)",
        tuition_estimate: "between $25,000 and $45,000 per year",
        fun_facts: "A 17-year-old teenager named Robert G. Heft truly created the US flag. Do you know that 100 acres of pizza are served daily in the US? The largest academic library in the world is the Harvard Library. The majority of entrepreneurs in the world are found in the US. 'Christmas' appears in the names of 182 localities in the US. Grizzly bears are the recognized state animal of California, yet none have been spotted there since 1922.",
        work_study_information: "Not eligible",
        migration_plan: "Work visa after graduation or apply for permanent visa",
        created_at: null,
        updated_at: null,
        // image: '/assets/images/the_name_of_the_image'
    },
    {
        id: 4,
        country_name: "Australia",
        header:"Australia",
        study_reason: "Australia offers a unique blend of academic excellence and stunning landscapes, making it an enticing destination for international students. Renowned for its high-quality education, the country hosts world-class universities with diverse academic programs taught by expert faculty. Beyond academics, students are greeted with breathtaking natural beauty, from the iconic Great Barrier Reef to vast outback expanses and lush rainforests. This combination of exceptional education and captivating scenery provides a well-rounded and enriching experience for those seeking adventure and academic growth.",
        ease_of_application: "Straightforward application process; Genuine Temporary Entrant (GTE) requirement for student visa",
        cost_of_living: "The cost of living in Australia varies from moderate to high, depending on the city you choose to live in. Major metropolitan areas like Sydney, Melbourne, and Brisbane typically have a higher cost of living due to factors such as housing prices, transportation expenses, and entertainment costs. On the other hand, smaller cities and regional areas might offer a more moderate cost of living. It's important to consider these variations when planning your budget as a student in Australia.",
        weather: "Diverse, ranging from tropical to desert climates",
        top_university: "University of Sydney",
        unis_with_highest_acceptance_rate: "Australian Catholic University, University of the Sunshine Coast",
        best_study_areas: "STEM, Environmental Studies",
        available_scholarships: "Australia Awards, Endeavour Scholarships, Research Training Program Scholarship",
        gen_application_requirements: "Academic transcripts, English proficiency test scores, GTE statement",
        gen_visa_requirements: "Student Visa (subclass 500); Work limit of 40 hours per fortnight during studies",
        tuition_estimate: "AUD $20,000 - $45,000",
        fun_facts: "Australia is home to the world's largest living structure, the Great Barrier Reef.",
        work_study_information: "Part-time work allowed during studies; full-time during breaks",
        migration_plan: "Temporary Graduate Visa (subclass 485) for post-study work",
        created_at: null,
        updated_at: null
      },
      {
        id: 1,
        country_name: "United Kingdom",
        header:"the United Kingdom",
        study_reason: "The UK provides free healthcare, high-quality education that is affordable, a very diverse population, and access to less expensive travel throughout the rest of Europe. The country's history, which spans thousands of years, can be studied by students.You are permitted to work up to 20 hours per week during the term with a student visa. Application opportunities for jobs and internships are numerous. London is an excellent destination for job searchers because it is the world's financial and business center. Students in the UK can work profitably in a variety of industries, such as tech and the food service industry.",
        ease_of_application: "Applying for school in the your is a pretty easy on ascale of 1-10 it would be a 7",
        cost_of_living: "Without dependents, international students studying in the UK should anticipate paying between £1300 and £1400 per month for housing, bills, groceries, and other living expenses in London or between £900 and £1300 in the rest of the UK.",
        weather: "In the UK, the average temperature fluctuates between 10°C in the winter and 30°C at the height of summer. The climate varies around the nation according to area. In general, the north side is colder than the south, which is mild and pleasant. • The winter season typically begins in November and lasts until March. The sky is generally gray and cloudy throughout this period, and from December to February, snow and frost are the predominant weather features. In May, June, September, and October, one may schedule a trip to the UK. These months have often had nice, warm weather. The hottest and wettest months in the UK are July and August.",
        top_university: "Aberystwyth University Bishop Grosseteste University Ravensbourne University London University for the Creative Arts Plymouth College of Art University of Sussex University of Roehampton Northumbria University Nottingham Trent University University of Kent York St John University Liverpool Hope University Coventry University London Metropolitan University University of Exeter University of Wales Trinity Saint David Newman University, Birmingham University College Birmingham University of Winchester Cardiff Metropolitan University",
        unis_with_highest_acceptance_rate: "Leeds Trinity University York St John University Nottingham Trent University University of Exeter Newman University",
        best_study_areas: "Business Analytics Data Science Computer Science MBA and MIM",
        available_scholarships: "GREAT Scholarships Chevening Scholarships Commonwealth Scholarships",
        gen_application_requirements: "Exams, grades, GPA, and results on standardized tests like the SAT and AP from secondary school Personal assertion Reference letters standardized examinations for English language competency, such as the IELTS and TOEFL (if necessary) Interviews, resumes, performances, and additional",
        gen_visa_requirements: "Tier 4 general student visa",
        tuition_estimate: "Typically, tuition costs range from £11,400 to £30,000 per year. Monthly living expenses range from £900 to $1,400. Average weekly expenditure per person: £35",
        fun_facts: "Sir Isaac Newton and Stephen Hawking are two of the most well-known scientists to have come from the UK. More than 300 different languages are spoken by more than 8 million people in London.The 2012 Olympic Games in London marked the first time that all competitors featured female athletes. The Thames River is crossed by 200 bridges.",
        work_study_information: "Check to see if you qualify for a Tier 4 visa when you submit an application for a visa to enter the UK as a student. This visa is intended for foreign students traveling to the United Kingdom to enroll in a program or school that holds a Tier 4 Sponsor License (to determine if your program or school is eligible, check this list). Depending on your academic standing and what your sponsor permits, the Tier 4 visa enables you to study and work in the nation.",
        migration_plan: "Tier-2 visa to work permanently",
        created_at: null,
        updated_at: null
      },
    {
        id: 3,
        country_name: "Canada",
        header:"Canada",
        study_reason: "Affordable stud programs in comparison to those in other nations Canada has a higher standard of living and cheaper costs of living Admission to Canadian study programs may be simpler. numerous post-graduate employment options in Canada. Multicultural workplaces and classrooms  International students' paths to immigration to Canada",
        ease_of_application: "Canadian universities can be extremely selective, however the difficulty of admission will vary by institution and by degree.",
        cost_of_living: "10 to 12 thousand Canadian dollars annually",
        weather: "Canada has the hottest summers and the coldest winters. The country also has cool spring and fall temperatures between 7 and 20 degrees Celsius. Winters can be chilly and summers can be in the 20 to 30 degree range. Lows of at least minus thirty-five are common.",
        top_university: "University of Toronto McGill University University of British Columbia Université de Montréal University of Alberta McMaster University",
        unis_with_highest_acceptance_rate: "University of Lethbridge. Lakehead University. Thompson Rivers University. University of Northern British Columbia. Brock University",
        best_study_areas: "Public Health Computer Science Mechanical Engineering",
        available_scholarships: "Anne Vallee Ecological Fund Trudeau Scholarships and Fellowships Canadian Commonwealth Scholarships and Fellowships",
        gen_application_requirements: "Valid Passport. Proof of Acceptance by a Designated Learning Institution. Proof of Funds. . Passport Size Photographs. . Immigration Medical Examination (IME) . English Language Proficiency Exam Score. . Statement of Purpose",
        gen_visa_requirements: "Study permit",
        tuition_estimate: "Around 36,100 CAD",
        fun_facts: "The world's longest street is located in Canada. Canada has the world's longest coastline. Winnie the Pooh was inspired by a Canadian bear. Canada has twice been invaded by the United States. In Canada, there are more than 55,000 different species of insects.",
        work_study_information: "Students are allowed to work partime  during studies",
        migration_plan: "Postgraduation Work Permit.",
        created_at: null,
        updated_at: null
    },
    {
        id: 5,
        country_name: "Sweden",
        header:"Sweden",
        study_reason: "Sweden is a captivating blend of innovation and an exceptional quality of life set in a truly unique environment. Renowned for its forward-thinking approach and cutting-edge technology, Sweden offers students a dynamic atmosphere to foster creativity and academic growth. With a strong emphasis on sustainability and research, students can immerse themselves in pioneering fields while enjoying a high standard of living. The country's stunning landscapes, from the Northern Lights to picturesque towns, provide a distinct backdrop for a transformative educational journey. Sweden's commitment to inclusivity and progressive values further enriches the student experience, making it a destination that marries intellectual exploration with an unparalleled way of life.",
        ease_of_application: "Application process varies; strong emphasis on motivation and fit; many programs in English",
        cost_of_living: "The cost of living in Sweden is generally moderate, while higher education is offered tuition-free for both domestic and international students. This means that students are not required to pay tuition fees for most bachelor's and master's degree programs at public universities. While the cost of living can vary depending on factors such as location and personal choices, the absence of tuition fees makes education in Sweden particularly accessible. This combination of reasonable living costs and tuition-free education contributes to Sweden's appeal as a desirable study destination for individuals seeking quality education and a balanced financial approach.",
        weather: "Sweden experiences a varied climate with distinct seasons. The country's geographical location in northern Europe results in significant seasonal changes throughout the year. Summers are relatively mild and enjoy long daylight hours, while winters bring cold temperatures and shorter days. Autumn showcases beautiful foliage as the leaves change color, and spring heralds the return of milder temperatures and the blossoming of flowers. This variety of seasons contributes to Sweden's unique charm and offers residents and visitors a diverse range of experiences and outdoor activities across the changing landscapes.",
        top_university: "Lund University",
        unis_with_highest_acceptance_rate: "Stockholm University, Mälardalen University",
        best_study_areas: "Engineering, Technology, Design",
        available_scholarships: "Swedish Institute Scholarships, University-specific scholarships",
        gen_application_requirements: "Documentation of previous studies, English proficiency test scores",
        gen_visa_requirements: "Residence Permit for studies; Part-time work allowed during studies",
        tuition_estimate: "SEK 80,000 - 160,000",
        fun_facts: "Sweden has a Right of Public Access that allows people to roam freely in nature.",
        work_study_information: "Part-time work allowed during studies; full-time during breaks",
        migration_plan: "Opportunities for work and residence permits after graduation",
        created_at: null,
        updated_at: null
    },
    {
        id: 6,
        country_name: "Germany",
        header:"Germany",
        study_reason: "Germany boasts a strong academic tradition coupled with a remarkable feature: tuition-free education. Renowned for its commitment to research and innovation, the country is home to top-notch universities offering a wide array of programs. Students can pursue their academic aspirations without the burden of tuition fees at many public universities. This unique combination of a rich educational heritage and accessible education makes Germany an attractive destination for learners seeking both academic excellence and financial feasibility.",
        ease_of_application: "Application process varies by university; some programs have restricted admissions; need for language proficiency may vary",
        cost_of_living: "Germany offers a moderate cost of living, and in addition, higher education at many of its public universities is tuition-free for both domestic and international students. This means that students can pursue their academic goals without the burden of tuition fees for most undergraduate and graduate programs. While living expenses can vary based on factors like location and lifestyle choices, the availability of free higher education makes studying in Germany an attractive option for those seeking quality education in a cost-effective manner.",
        weather: "Germany's climate is characterized by its variety, featuring four distinct seasons. Spring brings blooming flowers and warmer temperatures after the cold winter months. Summers are generally warm, with longer days perfect for outdoor activities. Autumn is marked by colorful foliage and gradually cooling temperatures. Winter brings cold weather and the possibility of snowfall, creating a picturesque holiday atmosphere. This seasonal diversity allows residents and visitors to enjoy a range of experiences and cultural traditions throughout the year.",
        top_university: "Technical University of Munich",
        unis_with_highest_acceptance_rate: "University of Potsdam, University of Hildesheim",
        best_study_areas: "Engineering, Natural Sciences",
        available_scholarships: "DAAD Scholarships, Deutschlandstipendium, Erasmus+ Scholarships",
        gen_application_requirements: "High school diploma, language proficiency (often English or German)",
        gen_visa_requirements: "Student Visa; Blocked Account requirement for living expenses",
        tuition_estimate: "€0 - €20,000",
        fun_facts: "Germany has over 1,500 beer breweries and a beer purity law dating back to 1516.",
        work_study_information: "Part-time work allowed during studies; 120 full days or 240 half days",
        migration_plan: "Possibilities for job seeker or work visas post-graduation",
        created_at: null,
        updated_at: null
    }
]
