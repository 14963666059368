import { forwardRef } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ButtonStyledComponent } from "./button.style";


const Button = forwardRef(
    (
        { isLoading, variant, children, fullWidth, responsive, type = "button", size, search, ...rest },
        ref
    ) => {
        return (
            <>
                <ButtonStyledComponent
                    search={search}
                    size={size}
                    fullWidth={fullWidth}
                    type={type}
                    variant={variant}
                    responsive={responsive}
                    ref={ref}
                    {...rest}
                >
                    {/* {search && <SearchOutlined style={{ marginRight: "10px" }} />} */}
                    <span>{children}</span>
                    <span>
                        {isLoading && (
                            <CircularProgress
                                style={{ marginLeft: "10px" }}
                                color="inherit"
                                thickness={5}
                                size={13}
                            />
                        )}
                    </span>
                </ButtonStyledComponent>
            </ >
        )
    }
)

export { Button }
