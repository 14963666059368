export function generateUrlFilters(url, queries) {
  if (Object.keys(queries).length === 0) return url;
  return Object.keys(queries).reduce((acc, cur) => {
    if (
      (Array.isArray(queries[cur]) && queries[cur].length) ||
      (!Array.isArray(queries[cur]) && queries[cur]) ||
      queries[cur] === 0
    ) {
      acc += `${acc === url ? "?" : "&"}${
        Array.isArray(queries[cur])
          ? resolveArrayParams(cur, queries[cur])
          : `${cur}=${queries[cur]}`
      }`;
    }
    return acc;
  }, url);
}

function resolveArrayParams(key, value) {
  if (value.length === 0) return "";
  let result = "";
  for (const item of value) {
    result += `${key}[]=${item}&`;
  }

  return result.slice(0, -1);
}

/**
 * Remove empty keys from filter objects, "", [, etc].
 * @param {*} filters
 * @returns
 */
export const stripFilters = (filters) => {
  let result = {};
  for (const [key, value] of Object.entries(filters)) {
    if (value === 0 || Number(value) > 0 || value?.length > 0) {
      result = { ...result, [key]: value };
    }
  }
  return result;
};

export const displayLongSeperatedText = (joinedItems, count = undefined, separator= ';') => {
  if (!joinedItems) return { isLongList: false, items: "" };
  if (!joinedItems.includes(separator))
    return { isLongList: false, items: joinedItems };
  const itemsArr = joinedItems.split(separator);
  return {
    items: `${itemsArr.slice(0, count).join(", ")}${
      count ? "..." : ""
    }`,
    isLongList: itemsArr.length > 4,
  };
};
