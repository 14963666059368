import styled from "styled-components";

export const SearchByCountrySectionWrapper = styled.div`
  button {
    height: 46px;
    padding: 12px 16px 12px 16px;
    background-color: #37fed9;
    border-style: none;
    margin: auto;
    display: flex;
    align-items: center;
    margin-bottom: 11rem;
    margin-top: -90px;
  }
 
  h2 {
    color: #0c0e26;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
  @media screen and (max-width: 480px) {
    button{
      display:none;
    }
  }
`;

export const HeaderSection = styled.div`
  width: 114rem;
  max-width: 90vw;
  margin: 0 auto;

  .header-text2 {
    font-family: "Avenir";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 33px;
    color: ${({ theme }) => theme.colors?.japaaBlack};
  }
  .header-text1 {
    font-family: "Avenir";
    font-style: normal;
    font-weight: 750;
    font-size: 24px;
    line-height: 33px;
    color: ${({ theme }) => theme.colors?.japaaBlack};
  }

  @media screen and (max-width: 480px) {
    // mobile
    .header-text {
      margin-bottom: 30px;
      font-weight: 800;
    }
  }
`;

export const CountryListSection = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25.7rem, 1fr));
  grid-column-gap: 3.2rem;
  grid-row-gap: 3.3rem;
  max-width: 114rem;
  margin: 1.7rem auto 13rem;

  @media screen and (max-width: 1160px) {
    width: 90vw;
    grid-column-gap: 6vw;
    grid-template-columns: repeat(auto-fit, minmax(42vw, 1fr));
    margin-bottom: 6rem;
  }
`;

export const CountryItem = styled.div`
  text-align: center;
  position: relative;
  width: 25.7rem;
  overflow: hidden;
  cursor: pointer;

  &.wide-item {
    grid-column: span 2;
    width: 54.4rem;

    @media screen and (max-width: 1160px) {
      width: 90vw;
    }
  }

  .description-text {
    font-family: "Avenir";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors?.textGrey};
    text-align: center;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform 0.9s;
  }

  &:hover {
    img {
      transform: scale(1.2);
    }
  }

  @media screen and (max-width: 1160px) {
    width: 42vw;
    max-width: 90vw;
  }
  @media screen and (max-width: 480px) {
    // mobile
    grid-column: 1;
    width: 90vw;
    height: 180px;
    img {
      border-radius: 5.21401px;
    }
  }
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: #0c0e26;
  opacity: 0.4;
  border-radius: 4px;

  &:hover {
    opacity: 0.6;
  }
`;

export const CountryText = styled.div`
  position: absolute;
  font-family: "Avenir";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  z-index: 3;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors?.white};
`;
