export const MENTOR_LIST_REQUEST = 'MENTOR_LIST_REQUEST'
export const MENTOR_LIST_SUCCESS = 'MENTOR_LIST_SUCCESS'
export const MENTOR_LIST_FAIL = 'MENTOR_LIST_FAIL'

export const MENTEE_LIST_REQUEST = 'MENTEE_LIST_REQUEST'
export const MENTEE_LIST_SUCCESS = 'MENTEE_LIST_SUCCESS'
export const MENTEE_LIST_FAIL = 'MENTEE_LIST_FAIL'

export const MENTEE_SEARCH_REQUEST = 'MENTEE_SEARCH_REQUEST'
export const MENTEE_SEARCH_SUCCESS = 'MENTEE_SEARCH_SUCCESS'
export const MENTEE_SEARCH_FAIL = 'MENTEE_SEARCH_FAIL'

export const GET_VERIFIED_MENTORS_REQUEST = 'GET_VERIFIED_MENTORS_REQUEST';
export const GET_VERIFIED_MENTORS_SUCCESS = 'GET_VERIFIED_MENTORS_SUCCESS';
export const GET_VERIFIED_MENTORS_FAIL = 'GET_VERIFIED_MENTORS_FAIL';

export const MENTOR_VERIFY_REQUEST = 'MENTOR_VERIFY_REQUEST'
export const MENTOR_VERIFY_SUCCESS = 'MENTOR_VERIFY_SUCCESS'
export const MENTOR_VERIFY_FAIL = 'MENTOR_VERIFY_FAIL'

export const MENTOR_REJECT_REQUEST = 'MENTOR_REJECT_REQUEST'
export const MENTOR_REJECT_SUCCESS = 'MENTOR_REJECT_SUCCESS'
export const MENTOR_REJECT_FAIL = 'MENTOR_REJECT_FAIL'